import React from 'react';

const IframeComponent = () => {
  return (
    <div>
      <iframe
        src="https://www.attspoc.com/?key=Hpcn8Lg2iWDDCjYi"
        width="100%"
        height="600px"
        allowFullScreen
        title="Fiber Deals"
      ></iframe>
    </div>
  );
};

export default IframeComponent;
