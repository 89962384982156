import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function RefreshOnRouteChange() {
  const location = useLocation();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    window.location.reload();
  }, [location]);

  return null;
}

export default RefreshOnRouteChange;
