import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function OptInModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a onClick={handleShow}>Opt In</a>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="common-modal-wraper custom-optin-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="theme-clr">
            <h5>Opt-In</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-common-body">
          <p>
            By submitting this form, you acknowledge that you permit Comlink
            Total Solutions Corp or its affiliates express consent to contact
            you at the number and/or email address you have provided above with
            automated technology in relation to this inquiry via phone, e-mail,
            or text message. You understand that making a purchase is not
            required to consent to receive communications from Comlink Total
            Solutions. By opting in, you agree to receive marketing
            communications, including but not limited to, calls, text messages,
            and emails from Comlink Total Solutions Corp, its affiliates,
            including Benefits Pilot. These communications may include
            promotional offers, updates, and other information about our
            products and services. Your consent is entirely voluntary, and you
            may opt-out at any time. If you change your mind and no longer wish
            to receive these communications, you can revoke your consent by
            using any of the following methods: To opt-out please email{" "}
            <a href="mailto:support@gocomlink.com">support@gocomlink.com</a>{" "}
            please provide your full name, email and contact number and ask to
            be removed from our list or call{" "}
            <a href="tel:833 604-1080">833 604-1080</a> and asked to be removed
            from our list.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OptInModal;
