import React, { useEffect, useState } from "react";

// __ __ Packages/Style/Media __ __ //
import StepWizard from "react-step-wizard";
import { toast } from "react-toastify";
import "./PrepaidPlanDeals.css";
import LeadCapture from "./LeadCapture";
import LastStep from "./LastStep";
import LeadDetailCapture from "./LeadDetailCapture";
import Deals from "./Deals";
// __ __ Components/Helpers __ __ //
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderSumary from "../../../Pages/GetOrderSumary/GetOrderSumary";

const stripePublicKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripePublicKey);

const PrepaidPlanDeals = () => {
  const [serviceZipcode, setServiceZipcode] = useState("");
  const [leadId, setLeadId] = useState("");
  const [rewardOptions, setRewardOptions] = useState("");
  const [step, setStep] = useState("1");
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState({
    phone: "",
    email: "",
    zipcode: "",
    type: [],
    preferredDevice: "",
  });
  return (
    <StepWizard>
      <LeadCapture setLeadId={setLeadId} setRewardOptions={setRewardOptions} setData={setData} />
      <LeadDetailCapture
        setServiceZipcode={setServiceZipcode}
        setData={setData}
        rewardOptions={rewardOptions}
        leadId={leadId}
        data={data}
      />
      <Deals
        serviceZipcode={serviceZipcode}
        leadId={leadId}
        rewardOptions={rewardOptions}
        setFlag={setFlag}
        flag={flag}
        data={data}
        setStep={setStep}
      />
      {/* <OrderSumary step={step} leadId={leadId} /> */}
      <Elements stripe={stripePromise}>
        <LastStep leadId={leadId} flag={flag} step={step}/>
      </Elements>
      {/* <LastStep leadId={leadId} flag={flag} /> */}
    </StepWizard>
  );
};

export default PrepaidPlanDeals;
