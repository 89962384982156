import React, { useEffect, useState } from "react";
import "./Deal.css";
import "../../../Pages/CmsBanner/CmsBanner.css";
import { Images } from "../../../../assets/assets";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import OptInModal from "./OptInModal";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY_FOR_ORDERS; // For Comlink Bundle Orders
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN,
  apikey: API_KEY,
};
const USStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const intialValue = {
  firstname: "",
  lastname: "",
  prefix: "",
  serviceAddress: "",
  phone: "",
  email: "",
  zipCode: "",
  serviceAddress2: "",
  serviceCity: "",
  serviceState: "",
  // shippingAddress: "",
  // shippingAddress2: "",
  // shippingZipcode: "",
  // shippingCity: "",
  // shippingState: "",
  // sameAsService: false,
  optIn: false,
  phone: "",
  email: "",
  zipCode: "",
  optIn: false,
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  // prefix: Yup.string().required("Prefix is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Enter 10 digits Number")
    .length(14, "Enter 10 digits Number"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .nullable(),

  serviceAddress: Yup.string().required("Service address is required"),
  serviceAddress2: Yup.string(),
  serviceCity: Yup.string().required("Service city is required"),
  serviceState: Yup.string().required("Service state is required"),
  zipCode: Yup.string()
    .required("Zipcode is required")
    .matches(/^[^_]*$/, "Enter 5 digits Zipcode")
    .length(5, "Zipcode must be exactly 5 digits"),

  // shippingAddress: Yup.string().required("Shipping address is required"),
  // shippingAddress2: Yup.string(),
  // shippingCity: Yup.string().required("Shipping city is required"),
  // shippingState: Yup.string().required("Shipping state is required"),
  // shippingZipcode: Yup.string()
  //   .required("Zipcode is required")
  //   .matches(/^[^_]*$/, "Zipcode must be numbers only")
  //   .length(5, "Zipcode must be exactly 5 digits"),

  sameAsService: Yup.boolean(),

  optIn: Yup.boolean()
    .oneOf([true], "You must agree to the Opt In")
    .required("Opt In is required"),
});

const apiUrl = `${API_URL}/leads/create`;
const singleLead=`${API_URL}/leads/get-one`;
const updateLead = `${API_URL}/leads/update`;
function LeadCapture(props) {
  const { slug } = useParams();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [sameAsService, setSameAsService] = useState(false);
  const [sameAsServiceDisabled, setSameAsServiceDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);
  useEffect(()=>{
    if(props.leadFound){
     fetch(`${singleLead}?id=${props?.leadId}`)
     .then((response) => {
       // Check if the response is successful
       if (!response.ok) {
         throw new Error("Network response was not ok");
       }
       // Parse the JSON data
       return response.json();
     })
     .then((data) => {
       // Handle the data
       // setLeadDetail(data.lead);
       formik.setValues({
         firstname:data.lead.firstname,
         lastname:data.lead.lastname,
         email:data.lead.email,
         phone:data.lead.phone ? changeNumberFormatReverse(data.lead.phone) : '',
         zipCode:data.lead.zipCode,
         serviceAddress:data.lead.metaData?.serviceAddress,
         serviceAddress2:data.lead.metaData?.serviceAddress2,
         serviceState:data.lead.metaData?.serviceState,
         serviceCity:data.lead.metaData?.serviceCity,
         optIn:false,
       })
     })
     .catch((error) => {
       // Handle errors
       console.error(
         "There was a problem with your fetch operation:",
         error
       );
       // setLeadDetail({});
     });
    }
   },[props.flag])
   let  changeNumberFormatReverse = (number) => {
    let phone = number?.trim();
  
    if (phone?.startsWith('+1') && phone?.length == 12) {
        phone = phone.substring(2); // Removing the country code
    }
    else if (phone?.startsWith('+') && phone?.length == 13) {
        phone = phone.substring(3); // Removing the country code and the leading zero
    }
    else if (phone?.startsWith('1') && phone?.length == 11) {
        phone = phone.substring(1); 
    }
  
    // Check if the phone number has the correct length before formatting
    if (phone?.length == 10) {
        return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;
    }
  
    return phone;
  }
  const formik = useFormik({
    initialValues: intialValue,
    validationSchema: validationSchema,
    validateOnChange: async () => {},
    onSubmit: async (values) => {
      setLoader2(true);
      props.setData({
        phone: values?.phone,
        email: values?.email,
        zipcode: values?.zipCode,
        firstname: values?.firstname,
        lastname: values?.lastname,
        prefix: values?.prefix,
        serviceAddress: values?.serviceAddress,
      });
      if (sameAsService) {
        values.shippingAddress = values.serviceAddress;
        values.shippingAddress2 = values.serviceAddress2;
        values.shippingCity = values.serviceCity;
        values.shippingState = values.serviceState;
        values.shippingZipcode = values.zipCode;
      }
      setLoader(true);
      toast.dismiss();
      const queryString = window.location.search.slice(1);
      var url = window?.location?.href;
      const currentPath = window.location.pathname;
      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({
          ...values,
          source: "Lead Capture Form",
          campaignSource: queryString ? queryString : "",
          partnerName: "",
          url: url ? url : "",
          leadRoute: currentPath ? currentPath : "",
          status: 9,
          step: 1,
          stepStatus: "Incompleted",
          slug: slug ? slug : "",
        }),
      };
      if(!props.leadFound){
        fetch(apiUrl, requestOptions)
          .then((response) => {
            if (!response?.ok) {
              throw new Error(
                response?.status == 429
                  ? "Too many requests from this IP, please try again after a day"
                  : response?.status == 409
                  ? 409
                  : "You are already connected with comlink through this email"
              );
            }
            return response?.json(); // Parse the response body as JSON
          })
          .then((data) => {
            // toast.success("Thanks for contacting. Have a great day!");
            props?.setLeadId(data?.leadId);
            formik.resetForm();
            props.nextStep();
          })
          .catch((error) => {
            if (error == "Error: 409") {
              toast.error(
                "You are already connected with comlink through this email"
              );
            }
            console.error("Error:", error);
          })
          .finally(() => {
            setLoader(false);
            setLoader2(false);
          });
        }else{
          const requestOptions = {
            method: "PUT",
            headers,
            body: JSON.stringify({
              ...values,
              id: props?.leadId,
              status: 10,
              step: 2,
              stepStatus: "Incompleted",
            }),
          };
    
          fetch(updateLead, requestOptions)
            .then((response) => {
              if (!response?.ok) {
                throw new Error(
                  response?.status == 429
                    ? "Too many requests from this IP, please try again after a day"
                    : response?.status == 409
                    ? 409
                    : "Something went wrong"
                );
              }
              return response?.json(); // Parse the response body as JSON
            })
            .then((data) => {
              props.nextStep();
            })
            .catch((error) => {
              console.error("Error:", error);
            })
            .finally(() => {
              setLoader(false);
              setLoader2(false);
            });
        }
    },
  });

  const handleSameAsService = () => {
    // Update sameAsService state and use the updated value
    setSameAsService((prevSameAsService) => {
      const updatedSameAsService = !prevSameAsService;

      // Update other state or execute logic based on the updated value
      if (updatedSameAsService) {
        setSameAsServiceDisabled(true);
        formik.setFieldValue("shippingAddress", formik.values?.serviceAddress);
        formik.setFieldValue(
          "shippingAddress2",
          formik.values?.serviceAddress2
        );
        formik.setFieldValue("shippingZipcode", formik.values?.zipCode);
        formik.setFieldValue("shippingCity", formik.values?.serviceCity);
        formik.setFieldValue("shippingState", formik.values?.serviceState);
      } else {
        setSameAsServiceDisabled(false);
      }

      // Return the updated value
      return updatedSameAsService;
    });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="application-step-form-wraper">
          <div className="custom-container">
            <div className="dealstep1-content-wraper deals-custom-wrap-screen">
              <div className="application-step1-top-content d-flex flex-column justify-content-center align-items-center">
                <h4 className="text-uppercase theme-clr mb-lg-2 mb-1">
                  SIGN UP FOR INTERNET
                </h4>
                <h3 className="green-clr text-uppercase">
                  Receive $100 Visa Gift Card Or Tablet
                </h3>
                <h6 className="theme-clr">
                  Discover unbeatable deals on Internet, TV, and phone services
                </h6>
              </div>
              <div className="application-step1-bottom-content">
                <div className="step1-left-content">
                  <div className="step1-img-wraper">
                    <img src={Images.bannerupdated} alt="img" />
                  </div>
                  <div className="terms-text">
                    <a className="text-dark">
                      <span className="me-2 text-white">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </span>
                      Terms & conditions apply. Subject to carrier approval &
                      service ability.
                    </a>
                  </div>
                </div>
                <div className="step1-right-content">
                  <div className="form_content blue-form-box">
                    <h6 className="font-bold mb-lg-4 mb-3">
                      Let’s Get Started!
                    </h6>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row ">
                        <div className="col-md-12">
                          <div className="row">
                            {/* <div className="col-lg-2   col-12">
                              <div className="mb-lg-4 mb-3">
                                 <label>
                              Prefix <span>*</span>
                            </label> 
                                <select
                                  {...formik.getFieldProps("prefix")}
                                  className={`form-control mt-0 appear_none ${
                                    formik.touched.prefix &&
                                    formik.errors.prefix
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="prefix"
                                  name="prefix"
                                >
                                  <option value="" hidden>
                                    Title
                                  </option>
                                  <option value="Mr.">Mr.</option>
                                  <option value="Mrs.">Mrs.</option>
                                  <option value="Ms.">Ms.</option>
                                  <option value="Other">Other</option>
                                </select>
                                {formik.touched.prefix &&
                                  formik.errors.prefix && (
                                    <div className="for-red">
                                      {formik.errors.prefix}
                                    </div>
                                  )}
                              </div>
                            </div> */}
                            <div className="col-lg-6  col-6">
                              <div className="mb-lg-4 mb-3">
                                {/* <label>
                              First Name <span>*</span>
                            </label> */}
                                <input
                                  type="text"
                                  className={`form-control ${
                                    formik.touched.firstname &&
                                    formik.errors.firstname
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  {...formik.getFieldProps("firstname")}
                                  id="fname"
                                  placeholder="First Name"
                                  name="firstname"
                                />
                                {formik.touched.firstname &&
                                  formik.errors.firstname && (
                                    <div className="for-red">
                                      {formik.errors.firstname}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="col-lg-6  col-6">
                              <div className=" ">
                                {/* <label>
                              Last Name <span>*</span>
                            </label> */}
                                <input
                                  type="text"
                                  className={`form-control ${
                                    formik.touched.lastname &&
                                    formik.errors.lastname
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  {...formik.getFieldProps("lastname")}
                                  id="l-name"
                                  placeholder="Last Name"
                                  name="lastname"
                                  required=""
                                />
                                {formik.touched.lastname &&
                                  formik.errors.lastname && (
                                    <div className="for-red">
                                      {formik.errors.lastname}
                                    </div>
                                  )}
                              </div>
                            </div>
                            {/* <div className="col-lg-5">
                          <div className="mb-lg-4 mb-3">
                            <label>Middle Name</label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.middleName &&
                                formik.errors.middleName
                                  ? "is-invalid"
                                  : ""
                              }`}
                              {...formik.getFieldProps("middleName")}
                              id="m-name"
                              placeholder="Middle Name"
                              name="middleName"
                            />
                            {formik.touched.middleName &&
                              formik.errors.middleName && (
                                <div className="for-red">
                                  {formik.errors.middleName}
                                </div>
                              )}
                          </div>
                        </div> */}
                          </div>
                        </div>

                        {/* <div className="col-md-12">
                    <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="">
                     
                        <input
                          type="text"
                          {...formik.getFieldProps("serviceAddress")}
                          className={`form-control service-address ${
                            formik.touched.serviceAddress &&
                            formik.errors.serviceAddress
                              ? "is-invalid"
                              : ""
                          }`}
                          id="serviceAddress"
                          placeholder="Enter Address"
                          name="serviceAddress"
                        />
                        {formik.touched.serviceAddress &&
                          formik.errors.serviceAddress && (
                            <div className="for-red">
                              {formik.errors.serviceAddress}
                            </div>
                          )}
                      </div>
                    </div>
                  

                    </div>
                    </div> */}
                      </div>
                      <div className="row">
                        <div className="form-group col-lg-6 col-6 mb-lg-4 mb-3">
                          <input
                            type="email"
                            placeholder=" Your email"
                            {...formik.getFieldProps("email")}
                            x
                            className={`form-control ${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="for-red">{formik.errors.email}</div>
                          )}
                        </div>
                        <div className="form-group col-lg-6 col-6 mb-lg-4 mb-3">
                          <ReactInputMask
                            className={`form-control ${
                              formik.touched.phone && formik.errors.phone
                                ? "is-invalid"
                                : ""
                            }`}
                            type="dob"
                            mask="(999) 999-9999"
                            {...formik.getFieldProps("phone")}
                            placeholder="Phone number"
                          />
                          {formik.touched.phone && formik.errors.phone && (
                            <div className="for-red">{formik.errors.phone}</div>
                          )}
                        </div>

                        <h6 className="pb-xxl-4 pb-md-3 pb-2">
                          Service Address
                        </h6>
                        <div className="col-lg-8 col-12 mb-lg-4 mb-3">
                          <div className="">
                            {/* <label>
                          Address 1<span>*</span>
                        </label> */}
                            <input
                              type="text"
                              {...formik.getFieldProps("serviceAddress")}
                              className={`form-control service-address ${
                                formik.touched.serviceAddress &&
                                formik.errors.serviceAddress
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="serviceAddress"
                              placeholder="Enter Address"
                              name="serviceAddress"
                            />
                            {formik.touched.serviceAddress &&
                              formik.errors.serviceAddress && (
                                <div className="for-red">
                                  {formik.errors.serviceAddress}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-4 col-6 mb-lg-4 mb-3">
                          <div className="">
                            {/* <label>
                          Address 1<span>*</span>
                        </label> */}
                            <input
                              type="text"
                              {...formik.getFieldProps("serviceAddress2")}
                              className={`form-control service-address ${
                                formik.touched.serviceAddress2 &&
                                formik.errors.serviceAddress2
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="serviceAddress2"
                              placeholder="Apt, Unit, etc"
                              name="serviceAddress2"
                            />
                            {formik.touched.serviceAddress2 &&
                              formik.errors.serviceAddress2 && (
                                <div className="for-red">
                                  {formik.errors.serviceAddress2}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-lg-4 col-6 mb-lg-4 mb-3">
                          <div className="">
                            {/* <label>
                          Address 1<span>*</span>
                        </label> */}
                            <input
                              type="text"
                              {...formik.getFieldProps("serviceCity")}
                              className={`form-control service-address ${
                                formik.touched.serviceCity &&
                                formik.errors.serviceCity
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="serviceCity"
                              placeholder="City"
                              name="serviceCity"
                            />
                            {formik.touched.serviceCity &&
                              formik.errors.serviceCity && (
                                <div className="for-red">
                                  {formik.errors.serviceCity}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-4  col-lg-4 col-6 mb-lg-4 mb-3">
                          <div className="">
                            {/* <input
                              type="text"
                              {...formik.getFieldProps("serviceState")}
                              className={`form-control service-address ${
                                formik.touched.serviceState &&
                                formik.errors.serviceState
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="serviceState"
                              placeholder="Enter state"
                              name="serviceState"
                            /> */}
                            <select
                              id="serviceState"
                              placeholder="State"
                              {...formik.getFieldProps("serviceState")}
                              className="form-control mt-0 appear_none"
                            >
                              <option value="" selected hidden label="State" />
                              {USStates.map((state, index) => (
                                <option
                                  key={index}
                                  value={state}
                                  label={state}
                                />
                              ))}
                            </select>
                            {formik.touched.serviceState &&
                              formik.errors.serviceState && (
                                <div className="for-red">
                                  {formik.errors.serviceState}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="form-group col-lg-4  col-6  mb-lg-4 mb-3">
                          <ReactInputMask
                            type="zipCode"
                            placeholder="Zipcode"
                            mask="99999"
                            {...formik.getFieldProps("zipCode")}
                            className={`form-control ${
                              formik.touched.zipCode && formik.errors.zipCode
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.touched.zipCode && formik.errors.zipCode && (
                            <div className="for-red">
                              {formik.errors.zipCode}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="terms_condition d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column align-items-start">
                          <label className="d-flex custom-control overflow-checkbox relative">
                            <input
                              name="optIn"
                              className="overflow-control-input"
                              type="checkbox"
                              onChange={formik.handleChange}
                              checked={formik.values.optIn}
                            />
                            <span className="overflow-control-indicator "></span>
                            <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2"></span>
                          </label>
                          <p className="Submitting_form cri-pointer mb-1">
                            <OptInModal />
                            <span className="red_staric">*</span>
                          </p>
                          {formik.touched.optIn && formik.errors.optIn && (
                            <div className="for-red ">
                              {formik.errors.optIn}
                            </div>
                          )}
                        </div>
                        <div className="d-flex justify-content-end">
                          {loader2 ? (
                            <button disabled className="btn theme-btn d-flex ">
                              <span> Loading...</span>
                              <div className="spinner"></div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn green-filled-btn"
                            >
                              Get Started
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LeadCapture;
