import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Images } from "../../../../assets/assets";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from 'react-lottie-player'
import playbtn from "../../../../assets/json-files/play-btn.json";
import callUs from "../../../../assets/json-files/call-us.json";
import providers from "../../../../assets/json-files/providers.json";
import bsetDeal from "../../../../assets/json-files/bset-deal.json";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import "./Bst-Provider.css";
import { Link } from 'react-router-dom';
import Internet from './../../../Pages/Internet/Internet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function BestDeals(props) {
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://static.myfinance.com/embed/inlineMedia_core.js";
        script.async = true;
        script.onload = () => {
            window.initializeInline({ loadInlineCss: true });
        };
        document.head.appendChild(script);

        // return () => {
        //   // Clean up script when component unmounts
        //   document.head.removeChild(script);
        // };
    }, []);

    const handleIconClick = () => {
        setIsPlaying(true);
    };
    return (
        <section className='lead-providers'>
            {/* <div ref={props.section1Ref} className='custom-container mt-lg-5 mt-3'>
                    <div
                        className="myFinance-widget"
                        data-ad-id="ebe461ff-6e71-4484-bf1c-03e43e225e01"
                        data-campaign="chameleon-comlink-zip-detected-providers"
                    ></div>
                </div> */}
            {/* <section className='comlinks-links'>
                    <Container fluid className="custom-container">
                        <div className='row'>
                            <div className='col-xl-3 col-md-6 mb-md-0 mb-3'>
                                <div className="btn-wraper">
                                    <Link className="cta" to="https://www.saleslinkcrm.com/acp/comlinkbundle.com" target="_blank">
                                        <div className="comlink-logos d-flex align-items-center">
                                            <span> 
                                                <span className='theme_blue'>FREE</span> <span className='theme_green'>INTERNET</span>
                                            </span>
                                        </div>
                                        <span className='next--arrow'>
                                            <svg viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#439037"></path>
                                                    <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#439037"></path>
                                                    <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#439037"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-6 mb-md-0 mb-3'>
                                <div className="btn-wraper">
                                    <Link className="cta" to="https://comlinkpartnerprogram.com/" target="_blank">
                                        <span>
                                            <img
                                                src={Images.comlinkpartner}
                                                className="comlink-logos"
                                                alt="img"
                                            />
                                        </span>
                                        <span className='next--arrow'>
                                            <svg viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#439037"></path>
                                                    <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#439037"></path>
                                                    <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#439037"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </Link>
                                </div>
                            </div>

                            <div className='col-xl-3 col-md-6 mb-md-0 mb-3'>
                                <div className="btn-wraper">
                                    <Link className="cta" to="https://comlinktv.com/" target="_blank">
                                        <span>
                                            <img
                                                src={Images.comlinktv}
                                                className="comlink-logos"
                                                alt="img"
                                            />
                                        </span>
                                        <span className='next--arrow'>
                                            <svg viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#439037"></path>
                                                    <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#439037"></path>
                                                    <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#439037"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-6 mb-md-0 mb-3'>

                                <div className="btn-wraper">
                                    <Link className="cta" to="https://comlinkmobility.com/" target="_blank">
                                        <span>
                                            <img
                                                src={Images.comlinkmobile}
                                                className="comlink-logos"
                                                alt="img"
                                            />
                                        </span>
                                        <span className='next--arrow'>
                                            <svg viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <path className="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#439037"></path>
                                                    <path className="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#439037"></path>
                                                    <path className="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#439037"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </Container>
                </section> */}

            <Container fluid className="custom-container">
                <Row className='align-items-center padding-bottom padding-top'>
                    <Col lg={5} className='order-lg-1 order-2'>
                        <div className='lead-provider-content'>
                            <h4 className='theme-clr '>Comlink Bundle Provides Best Deals On Internet Services</h4>
                            <p>Searching for an internet or TV provider can be hectic. Contact Us, and we will guide you about the best feasible options according to your zip code.</p>
                            <div className='baner-btn'>
                                <button onClick={() => props?.scrollToSection(props.section1Ref)} className='btn theme-btn'>Order Now</button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={7} className='d-flex justify-content-end  ps-xl-5 ps-lg-3 ps-0 order-lg-1 order-1' >
                        <div className='lead-vidoe-container relative'>
                            <div id='myVideo'>
                                <div className='poster-container' style={{ display: isPlaying ? 'none' : 'block' }}>
                                    <img
                                        src={Images.videoposter}
                                        className='poster-image'
                                        alt='Poster'
                                    />
                                    <div className='play-icon' onClick={handleIconClick}>
                                        <div id='playButton'>
                                            {/* Assuming your Lottie component is set up correctly */}
                                            <Lottie loop animationData={playbtn} play />
                                        </div>
                                    </div>
                                </div>
                                {isPlaying && (
                                    <iframe
                                        className='iframe-video'
                                        width='100%'
                                        src='https://www.youtube.com/embed/EOA6MrvDna4?si=hUiBeS79yaiB3Zax'
                                        title='YouTube video player'
                                        frameBorder='0'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BestDeals;
