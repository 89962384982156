import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./SpecialOffer.css";
import { Images } from "../../../assets/assets";
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify'
import SignatureCanvas from "react-signature-canvas";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactInputMask from 'react-input-mask';
import "../OptimumInternet/OptimumInternet.css"

const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY
const ORIGIN = process.env.REACT_APP_ORIGIN

const headers = {
    'Content-Type': 'application/json',
    origin: ORIGIN, // Replace with the actual client URL
    apikey: API_KEY, // Replace with your actual API key
};

const apiUrl = `${API_URL}/leads/create`

const USStates = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida',
    'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
    'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
    'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah',
    'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const intialValue = {
    firstname: "",
    lastname: "",
    // areaCode: "",
    phone: "",
    email: "",
    dob: "",
    securityNumber: "",
    streetAddress: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipcode: "",
    previousStreetAddress: "",
    previousStreetAddress2: "",
    previousCity: "",
    previousState: "",
    previousZipcode: "",
    installDate: "",
    freeInternet: true,
    andriod: false,
    visa: false,
    quries: "",
    agreement: false,
    agreement2: false,
}

const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required').matches(/^[A-Za-z\s]+$/, 'First name  must contain only letters and spaces'),
    lastname: Yup.string().required('Last name is required').matches(/^[A-Za-z\s]+$/, 'Last name must contain only letters and spaces'),
    // areaCode: Yup.string()
    //     .matches(/^\d{3}$/, 'Invalid area code')
    //     .required('Area code is required')
    //     .nullable(),
    phone: Yup.string()
        .required('Phone number is required')
        .matches(/^[^_]*$/, 'Phone number must be exactly 10 digits')
        .length(14, 'Phone number must be exactly 10 digits'),
    email: Yup.string().email('Invalid email address').required('Email is required').nullable(),
    dob: Yup.string()
        .required('Date of birth is required')
        .matches(
            /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19\d{2}|[2-9]\d{3})$/,
            'Invalid date format (MM/DD/YYYY) or year must be greater than 1900'
        )
        .test('is-valid-date', 'Invalid date', function (value) {
            if (!value) {
                return false;
            }

            const [month, day, year] = value.split('/').map(Number);
            const today = new Date();
            const inputDate = new Date(year, month - 1, day);

            if (inputDate.getMonth() + 1 !== month) {
                return false; // Month is invalid
            }

            if (inputDate.getDate() !== day) {
                return false; // Day is invalid (handles different days in months)
            }

            if (inputDate.getFullYear() !== year) {
                return false; // Year is invalid
            }

            if (year < 1900) {
                return false; // Year must be greater than 1900
            }

            // Handle leap years
            if (month === 2) {
                const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
                if ((isLeapYear && day > 29) || (!isLeapYear && day > 28)) {
                    return false; // Invalid day for February
                }
            }

            // Validate other months with 30 or 31 days
            const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
            if (monthsWith31Days.includes(month) && day > 31) {
                return false; // Invalid day for months with 31 days
            }

            if (day > 30) {
                return false; // Invalid day for months with 30 days
            }

            return true;
        }),
    securityNumber: Yup.string()
        .required('Security number is required')
        .matches(/^\d{4}$/, 'Security number must be exactly 4 digits'),
    streetAddress: Yup.string().required('Street address is required').nullable(),
    streetAddress2: Yup.string().nullable(),
    city: Yup.string()
        .required('City is required')
        .matches(/^[A-Za-z\s]+$/, 'City must contain only letters and spaces')
        .nullable(),
    state: Yup.string()
        .required('State is required')
        .matches(/^[A-Za-z\s]+$/, 'State must contain only letters and spaces')
        .nullable(),
    zipcode: Yup.string()
        .required('Zip code is required')
        .length(5, 'Zip code must be exactly 5 characters')
        .matches(/^[0-9]+$/, 'Zipcode must contain only numbers'),
    previousStreetAddress: Yup.string().nullable(),
    previousStreetAddress2: Yup.string().nullable(),
    previousCity: Yup.string().nullable().matches(/^[A-Za-z\s]+$/, 'City must contain only letters and spaces'),
    previousState: Yup.string().nullable().matches(/^[A-Za-z\s]+$/, 'State must contain only letters and spaces'),
    previousZipcode: Yup.string()
        .length(5, 'Zip code must be exactly 5 characters')
        .matches(/^[0-9]+$/, 'Zipcode must contain only numbers')
        .nullable(),
    installDate: Yup.string()
        .matches(
            /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
            'Invalid date format. Please use MM/DD/YYYY'
        )
        .test('is-future-date', 'Date should be in the future', function (value) {
            if (!value) {
                return false; // Date is required
            }

            // Parse the input string into month, day, and year
            const [month, day, year] = value.split('/').map(Number);
            const inputDate = new Date(year, month - 1, day);

            // Get the current date and set hours, minutes, seconds, and milliseconds to 0
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return inputDate > today;
        }).nullable(),
    freeInternet: Yup.boolean().nullable(),
    android: Yup.boolean().nullable(),
    visa: Yup.boolean().nullable(),
    queries: Yup.string().nullable(),
    agreement: Yup.boolean().oneOf([true], 'You must agree to the terms').required('Agreement is required'),
    agreement2: Yup.boolean().oneOf([true], 'You must agree to the terms').required('Agreement is required'),
});

function SpecialOffer() {
    const [freeInternetCount, setFreeInternetCount] = useState(1);
    const [andriodCount, setAndriodCount] = useState(1);
    const [visaCount, setVisaCount] = useState(1);
    const [sign, setSignatureData] = useState(null);
    const [signError, setSignError] = useState(null);
    const signatureRef = React.useRef();
    const startPoint = React.useRef();

    const formik = useFormik({
        initialValues: intialValue,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (sign == null) {
                toast.dismiss();
                setSignError("Sign is required");
                toast.error("We need your sign for agreement")
                return;
            }
            const requestOptions = {
                method: 'POST',
                headers,
                body: JSON.stringify({ ...values, visaCount, andriodCount, freeInternetCount, sign }),
            };
            toast.dismiss();
            fetch(apiUrl, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(response.status == 429
                            ? "Too many requests from this IP, please try again after a day"
                            : "Something went wrong");
                    }
                    return response.json(); // Parse the response body as JSON
                })
                .then(data => {
                    // Handle the successful response here
                    toast.success("Thanks for contacting. Have a great day!")
                    formik.resetForm()
                    setSignatureData(null);
                })
                .catch(error => {
                    // Handle any errors that occur during the request
                    toast.error("" + error);
                    console.error('Error:', error);
                })
        },
    })

    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    // Function to clear the signature
    const clearSignature = () => {
        signatureRef.current.clear();
        setSignatureData(null);
    };

    // Function to get the signature data
    const getSignatureData = () => {
        if (signatureRef.current.isEmpty()) {
            // Handle empty signature case
            setSignError("We need your sign for agreement");
            return;
        }
        const data = signatureRef.current.toDataURL();
        setSignatureData(data);
        setSignError(null)
        signatureRef.current.clear();
    };

    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // You can change this to "auto" for an instant scroll
        });
    }, [])
    return (
        <>
            <div className='special-offer-page optimum-internet-services-wraper'>
                <div fluid className="sp-offer-form-wraper " >
                    <section>
                        <div className='d-flex  align-items-center optimum-navbar'>
                            <div className='comlink-bundle-logo'>
                                {/* <Link to="/"> */}
                                <img
                                    src={Images.siteLogo}
                                    className="img-fluid"
                                    alt="img"
                                />
                                {/* </Link> */}
                            </div>
                            <div className="call-to-claim">
                                <h6>CALL TO CLAIM THIS OFFER:</h6>
                                <a style={{ cursor: 'pointer', color: "black", textDecoration: 'none' }} href="tel:+18662612148"><h5>866-261-2148</h5></a>
                            </div>
                        </div>
                        <div>
                            <div className='special-offer-baner'>
                                <Row>
                                    <Col>
                                        <div className='special-offer-content'>
                                            <h2>You May Qualify</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='affordable-programs'>
                                <Row className='d-flex justify-content-center align-items-center'>
                                    <Col lg={6} className='order-lg-1 order-2'>
                                        <div className='offer-circle'>
                                            <h3 className='theme-clr'>FREE INTERNET</h3>
                                            <p className='green-clr'>With The Affordable Connectivity Program</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='d-flex justify-content-end order-mobile order-lg-2 order-1'>
                                        <div className='optimum-logo-wraper'>
                                            {/* <Link to="/"> */}
                                            <img
                                                src={Images.optmimumWhiteLogo}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                            {/* </Link> */}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </section>
                    <section className='special-offer-form' >
                        <Row>
                            <Col>
                                <div className='sp-form-content-wraper'>
                                    <h2>(SPECIAL OFFER)</h2>
                                    <h6>RECEIVE A FREE SMART PHONE OR $50 VISA GIFT CARD</h6>
                                    <p>
                                        This Offer Is Exclusive To Comlink Bundle Customers! The Smart Phone Or Gift Card Will Be Supplied By Comlink Total Solutions Corp For Approved Installed & Activated Customers. Offer Fulfillment May Take Up 90 Days After Install. Restrictions Apply. Customers Must Be ACP Approved To Receive This Offer. The ACP Is A Government Benefit Program Operated By The Federal Communications Commission (FCC) That Is Designed To Make Broadband More Affordable For Struggling Households. The ACP Provides Discounts Of Up To $30 Per Eligible Household On Monthly Broadband Internet Access Service (Or Up To $75 Per Eligible Household On Tribal Lands) And Up To $100 On Certain Connected Devices (With A Required Co-Pay Of Between $10 And $50). ACP Services And Devices Are Subject To Consumer Eligibility, Provider Participation, Product Availability, And Approval By The FCC And Its ACP Administrator, The Universal Service Administrative Company (USAC). The ACP Was Launched On December 31, 2021, And Replaces The Emergency Broadband Benefit (EBB) Program.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <div className='sp-form-content' >
                            <Form onSubmit={formik.handleSubmit}>
                                <Row ref={startPoint}>
                                    <h6 className='sp-24-heading'>Customer Detail<span className='for-red'> *</span></h6>
                                </Row>
                                <Row>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name"
                                                {...formik.getFieldProps('firstname')}
                                            />
                                            {formik.touched.firstname && formik.errors.firstname && (
                                                <div className="for-red">{formik.errors.firstname}</div>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <Col lg={6}>
                                        <Form.Group controlId="lastname">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Last Name" {...formik.getFieldProps('lastname')} />
                                            {formik.touched.lastname && formik.errors.lastname && <div className="for-red">{formik.errors.lastname}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Area Code */}
                                    {/* <Col lg={6}>
                                        <Form.Group controlId="areaCode">
                                            <Form.Label>Area Code</Form.Label>
                                            <Form.Control type="text" maxLength={3} placeholder="Area Code" {...formik.getFieldProps('areaCode')} />
                                            {formik.touched.areaCode && formik.errors.areaCode && <div className="for-red">{formik.errors.areaCode}</div>}
                                        </Form.Group>
                                    </Col> */}
                                    {/* Phone Number */}
                                    <Col lg={6}>
                                        <Form.Group controlId="phone">
                                            <Form.Label>Phone Number</Form.Label>
                                            <ReactInputMask className='form-control' type="text" placeholder="Phone Number" mask='(999) 999-9999' {...formik.getFieldProps('phone')} />
                                            {formik.touched.phone && formik.errors.phone && <div className="for-red">{formik.errors.phone}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Customer Email */}
                                    <Col lg={6}>
                                        <Form.Group controlId="email">
                                            <Form.Label>Customer Email</Form.Label>
                                            <Form.Control type="email" placeholder="Customer Email" {...formik.getFieldProps('email')} />
                                            {formik.touched.email && formik.errors.email && <div className="for-red">{formik.errors.email}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Date Of Birth */}
                                    <Col lg={6}>
                                        <Form.Group controlId="dob">
                                            <Form.Label>Date Of Birth</Form.Label>
                                            <ReactInputMask className='form-control' type="text" placeholder="MM/DD/YYYY" mask="99/99/9999" {...formik.getFieldProps('dob')} />
                                            {formik.touched.dob && formik.errors.dob && <div className="for-red">{formik.errors.dob}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Last 4 Digits Of Your Social Security Number */}
                                    <Col lg={6}>
                                        <Form.Group controlId="securityNumber">
                                            <Form.Label>Last 4 Digits Of Your Social Security Number</Form.Label>
                                            <ReactInputMask className='form-control' type="text" placeholder="Last 4 Digits Of Your Social Security Number" mask="9999" {...formik.getFieldProps('securityNumber')} />
                                            {formik.touched.securityNumber && formik.errors.securityNumber && <div className="for-red">{formik.errors.securityNumber}</div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='service-address gap-top-40'>
                                    <div className='col-12'>
                                        <h6 className='sp-24-heading'>Service Address<span className='for-red'> *</span></h6>
                                    </div>
                                    <Col lg={6}>
                                        <Form.Group controlId="streetAddress">
                                            <Form.Label>Street Address</Form.Label>
                                            <Form.Control type="text" placeholder="Street Address" {...formik.getFieldProps('streetAddress')} />
                                            {formik.touched.streetAddress && formik.errors.streetAddress && <div className="for-red">{formik.errors.streetAddress}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Street Address Line 2 */}
                                    <Col lg={6}>
                                        <Form.Group controlId="streetAddress2">
                                            <Form.Label>Street Address Line 2</Form.Label>
                                            <Form.Control type="text" placeholder="Street Address Line 2" {...formik.getFieldProps('streetAddress2')} />
                                            {formik.touched.streetAddress2 && formik.errors.streetAddress2 && <div className="for-red">{formik.errors.streetAddress2}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* City */}
                                    <Col lg={6}>
                                        <Form.Group controlId="city">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control type="text" placeholder="Enter your city" {...formik.getFieldProps('city')} />
                                            {formik.touched.city && formik.errors.city && <div className="for-red">{formik.errors.city}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* State / Province */}
                                    <Col lg={6}>
                                        <Form.Group controlId="state">
                                            <Form.Label>State / Province</Form.Label>
                                            <select type="text" placeholder="Enter your State / Province" {...formik.getFieldProps('state')} className='form-control'>
                                                <option value="" selected hidden label="Select a state" />
                                                {USStates.map((state, index) => (
                                                    <option key={index} value={state} label={state} />
                                                ))}
                                            </select>
                                            {formik.touched.state && formik.errors.state && <div className="for-red">{formik.errors.state}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Postal / Zip Code */}
                                    <Col lg={6}>
                                        <Form.Group controlId="zipcode">
                                            <Form.Label>Postal / Zip Code</Form.Label>
                                            <ReactInputMask className='form-control' type="text" placeholder="Postal / Zip Code" mask="99999" {...formik.getFieldProps('zipcode')} />
                                            {formik.touched.zipcode && formik.errors.zipcode && <div className="for-red">{formik.errors.zipcode}</div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='previous-address gap-top-40'>
                                    <div className='col-12 d-flex'>
                                        <h6 className='sp-24-heading'>Previous Address</h6>
                                        <p className='green-clr'> (If You Lived Less Than 2 Years At Current Address)</p>
                                    </div>
                                    <Col lg={6}>
                                        <Form.Group controlId="previousStreetAddress">
                                            <Form.Label>Previous Street Address</Form.Label>
                                            <Form.Control type="text" placeholder="Previous Street Address" {...formik.getFieldProps('previousStreetAddress')} />
                                            {formik.touched.previousStreetAddress && formik.errors.previousStreetAddress && <div className="for-red">{formik.errors.previousStreetAddress}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Previous Street Address Line 2 */}
                                    <Col lg={6}>
                                        <Form.Group controlId="previousStreetAddress2">
                                            <Form.Label>Previous Street Address Line 2</Form.Label>
                                            <Form.Control type="text" placeholder="Previous Street Address Line 2" {...formik.getFieldProps('previousStreetAddress2')} />
                                            {formik.touched.previousStreetAddress2 && formik.errors.previousStreetAddress2 && <div className="for-red">{formik.errors.previousStreetAddress2}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Previous City */}
                                    <Col lg={6}>
                                        <Form.Group controlId="previousCity">
                                            <Form.Label>Previous City</Form.Label>
                                            <Form.Control type="text" placeholder="Enter your previous city" {...formik.getFieldProps('previousCity')} />
                                            {formik.touched.previousCity && formik.errors.previousCity && <div className="for-red">{formik.errors.previousCity}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Previous State / Province */}
                                    <Col lg={6}>
                                        <Form.Group controlId="previousState">
                                            <Form.Label>Previous State / Province</Form.Label>
                                            <select type="text" placeholder="Enter your State / Province" {...formik.getFieldProps('previousState')} className='form-control'>
                                                <option value="" selected hidden label="Select a state" />
                                                {USStates.map((state, index) => (
                                                    <option key={index} value={state} label={state} />
                                                ))}
                                            </select>
                                            {formik.touched.previousState && formik.errors.previousState && <div className="for-red">{formik.errors.previousState}</div>}
                                        </Form.Group>
                                    </Col>
                                    {/* Previous Postal / Zip Code */}
                                    <Col lg={6}>
                                        <Form.Group controlId="previousZipcode">
                                            <Form.Label>Previous Postal / Zip Code</Form.Label>
                                            <ReactInputMask className='form-control' type="text" placeholder="Postal / Zip Code" mask="99999" {...formik.getFieldProps('previousZipcode')} />
                                            {formik.touched.previousZipcode && formik.errors.previousZipcode && <div className="for-red">{formik.errors.previousZipcode}</div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className='service-order gap-top-40'>
                                    <div className='d-flex'>
                                        <h6 className='sp-24-heading'>Service Order</h6>
                                    </div>
                                    <Row className='mb-lg-4 mb-md-3 mb-2'>
                                        <Col lg={2}>
                                            <div className='service-or-img'>
                                                <img
                                                    src={Images.optmimumblackLogo}
                                                    className="img-fluid"
                                                    alt="img"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={10}>
                                            <div className='service-order-content'>
                                                <label className="custom-control overflow-checkbox relative">
                                                    <input
                                                        name="freeInternet"
                                                        disabled
                                                        className='overflow-control-input'
                                                        type="checkbox"
                                                        onChange={formik.handleChange}
                                                        checked={formik.values.freeInternet}
                                                    />
                                                    <span className="overflow-control-indicator "></span>
                                                    <span className="overflow-control-description">
                                                        <p className="Submitting_form">
                                                            (FREE INTERNET) Optimum 300 Mbps Fiber Internet $40/Mo. FREE With The Affordable Connectivity Program Free
                                                        </p>
                                                        <p className='fs-16'>
                                                            Selection Of This Offer Is Subject To Approval And Serviceability. Affordable Connectivity Program: The Federal Government’s Affordable Connectivity Program (ACP) Operated By The FCC Reduces Internet Charges For Qualifying Low-Income Households With A One Per Household Benefit Up To $30/Mo. Or Up To $75/Mo. For Residents Of Tribal Lands. ACP Eligibility Requirements, How To Enroll And Plans That May Be Free With The Benefit Can Be Found At Optimum.Com/Acpinternet. Bulk Internet And Commercial Accounts Excluded. Terms, Conditions And Restrictions Apply. Free Internet Claim Is Based On Internet Tiers Priced $30 Or Less. Credit Will Be Applied To Qualified And Active Customer Accounts Until The ACP Expires, At Which Time Customers Will Be Responsible For Full Payment Of The Monthly Bill At Our Then Current Rates And Regular Terms And Conditions. This Benefit Is Portable To And From Other Participating Providers But Is Not Transferable To Other Individuals Or Households. Nonpayment Of Monthly Bill May Result In Service Suspension And/Or Downgrade. FREE 300 MBPS W/ ACP OFFER: Offer For New Optimum Residential Customers That Have Qualified For The Affordable Connectivity Program. After Applying Customer’s ACP Benefit Of $30/Mo., Optimum Will Credit The Remaining $10/Mo. Balance And Any Applicable Taxes And Fees. Offer Is Not Transferrable, May Not Be Combined W/Other Offers, Is Limited To Advertised Level Of Srvc, And Is Not Available In All Areas. Other Terms, Restrictions & Conditions Apply. Auto-Pay Enrollment Encouraged But Not Required To Receive ACP Benefits Or Any Optimum Internet Offer Advertised As Free After The ACP Benefit Is Applied.
                                                        </p>
                                                    </span>
                                                </label>
                                                <div className='quantity d-flex align-items-center'>
                                                    <p className='fs-16'>Quantity</p>
                                                    <div className='quantity-nbrs d-flex align-items-center'>
                                                        <span className='decrement p-1' onClick={() => freeInternetCount > 1 && setFreeInternetCount(freeInternetCount - 1)}>-</span>
                                                        {freeInternetCount}
                                                        <span className='increment p-1' onClick={() => { setFreeInternetCount(freeInternetCount + 1) }}>+</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-lg-4 mb-md-3 mb-2'>
                                        <Col lg={2}>
                                            <div className='service-or-img'>
                                                <img
                                                    src={Images.bluemobile}
                                                    className="img-fluid"
                                                    alt="img"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={10}>
                                            <div className='service-order-content'>
                                                <label className="custom-control overflow-checkbox relative">
                                                    <input
                                                        name="andriod"
                                                        className='overflow-control-input'
                                                        type="checkbox"
                                                        onChange={formik.handleChange}
                                                        checked={formik.values.andriod}
                                                    />
                                                    <span className="overflow-control-indicator "></span>
                                                    <span className="overflow-control-description">
                                                        <p className="Submitting_form">
                                                            Free Android Phone
                                                        </p>
                                                        <p className='fs-16'>
                                                            Stay Connected With Family And Friends While Traveling The World. The J9L Comes With 4G LTE 1/2/3/4/5/7/12/17/28 That Ensures You Always Have Access To Worldwide Carrier Coverage While You Are On The Go! Free Andriod Phone By Comlink Total Solutions Corp For Approved Installed & Activated Customers. Offer Fulfillment May Take Up 90 Days After Installation. Restrictions Apply. Customers Must Be ACP-Approved To Receive This Offer.
                                                        </p>
                                                    </span>
                                                </label>
                                                <div className='quantity d-flex align-items-center'>
                                                    <p className='fs-16'>Quantity</p>
                                                    <div className='quantity-nbrs d-flex align-items-center'>
                                                        <span className='decrement p-1' onClick={() => andriodCount > 1 && setAndriodCount(andriodCount - 1)}>-</span>
                                                        {andriodCount}
                                                        <span className='increment p-1' onClick={() => setAndriodCount(andriodCount + 1)}>+</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-lg-4 mb-md-3 mb-2'>
                                        <Col lg={2}>
                                            <div className='service-or-img'>
                                                <img
                                                    src={Images.spvisa}
                                                    className="img-fluid"
                                                    alt="img"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={10}>
                                            <div className='service-order-content'>
                                                <label className="custom-control overflow-checkbox relative">
                                                    <input
                                                        name="visa"
                                                        className='overflow-control-input'
                                                        type="checkbox"
                                                        onChange={formik.handleChange}
                                                        checked={formik.values.visa}
                                                    />
                                                    <span className="overflow-control-indicator "></span>
                                                    <span className="overflow-control-description">
                                                        <p className="Submitting_form">
                                                            $50 Visa Gift Card
                                                        </p>
                                                        <p className='fs-16'>
                                                            Free Visa Gift Card By Comlink Total Solutions Corp For Approved Installed & Activated Customers. Offer Fulfillment May Take Up 90 Days After Installation. Restrictions Apply. Customers Must Be ACP Approved To Receive This Offer.
                                                        </p>
                                                    </span>
                                                </label>
                                                <div className='quantity d-flex align-items-center'>
                                                    <p className='fs-16'>Quantity</p>
                                                    <div className='quantity-nbrs d-flex align-items-center'>
                                                        <span className='decrement p-1' onClick={() => visaCount > 1 && setVisaCount(visaCount - 1)}>-</span>
                                                        {visaCount}
                                                        <span className='increment p-1' onClick={() => setVisaCount(visaCount + 1)}>+</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={2}>
                                        </Col>
                                        <Col lg={10}>
                                            <div className='quantity d-flex align-items-center'>
                                                <p className='fs-16'>Total:</p>
                                                $0.00
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Row className='service-address gap-top-40'>
                                    <div className='col-12'>
                                        <h6 className='sp-24-heading pb-lg-3 pb-2'>Install Date Request (Subject To Availability)</h6>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Select Date</Form.Label>
                                            <ReactInputMask
                                                type="text"
                                                mask="99/99/9999"
                                                placeholder="MM/DD/YYYY"
                                                name="installDate"
                                                {...formik.getFieldProps('installDate')}
                                                // value={formik.values.installDate}
                                                // onChange={formik.handleChange}
                                                className={`form-control  ${formik.touched.installDate && formik.errors.installDate ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.installDate && formik.errors.installDate && <div className="for-red">{formik.errors.installDate}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Question</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type A Question"
                                                name="installDateQuestion"
                                                {...formik.getFieldProps('installDateQuestion')}
                                                // value={formik.values.installDateQuestion}
                                                // onChange={formik.handleChange}
                                                className={`${formik.touched.installDateQuestion && formik.errors.installDateQuestion ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.installDateQuestion && formik.errors.installDateQuestion && <div className="for-red">{formik.errors.installDateQuestion}</div>}
                                        </Form.Group>
                                    </div>
                                </Row>
                                <Row className='service-address gap-top-40'>
                                    <div className='col-12 d-flex flex-xl-row flex-column mb-xl-0 mb-2'>
                                        <h6 className='sp-24-heading pb-lg-3 pb-2 text-xl-start text-center'>PLEASE TYPE YOUR FULL NAME ELECTRONIC SIGNATURE</h6>
                                        <p className='green-clr text-xl-start text-center'>(By Signing This Form You Are Agreeing To All Terms And Conditions) *</p>
                                    </div>
                                    <div className='col-12 signature-pad'>
                                        <Form.Group className="mb-3 d-flex align-items-center flex-xl-row flex-column" controlId="exampleForm.ControlInput1">
                                            {/* <Form.Label>John Doe</Form.Label>
                                                <Form.Control type="text" placeholder="John Doe" /> */}
                                            {sign == null && (
                                                <React.Fragment>
                                                    <label className="me-3">Enter your sign here</label>
                                                    <SignatureCanvas
                                                        ref={signatureRef}
                                                        penColor="black"
                                                        canvasProps={{
                                                            style: {
                                                                width: "300px",
                                                                height: "200px",
                                                                border: "2px solid black",
                                                                padding: "10px",
                                                                borderRadius: "10px", // Add this line to set the border radius
                                                            },
                                                        }}
                                                    />
                                                    <div className="d-flex">
                                                        <button
                                                            className="btn green-borderd-btn ms-xl-3 small-btn mt-xl-0 mt-3 "
                                                            onClick={getSignatureData}
                                                        >
                                                            Apply
                                                        </button>
                                                        <button
                                                            className="btn theme-btn small-btn ms-xl-3 mt-xl-0 mt-3 ms-xl-0 ms-2"
                                                            onClick={clearSignature}
                                                        >
                                                            Clear
                                                        </button>

                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <div>
                                                {sign && (
                                                    <React.Fragment>
                                                        <img
                                                            style={{
                                                                width: "300px",
                                                                height: "200px",
                                                                border: "2px solid black",
                                                                padding: "10px",
                                                                borderRadius: "10px", // Add this line to set the border radius
                                                            }}
                                                            src={sign}
                                                            alt="Signature"
                                                        />
                                                        <button
                                                            className="btn theme-btn small-btn ms-xl-3 mt-xl-0 mt-3"
                                                            onClick={() => setSignatureData(null)}
                                                        >
                                                            Retry
                                                        </button>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                            {signError && <div className="for-red">{signError}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className='col-12 d-flex mt-lg-3'>
                                        <h6 className='sp-24-heading pb-lg-3 pb-2'>Please Enter Any Additional Info Or Questions</h6>
                                    </div>
                                    <div className='col-12'>
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter Information"
                                                name="quries"
                                                {...formik.getFieldProps('quries')}
                                                // value={formik.values.quries}
                                                // onChange={formik.handleChange}
                                                className={`${formik.touched.quries && formik.errors.quries ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.quries && formik.errors.quries && <div className="for-red">{formik.errors.quries}</div>}
                                        </Form.Group>
                                    </div>
                                </Row>
                                <Row className='pt-lg-5 pt-3'>
                                    <Col>
                                        <div className='service-order-content'>
                                            <label className="custom-control overflow-checkbox relative">
                                                <input
                                                    name="agreement"
                                                    className='overflow-control-input'
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.agreement}
                                                />
                                                <span className="overflow-control-indicator "></span>
                                                <span className="overflow-control-description pl-25">
                                                    Yes
                                                </span>
                                            </label>
                                            <p className='fs-16'>
                                                By submitting this form, you acknowledge that you permit Comlink
                                                Total Solutions Corp or its affiliates express consent to contact
                                                you at the number and/or email address you have provided above with
                                                automated technology in relation to this inquiry via phone, e-mail,
                                                or text message. You understand that making a purchase is not
                                                required to consent to receive communications from Comlink Total
                                                Solutions. By opting in, you agree to receive marketing
                                                communications, including but not limited to, calls, text messages,
                                                and emails from Comlink Total Solutions Corp, its affiliates,
                                                including Benefits Pilot. These communications may include
                                                promotional offers, updates, and other information about our
                                                products and services. Your consent is entirely voluntary, and you
                                                may opt-out at any time. If you change your mind and no longer wish
                                                to receive these communications, you can revoke your consent by
                                                using any of the following methods: To opt-out please email{" "}
                                                <a href="mailto:support@gocomlink.com">support@gocomlink.com</a>{" "}
                                                please provide your full name, email and contact number and ask to
                                                be removed from our list or call{" "}
                                                <a href="tel:833 604-1080">833 604-1080</a> and asked to be removed
                                                from our list.<span className='for-red'> *</span>
                                            </p>
                                        </div>
                                        {formik.touched.agreement && formik.errors.agreement && (
                                            <div className="for-red">{formik.errors.agreement}</div>
                                        )}

                                    </Col>
                                </Row>
                                <Row className='pt-lg-5 pt-3'>
                                    <Col>
                                        <div className='service-order-content'>
                                            <label className="custom-control overflow-checkbox relative">
                                                <input
                                                    name="agreement2"
                                                    className='overflow-control-input'
                                                    type="checkbox"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.agreement2}
                                                />
                                                <span className="overflow-control-indicator "></span>
                                                <span className="overflow-control-description pl-25">
                                                    Yes
                                                </span>
                                            </label>
                                            <p className='fs-16'>
                                                I Authorize Comlink Total Solutions Corp And Its Contracted Partners, For The Purpose Of Applying For, Determining Eligibility, Enrolling In And Seeking Reimbursement Of ACP Service And Connected Device Benefits, To Collect, Use, Share, And Retain My Personal Information, Including But Not Limited To Information Required For The Purpose Of Establishing Eligibility For And Enrolling In The ACP Program, And Including, But Not Limited To, Full Name, Full Residential Address, Date Of Birth, Last Four Digits Of Social Security Number, Telephone Number, Eligibility Criteria And Status, The Date On Which The ACP Service Discount Was Initiated And If Applicable, Terminated, Usage Status And Other Compliance Requirements, The Amount Of Support Being Sought For The Service, And Information Necessary To Establish Identity And Verifiable Address. This Information May Be Shared With Universal Service Administrative Company (USAC) To Ensure Proper Administration Of The ACP Program. Failure To Provide Consent Will Result In Me Being Denied ACP Service And Connected Device Benefits. I Acknowledge That The Information I Have Provided On This Consent To Obtain Consumer Reports Is True And Accurate. I Certify That I Have Been Provided With A Copy Of The Disclosure Concerning Consumer Reports Which You May Obtain About Me In Connection With My Application. I Have Also Been Provided With A Copy Of The Notice Entitled “A Summary Of Your Rights Under The Fair Credit Reporting Act.” I Hereby Consent To Your Gathering Such Information About Me From COMLINK TOTAL SOLUTIONS OR ITS PARTNER OR AFFLIATES Or Any Other Agent You Or COMLINK TOTAL SOLUTIONS OR ITS PARTNER OR AFFLIATES Authorize, And To Receive And Use My Historic Lifeline Enrollment Information For Enrollment Verification And Waste, Fraud And Abuse Mitigation Purposes.<span className='for-red'> *</span>
                                            </p>
                                        </div>
                                        {formik.touched.agreement2 && formik.errors.agreement2 && (
                                            <div className="for-red">{formik.errors.agreement2}</div>
                                        )}
                                    </Col>
                                </Row>
                                <div className="baner-btn d-flex justify-content-end">
                                    <Button className="btn theme-btn" type="submit" onClick={() => scrollToSection(startPoint)}>Submit</Button>
                                </div>
                            </Form>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default SpecialOffer

