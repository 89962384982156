import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Lottie from 'react-lottie-player'
import faq from "../../../../assets/json-files/faq.json";
import "./faqs.css";

function Faqs() {
    return (
        <div>
            <section className='faqs-section'>
                <Container fluid className="custom-container">
                    <Row className='align-items-center'>
                        <Col lg={7} className='order-lg-1 order-2'>
                            <div className='faqs-content'>
                                <h3 className='theme-clr'>Frequently Asked Questions</h3>
                                <Accordion defaultActiveKey="0" flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>What services does Comlink Bundle provide?</Accordion.Header>
                                        <Accordion.Body>
                                            We are an authorized dealer for top telecom providers in USA. The website lets you view and order all the applicable offers from local phone, cable, and internet providers in your area.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Which are the best cable Tv, internet and phone service providers?</Accordion.Header>
                                        <Accordion.Body>
                                            Your location and the availability of the supplier will determine the answer. Find out which phone, cable, and internet service providers are available in your area by running your complete street address on our website. You can also talk to us at <a href="tel:+18662612148">866-261-2148</a>.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>What is the best rural internet?</Accordion.Header>
                                        <Accordion.Body>
                                            Many rural locations are served by Xfinity and CenturyLink, but if you live in a remote location, your only alternatives for internet connectivity may be satellite internet from Viasat or HughesNet or fixed wireless internet service from companies like Rise Broadband.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>What is the best satellite internet?</Accordion.Header>
                                        <Accordion.Body>
                                            The two most well-known satellite carriers, Viasat and HughesNet, presently offer speeds of up to 100 Mbps and 25 Mbps, respectively.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Is 5G faster than fiber-optic internet?</Accordion.Header>
                                        <Accordion.Body>
                                            No, Fiber internet provides much faster speeds which can go beyond 1 Gbps.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                        <Col lg={5} className='d-flex justify-content-end ps-xl-5 ps-lg-3 ps-0 order-lg-2 order-1'>
                            <div className='faq-lottie w-100'>
                                <Lottie className='faq-lottie'
                                    loop
                                    animationData={faq}
                                    play
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Faqs
