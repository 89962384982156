import React, { useEffect, useState } from "react";
import "./Business.css";
import "../../../Pages/CmsBanner/CmsBanner.css";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY_FOR_BUSINESS; // For Comlink Bundle Business
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN,
  apikey: API_KEY,
};

const intialValue = {
  fullname: "",
  phone: "",
  email: "",
  services: [],
  agreement: false,
};

const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .required("Full name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "Full name  must contain only letters and spaces"
    ),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Phone number must be exactly 10 digits")
    .length(14, "Phone number must be exactly 10 digits"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .nullable(),
  services: Yup.array()
    .required("Please select at least one service")
    .min(1, "Please select at least one service"),
  // services: Yup.array().nullable(),
  agreement: Yup.boolean()
    .oneOf([true], "You must agree to the terms")
    .required("Agreement is required"),
});

const apiUrl = `${API_URL}/leads/create`;

function Business() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [multiSelected, setMultiSelected] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // maxWidth: '400px',
      margin: "0 auto",
      padding: "8px",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "none",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      border: "1px ",
      color: "#888888",
      fontSize: "18px",
      fontWeight: "300",
      // boxShadow: !state.isFocused ? 'none' : '0 0 8px rgba(0, 0, 0, 0.3)'
    }),
  };

  const formik = useFormik({
    initialValues: intialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader2(true);
      toast.dismiss();
      const queryString = window.location.search.slice(1);
      var url = window?.location?.href;
      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({
          ...values,
          source: "Business Leads",
          campaignSource: queryString ? queryString : "",
          url: url ? url : "",
        }),
      };
      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              response.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : "Something went wrong"
            );
          }
          return response.json(); // Parse the response body as JSON
        })
        .then((data) => {
          toast.success("Thanks for contacting. Have a great day!");
          // navigate("/");
          formik.resetForm();
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          toast.error("" + error);
          console.error("Error:", error);
        })
        .finally(() => {
          setLoader2(false);
        });
    },
  });

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="Qoute__content">
          <div className="custom-container">
            <div className="qoute__form">
              <div className="row">
                <div className="col-xl-6">
                  <div className="form_image">
                    {/* <img src={QouteForm} alt="QouteForm" /> */}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form_content">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label for="exampleInputEmail1">
                          Your Name<span className="red_staric">*</span>
                        </label>
                        <input
                          {...formik.getFieldProps("fullname")}
                          autoComplete="off"
                          name="fullname"
                          type="text"
                          className={`form-control ${
                            formik.touched.fullname && formik.errors.fullname
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your fullname"
                        />
                        {formik.touched.fullname && formik.errors.fullname && (
                          <div className="for-red">
                            {formik.errors.fullname}
                          </div>
                        )}
                      </div>
                      <div className="form-groupmb-xl-4 mb-lg-3 mb-2">
                        <label for="exampleInputPassword1">
                          Your Phone<span className="red_staric">*</span>
                        </label>
                        <ReactInputMask
                          className={`form-control ${
                            formik.touched.phone && formik.errors.phone
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          mask="(999) 999-9999"
                          {...formik.getFieldProps("phone")}
                          placeholder="Enter your phone number"
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <div className="for-red">{formik.errors.phone}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label for="exampleInputPassword1">
                          Your Email<span className="red_staric">*</span>
                        </label>
                        <input
                          type="email"
                          placeholder="Enter your email"
                          {...formik.getFieldProps("email")}
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="for-red">{formik.errors.email}</div>
                        )}
                      </div>
                      {/* <div className="form-group">
                        <select
                          name="service"
                          {...formik.getFieldProps("service")}
                        >
                          <option value="" selected hidden>
                            Which Services Are You Interested In ?
                          </option>
                          <option value="cableTv">CableTv</option>
                          <option value="internet">Internet</option>
                          <option value="phone">Phone</option>
                          <option value="freeInternet">Free Internet</option>
                        </select>
                      </div> */}
                      <div className="form-group">
                        <label for="exampleInputPassword1">
                          Which Services Are You Interested In ?
                          <span className="red_staric">*</span>
                        </label>
                        <Select
                          className={` ${
                            formik.touched.services && formik.errors.services
                              ? "is-invalid"
                              : ""
                          }`}
                          name="services"
                          styles={customStyles}
                          options={[
                            { value: "cableTv", label: "CableTv" },
                            { value: "internet", label: "Internet" },
                            { value: "phone", label: "Phone" },
                            { value: "freeInternet", label: "Free Internet" },
                            { value: "other", label: "Other" },
                          ]}
                          isMulti
                          onChange={(selectedOptions) => {
                            selectedOptions?.length > 0
                              ? setMultiSelected([...selectedOptions])
                              : setMultiSelected([]);
                            formik.setFieldValue(
                              "services",
                              selectedOptions?.length > 0
                                ? selectedOptions.map((option) => option?.value)
                                : []
                            );
                          }}
                          value={multiSelected}
                        />
                        {formik.touched.services && formik.errors.services && (
                          <div className="for-red">
                            {formik.errors.services}
                          </div>
                        )}
                      </div>
                      <div className="terms_condition d-flex align-items-start">
                        <label className="custom-control overflow-checkbox relative">
                          <input
                            name="agreement"
                            className="overflow-control-input"
                            type="checkbox"
                            onChange={formik.handleChange}
                            checked={formik.values.agreement}
                          />
                          <span className="overflow-control-indicator "></span>
                          <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                            <p className="Submitting_form">
                              By submitting this form, you acknowledge that you
                              permit Comlink Total Solutions Corp or its
                              affiliates express consent to contact you at the
                              number and/or email address you have provided
                              above with automated technology in relation to
                              this inquiry via phone, e-mail, or text message.
                              You understand that making a purchase is not
                              required to consent to receive communications from
                              Comlink Total Solutions. By opting in, you agree
                              to receive marketing communications, including but
                              not limited to, calls, text messages, and emails
                              from Comlink Total Solutions Corp, its affiliates,
                              including Benefits Pilot. These communications may
                              include promotional offers, updates, and other
                              information about our products and services. Your
                              consent is entirely voluntary, and you may opt-out
                              at any time. If you change your mind and no longer
                              wish to receive these communications, you can
                              revoke your consent by using any of the following
                              methods: To opt-out please email{" "}
                              <a href="mailto:support@gocomlink.com">
                                support@gocomlink.com
                              </a>{" "}
                              please provide your full name, email and contact
                              number and ask to be removed from our list or call{" "}
                              <a href="tel:833 604-1080">833 604-1080</a> and
                              asked to be removed from our list.s
                              <span className="red_staric">*</span>
                            </p>
                          </span>
                        </label>
                      </div>
                      {formik.touched.agreement && formik.errors.agreement && (
                        <div className="for-red">{formik.errors.agreement}</div>
                      )}
                      <div className="d-flex  justify-content-end mt-xl-4 mt-3 mb-xl-4 mb-3">
                        {loader2 ? (
                          <button disabled className="btn theme-btn d-flex ">
                            <span className="ml_9"> Loading...</span>
                            <div className="spinner"></div>
                          </button>
                        ) : (
                          <button type="submit" className="btn theme-btn">
                            Get In Touch
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Business;
