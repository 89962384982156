import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Stripe from "../Stripe/Stripe";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};
const apiUrlForPayment = `${API_URL}/leads/get-lead-payment`;

const options = {
  style: {
    base: {
      iconColor: "#409131",
      height: "50px",
      fontWeight: "400",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#212529",
      },
    },
    invalid: {
      iconColor: "#ff0101",
      color: "#ff0101",
    },
  },
};

const StripeModal = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardError, setCardError] = useState("");
  const [flag, setFlag] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  let price = 4.99;

  const handleCardChange = (event) => {
    try {
      if (event.complete && !event.error) {
        setFlag(true);
        setCardError("");
      } else {
        setFlag(false);
        setCardError("Please enter your card information.");
      }
    } catch (error) {
    }
  };

  const sumbitPayment = async () => {
    setButtonLoader(true);
    toast.dismiss();
    if(!props?.leadId || props?.leadId == ""){
      toast.error("Required data is missing");
    }

    if (!stripe || !elements) {
      setButtonLoader(false);
      return;
    }
    if (!flag) {
      return;
    }

    const firstToken = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (firstToken?.error) {
      toast.error(firstToken?.error?.message || "Something went wrong!");
      console.error(firstToken.error, "error");
      setButtonLoader(false);
      return;
    }

    let payload = {
      paymentMethod: "Card",
      price: props.price,
      source: firstToken?.token?.id,
      leadId: props?.leadId ?? "", // This leadId is for testing
    };

    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({
        ...payload,
      }),
    };
    fetch(`${apiUrlForPayment}`, requestOptions)
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Parse the JSON data
        return response.json();
      })
      .then((data) => {
        toast.success(data?.message || "Payment done successfully");
        props.setStripeModalShow(false);
        // Handle the data
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  return (
    <Modal
      show={props.stripeModalShow}
      //   onHide={props.setStripeModalShow(false)}
      size="xl"
      className="common-modal-wrape payment-detail-modal"
    >
      <Modal.Body className="modal-common-body">
        <div className="col-12  payment-detail--row">
          <div className="d-flex  paymeny-deatail-header">
            <h4 className="blue-text">Add a Payment Method</h4>
          </div>
          <div className="m-4">
            <div className="col-lg-12">
              <h5 className="">
                {" "}
                ${props.price} will be charged as a service fee, one of our
                representatives will call you shortly regarding your order.
              </h5>

              {/* <label>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
              </label> */}
              <div className="stripe_field_wrap">
                <CardElement
                  options={options}
                  onChange={(e) => handleCardChange(e)}
                />
              </div>
              {cardError ? <p className="for-red">{cardError}</p> : null}
            </div>
            <div className="d-flex justify-content-lg-end align-lg-items-end align-items-center justify-content-center mt-lg-4 mt-3">
              {buttonLoader ? (
                <div className="btn green-filled-btn">
                  <span role="status">Loading...</span>
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                <button
                  onClick={() => sumbitPayment()}
                  className="btn green-filled-btn"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StripeModal;
