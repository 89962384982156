import React from 'react'
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='leads-footer'>
      <hr />
      <Container className="custom-container">
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <ul className='leads-footer-page updated-footer'>
            <li><Link to="/page/terms">Terms & Conditions</Link></li>
            <li><Link to="/page/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/page/california-privacy-notice">California Privacy Notice</Link></li>
            <li><Link to="/page/unsubscribe">Unsubscribe</Link></li>
            <li><Link to="/page/accessible-view">Accessible View</Link></li>
            <li><Link to="/page/faq">FAQ</Link></li>
            <li><Link to="/page/advertising-disclosure">Advertising Disclosure</Link></li>
            <li><Link to="/page/privacy-rights-request">Privacy Rights Request Form</Link></li>
            <li><Link to="/page/profiling-opt-out">Right to Opt Out of Profiling</Link></li>
            <li><Link to="/page/targeted-opt-out">Right to Opt Out of Targeted Advertising</Link></li>
            <li className='border_left'><Link to="/page/limit-sensitive-info">Limit the use of my sensitive personal information</Link></li>
            <li><Link to="/page/ca-unsubscribe">Do Not Share or Sell My Personal Information</Link></li>
          </ul>
          <p className='right-reserved'>©2024 QuantumMax. All rights reserved.</p>
        </div>
      </Container>
    </div>
  )
}

export default Footer