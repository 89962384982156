import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// __ __ Packages/Style/Media __ __ //
import StepWizard from "react-step-wizard";
import { toast } from "react-toastify";
import "./Deal.css";
import LeadCapture from "./LeadCapture";
import LastStep from "./LastStep";
import LeadDetailCapture from "./LeadDetailCapture";
import Deals from "./Deals";
import OrderSumary from "../../../Pages/GetOrderSumary/GetOrderSumary";

// __ __ Components/Helpers __ __ //

const FiberOffer = () => {
  const { search } = useLocation();
  const [leadFound, setLeadFound] = useState(false);
  const [serviceZipcode, setServiceZipcode] = useState("");
  const [leadId, setLeadId] = useState("");
  const [flag, setFlag] = useState(false);
  const [step, setStep] = useState("1");
  const [data, setData] = useState({
    phone: "",
    email: "",
    zipcode: "",
    type: [],
    preferredDevice: "",
  });
  const queryParams = new URLSearchParams(search);
  const lead = queryParams.get("leadId");
  useEffect(() => {
    if (lead) {
      setLeadId(lead);
      setLeadFound(true);
      setFlag(true);
 
    }
  }, []);
  return (
    <StepWizard>
      <LeadCapture setLeadId={setLeadId} setData={setData} 
        leadFound={leadFound}
         setLeadFound={setLeadFound}
         flag={flag}
         leadId={leadId}
      />
      <LeadDetailCapture
        setServiceZipcode={setServiceZipcode}
        setData={setData}
        leadId={leadId}
        data={data}
      />
      <Deals
        serviceZipcode={serviceZipcode}
        leadId={leadId}
        setFlag={setFlag}
        flag={flag}
        data={data}
        setStep={setStep}
      />
      <OrderSumary step={step} leadId={leadId} />
      <LastStep leadId={leadId} flag={flag} step={step}/>
    </StepWizard>
  );
};

export default FiberOffer;
