import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function TermsConditionStep3(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a onClick={handleShow} className="font-bold orange-clr text-under-line">
        Terms & Conditions
      </a>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="common-modal-wraper"
      >
        <Modal.Header closeButton>
          <Modal.Title className="theme-clr">
            <h5>Terms & Conditions</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-common-body">
          <p>
            {props?.data?.termAndConditionDetail
              ? props?.data?.termAndConditionDetail
              : "-"}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TermsConditionStep3;
