import React, { useEffect, useState } from "react";
import "./CompanyPage.css";
import "../../../Pages/CmsBanner/CmsBanner.css";
import Loader from "../Loader/LoaderQuantumMax";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};

const apiUrl = `${API_URL}/cms/page?slug=`;

function CmsPage() {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [content, setContent] = useState('');

  const { slug } = useParams();

  useEffect(() => {
    
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });

    const fetchPageContent = async () => {
      try {
        const response = await fetch(`${apiUrl}${slug}`, {
          method: 'GET',
          headers: headers,
        });

        if (!response.ok) {
          throw new Error('Page not found');
        }
        const data = await response.json();
        setContent(data.content);
      } catch (error) {
        toast.error("" + error);
      } finally {
        setLoader(false);
      }
    };

    fetchPageContent();

  }, []);

  useEffect(() => {
    // Find and execute script tags
    const scriptElements = document.querySelectorAll('.Qoute__content script');

    scriptElements.forEach((script) => {
      const newScript = document.createElement('script');
      if (script.src) {
        // External script (like the one you're using)
        newScript.src = script.src;
      } else {
        // Inline script
        newScript.innerHTML = script.innerHTML;
      }
      document.body.appendChild(newScript);
      document.body.removeChild(newScript); // Clean up after execution
    });
  }, [content]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="Qoute__content">
          <div className="custom-container">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      )}
    </>
  );
}

export default CmsPage;
