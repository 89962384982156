import React, { useEffect, useRef, useState } from 'react'
import Banner from './Banner.js/Banner'
import QuestionAnswer from './QuestionAnswer/QuestionAnswer';
import BestProviders from './BestProviders/BestProviders';
import SearchProvider from './SearchProvider/SearchProvider';
import Faqs from '../Common/Faqs/Faqs';
import BestDeals from './BestProviders/BestDeals';
import Loader from '../Common/Loader/Loader';

function Home() {
	const section1Ref = useRef(null);
	const [loader, setLoader] = useState(true)

	const scrollToSection = (ref) => {
		if (ref && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			});
		}
	};
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Adds smooth scrolling animation
		});
		setTimeout(() => { setLoader(false) }, 1000)
	}, [])
	return (
		<>
			{loader ? <Loader /> : <div className='homepg-wraper'>
				<Banner scrollToSection={scrollToSection} section1Ref={section1Ref} />
				<BestDeals scrollToSection={scrollToSection} section1Ref={section1Ref} />
				<QuestionAnswer />
				<BestProviders scrollToSection={scrollToSection} section1Ref={section1Ref} />
				<SearchProvider scrollToSection={scrollToSection} section1Ref={section1Ref} />
				<Faqs />
			</div>}
		</>
	)
}

export default Home
