import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import comlinkDisability from "../../../../../src/assets/images/Comlink-disablity.JPG";
import comlinkMobileimg from "../../../../../src/assets/images/comlink-mobile-line.png";
import comlinkTvimg from "../../../../../src/assets/images/comlink-tv-trial.png";
import comlinkbundleimg from "../../../../../src/assets/images/acp-logo.png";
import netspend from "../../../../../src/assets/images/netspend-img.png";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CongratulationModal from "../Congratulation-modal/Congratulation-modal";
import StripeModal from "../Stripe/Stripe";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};
const apiUrl = `${API_URL}/leads/get-one`;
const apiUrlForPayment = `${API_URL}/leads/get-lead-payment`;

const apiUpdateUrl = `${API_URL}/leads/update`;

const options = {
  style: {
    base: {
      iconColor: "#409131",
      height: "50px",
      fontWeight: "400",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#212529",
      },
    },
    invalid: {
      iconColor: "#ff0101",
      color: "#ff0101",
    },
  },
};

const LastStep = (props) => {
 
  const [leadDetail, setLeadDetail] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [cardError, setCardError] = useState("");
  const [flag, setFlag] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [netSpendButton, setNetSpendButton] = useState(true);

  const [activeStep, setActiveStep] = useState(1); // Initialize activeStep state
  const [showModal, setShowModal] = useState(false); // Initialize modal visibility state
  const [stripeModalShow, setStripeModalShow] = useState(false); // Initialize modal visibility state
  const [horoscopesButton, setHoroscopesButton] = useState(true);
  const [submitButton, setSubmitButton] = useState(false);
  const [installationDate, setInstallationDate] = useState("");
  const [preferredInstallTime, setPreferredInstallTime] = useState("");

  //start to show thank you model
  const [stepLinks, setStepLinks] = useState([
    "https://www.nblv4trk.com/2FMZLP/M2GJW/?sub1=&sub2=&sub3=", // Link for Step 1
    "https://www.benefitspilot.com/", // Link for Step 2 (initialize empty, you can change as needed)

  ]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
  }, []);

  useEffect(() => {
    let totalPrice = 0;

    leadDetail?.dealsId?.forEach((item) => {
      leadDetail?.dealSelectedItems.forEach((data) => {
        if (data?._id === item?._id) {
          let planPrice = parseFloat(item.planPrice || "0");
          let acpDiscountPrice = parseFloat(item.acpDiscountPrice || "0");
          let professionalInstallationFee = parseFloat(
            item.professionalInstallationFee || "0"
          );

          totalPrice +=
            data?.acpDiscount &&
            item?.acpDiscountPrice &&
            item?.acpDiscountPrice !== "0"
              ? data?.installationOptions === "Professional Installation" &&
                item?.professionalInstallationFee &&
                item?.professionalInstallationFee !== "0"
                ? planPrice + professionalInstallationFee - acpDiscountPrice
                : data?.installationOptions !== "Professional Installation"
                ? planPrice - acpDiscountPrice
                : planPrice - acpDiscountPrice
              : data?.installationOptions === "Professional Installation" &&
                item?.professionalInstallationFee &&
                item?.professionalInstallationFee !== "0"
              ? planPrice + professionalInstallationFee
              : planPrice;
        }
      });
    });
    setSubTotal(totalPrice);
  }, [leadDetail]);

  useEffect(() => {
    if (props.leadId) {
      fetch(`${apiUrl}?id=${props?.leadId}`)
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          // Parse the JSON data
          return response.json();
        })
        .then((data) => {
          // Handle the data
          setLeadDetail(data.lead);
        })
        .catch((error) => {
          // Handle errors
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
          setLeadDetail({});
        });
    }
  }, [props.flag]);

  const handleStepClick = (step, e) => {
    e.preventDefault();
    setActiveStep(step);
  };

  const handleButtonClick = (btnType) => {
    if (btnType === "skip") {
      const nextStep = activeStep + 1;
      if (nextStep <= stepLinks.length && stepLinks[nextStep - 1] !== "") {
        setActiveStep(nextStep);
      }
    } else if (btnType === "prev") {
      const prevStep = activeStep - 1;
      if (prevStep > 0) {
        setActiveStep(prevStep);
      }
    } else {
      if (stepLinks[activeStep - 1] !== "") {
        window.open(stepLinks[activeStep - 1], "_blank");
      }
    }
  };

  const stepImages = [
    comlinkDisability,
    comlinkDisability,
    // comlinkMobileimg,
    // comlinkMobileimg,
    // comlinkbundleimg,
  ];

  useEffect(() => {
    const queryString = window.location.search.slice(1);
    var url = window?.location?.href;

   if (props?.step == "4") {
      const timer = setTimeout(() => {
        handleShowModal(); // Show the modal after 2 seconds
      }, 2000);
    }
    // return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [props?.step]);

  const handleInstallDate = () => {
    toast.dismiss();
    if (!installationDate || !preferredInstallTime) {
      toast.warning("Please add complete information");
      return;
    }
    const requestOptions = {
      method: "PUT",
      headers,
      body: JSON.stringify({
        id: props?.leadId,
        installationDate: installationDate,
        preferredInstallTime: preferredInstallTime,
      }),
    };

    fetch(apiUpdateUrl, requestOptions)
      .then((response) => {
        if (!response?.ok) {
          throw new Error(
            response?.status == 429
              ? "Too many requests from this IP, please try again after a day"
              : response?.status == 409
              ? 409
              : "Something went wrong"
          );
        }
        return response?.json(); // Parse the response body as JSON
      })
      .then((data) => {
        toast.success("Submitted successfully");
        setSubmitButton(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {});
  };

  return (
    <div className="application-step-form-wraper">
      <div className="custom-container">
        <div className="leads-step4-wraper">
          <div className="lead-step-form-card padding-70">
            <div className="row">
              <div className="col-lg-7  mb-lg-0 mb-2 inserted-order-2">
                <div className="order-submitted-inner ">
                  <div className="inserted-display-none">
                    <div className="text-sm-start text-center">
                    <h6 className="theme-clr font-medium mb-2">
                      Order Submitted For Approval!
                    </h6>
                    <p className=" mb-2">
                      For quicker approval and installation please call the
                      number below. Once your order is approved you will receive
                      an approved order confirmation via email and SMS (data
                      rates may apply){" "}
                    </p>
                    <p className=" mb-2">
                      Call now to speak with an agent directly!
                    </p>
                    </div>
                   

                    <div className="row align-items-center">
                  
                      <div className="col-lg-5 ">
                      <div className="text-sm-start text-center">
                        <h5 className="contact-text">
                          <span className="theme-clr font-normal">Call:</span>{" "}
                          <a
                            style={{ textDecoration: "none" }}
                            href="tel:+18662612148"
                            className="green-clr font-medium "
                          >
                            866-261-2148
                          </a>
                        </h5>
                        <p className="grey-text  mb-2">
                          Mon-Fri 9am to 12am EST
                        </p>
                      </div>
                      </div>
                      
                      {/* <div className="col-lg-7 ">
                        <div className="grey-bg-card">
                          <div className="grey-bg-card-content">
                            <p className="grey-text ">
                              Don’t forget to have your confirmation and phone
                              number handy when you speak with the agent to
                              schedule your installation for service.
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-lg-7 ">
                       <div className="grey-bg-card mb-3 mt-2 installation-mobile-date">
                  <div className="grey-bg-card-content top-green-border">
                    <p className="font-bold mb-2">Installation Date</p>
                    <input
                      type="date"
                      className="form-control form-custom-date mb-3"
                      value={installationDate}
                      onChange={(e) => setInstallationDate(e.target.value)}
                    />
                    <div className="row">
                      <p className="font-bold mb-2">Preferred Install Time</p>
                      <div className="form-check col-xxl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="Morning 8am to 12pm"
                          disabled={submitButton}
                          checked={
                            preferredInstallTime === "Morning 8am to 12pm"
                          }
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Morning 8am to 12pm
                        </label>
                      </div>
                      <div className="form-check col-xxl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="Afternoon 1pm to 5pm"
                          disabled={submitButton}
                          checked={
                            preferredInstallTime === "Afternoon 1pm to 5pm"
                          }
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Afternoon 1pm to 5pm
                        </label>
                      </div>
                      <div className="form-check col-xxl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          value="Evening After 5pm"
                          disabled={submitButton}
                          checked={preferredInstallTime === "Evening After 5pm"}
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Evening After 5pm
                        </label>
                      </div>
                    </div>
                    {submitButton ? null : (
                    <div className="text-end mt-3">
                      <button
                        className="btn green-filled-btn "
                        onClick={() => handleInstallDate()}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                  </div>
              
                </div>
                </div>
                    </div>
                    
                  </div>
                  {/* modal */}
                  <div className="grey-bg-card-content top-green-border inserted-box">
                    <div className="thanku-screen  mt-2 inserted-thank-screen">
                      <div className="congratulation-modal last_step_congratulation_modal mt-6 ">
                        <div className="thanks-offers-wraper">
                          <h2>Wait, Check Out Our Exclusive Offers!</h2>
                          <div
                            id="smartwizard"
                            className="sw-main sw-theme-dots"
                          >
                            {/* <p>Please choose from the options below to view the incredible discounts on our other fantastic products for your home.</p> */}
                            <ul className="nav nav-tabs step-anchor">
                              {[1, 2].map((step) => (
                                <li
                                  key={step}
                                  className={`nav-item ${
                                    activeStep === step ? "active" : ""
                                  }`}
                                >
                                  <a
                                    href={`#step-${step}`}
                                    className="nav-link"
                                    onClick={(e) => handleStepClick(step, e)}
                                  >
                                    OFFER {step}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>

                          {/* <div className="item_cong">
            <a target="_blank" href={stepLinks[activeStep - 1]}>
              <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
            </a>
        
          </div> */}
                          {/* Conditional rendering based on activeStep */}
                          {activeStep === 1 && (
                            <div className="modal-box-custom second-offers-box p-3">
                              <div className="text-section">
                                {/* <div className="item_cong">
                    <a target="_blank" href={stepLinks[activeStep - 1]}>
                    <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
                  </a>
                    </div> */}
                                <div
                                  className="netspend-visa-wrapper"
                                  id="netspend"
                                >
                                  <h3>
                                    Netspend® All-Access ® Account by MetaBank®{" "}
                                  </h3>
                                  <div className="netspend-main-content">
                                    <div className="row ">
                                      <div className="col-lg-3">
                                        <img
                                          src={netspend}
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="col-lg-9">
                                        <p>
                                          All-Access is a bank account that
                                          helps you reach your goals. We’ll help
                                          you with the financial freedom to get
                                          there.
                                        </p>
                                        <p>
                                          I would like to apply for a Netspend
                                          All-Access Account by MetaBank!
                                        </p>
                                      </div>
                                    </div>
                                    <div className="text-btn-wrap text-center">
                                      {netSpendButton ? (
                                        <React.Fragment>
                                          <button
                                            type="button"
                                            className="btn btn-primary "
                                            onClick={() =>
                                              setNetSpendButton(false)
                                            }
                                          >
                                            Yes
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary "
                                            onClick={() =>
                                              setNetSpendButton(false)
                                            }
                                          >
                                            No
                                          </button>
                                        </React.Fragment>
                                      ) : null}
                                      <p>
                                        Subject to account activation and ID
                                        verification. Terms & costs apply.
                                        Account issued by MetaBank®, Member
                                        FDIC.{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="text-section">
                <p>This is content for step 3.</p>
              </div> */}
                            </div>
                          )}
                          {activeStep === 2 && (
                            <>
                              <div className="modal-box-custom p-3">
                                <div className="item_cong">
                                  <a
                                    target="_blank"
                                    href={stepLinks[activeStep - 1]}
                                  >
                                    <img
                                      src={stepImages[activeStep - 1]}
                                      alt={`Image for Step ${activeStep}`}
                                    />
                                  </a>
                                </div>
                                <p className="learn-more ps-1">
                                  Learn more{" "}
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 2L12 22"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M19 15L12 22L5 15"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </p>
                              </div>
                            </>
                          )}
                          {activeStep === 3 && (
                            <>
                              {/* <div className="modal-box-custom">
                <div className="item_cong">
                <a target="_blank" href={stepLinks[activeStep - 1]}>
                <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
              </a>
                </div>
                <p className="learn-more">Learn more  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L12 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M19 15L12 22L5 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></p> */}
                              {/* <div className="text-section">
                  <p>This is content for step 2.</p>
                </div> */}
                              {/* </div> */}
                              <div className="modal-box-custom second-offers-box p-3">
                                <div className="text-section">
                                  <div className="row" id="horoscope">
                                    <h3 className="text-capitalize">
                                      Access to your horoscopes has never been
                                      so effortless!
                                    </h3>
                                    <div className="col-lg-12">
                                      <h6 className="text-blue pb-lg-2 pb-md-3 pb-2">
                                        Receiving your predictions via texts
                                        messages is quick, easy and free*!
                                      </h6>
                                      {horoscopesButton ? (
                                        <div className="text-btn-wrap ">
                                          <button
                                            type="button"
                                            className="btn btn-primary "
                                            onClick={() =>
                                              setHoroscopesButton(false)
                                            }
                                          >
                                            Yes
                                          </button>

                                          <button
                                            type="button"
                                            className="btn btn-primary "
                                            onClick={() =>
                                              setHoroscopesButton(false)
                                            }
                                          >
                                            No
                                          </button>
                                        </div>
                                      ) : null}

                                      <span>
                                        By entering my number and clicking the
                                        "Yes" button, I verify that this is my
                                        mobile number and consent to receive
                                        Daily Horoscope &amp; Everyday
                                        Horoscopes text messages on my mobile
                                        phone. Message and data rates may apply.
                                        1 message/day. Reply HELP to 96059 for
                                        help, reply STOP to 96059 to opt-out. To
                                        obtain help you may: Email the service
                                        provider at:
                                        customercare@daily-horoscope.us &amp;
                                        contact@everydayhoroscopes.com Call the
                                        service provider at: +1 (888)-880-9693
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {activeStep === 4 && (
                            <div className="modal-box-custom">
                              <div className="item_cong">
                                <a
                                  target="_blank"
                                  href={stepLinks[activeStep - 1]}
                                >
                                  <img
                                    src={stepImages[activeStep - 1]}
                                    alt={`Image for Step ${activeStep}`}
                                  />
                                </a>
                                <p className="learn-more">
                                  Learn more{" "}
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 2L12 22"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M19 15L12 22L5 15"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </p>
                              </div>
                              {/* <div className="text-section">
                <p>This is content for step 4.</p>
              </div> */}
                            </div>
                          )}
                          {activeStep === 5 && (
                            <div className="modal-box-custom">
                              <div className="item_cong">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={stepLinks[activeStep - 1]}
                                >
                                  <img
                                    src={stepImages[activeStep - 1]}
                                    alt={`Image for Step ${activeStep}`}
                                  />
                                </a>
                                <p className="learn-more">
                                  Learn more{" "}
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 2L12 22"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M19 15L12 22L5 15"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </p>
                              </div>
                            </div>
                          )}
                          {/* {activeStep === 6 && (
        <div className="modal-box-custom">
            <div className="item_cong">
                    <a target="_blank" href={stepLinks[activeStep - 1]}>
                    <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
                  </a>
                    </div> 
                    <p className="learn-more">Learn more  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L12 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M19 15L12 22L5 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></p>
        </div>
      )} */}

                          {/* <div className="dual-btn-wrap mt-xxl-5 mt-3">
            <button className="btn green-filled-btn" onClick={handleButtonClick}>Click</button>
            <button className="btn green-filled-btn theme-blue-filled" fdprocessedid="ytqjgq" onClick={() => handleButtonClick('skip')}>
            Skip
            </button>
         
            
            
          </div> */}
                          {activeStep !== 1 ? (
                            <button
                              className="left-arrow"
                              fdprocessedid="ytqjgq"
                              onClick={() => handleButtonClick("prev")}
                            ></button>
                          ) : null}
                          {activeStep !== 5 ? (
                            <button
                              className="right-arrow"
                              fdprocessedid="ytqjgq"
                              onClick={() => handleButtonClick("skip")}
                            ></button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="textual-display-none mt-3">
                    <p className="  mb-2">
                      Your service order has been submitted and is subject to
                      approval. If approved you will receive an additional
                      approved order confirmation with install date (if
                      applicable) You may also receive a phone call or text from
                      Comlink or the service provider with additional
                      information. If you have questions please call us at 866
                      261-2148 M-F 9am to 8pm EST.
                    </p>
                    <p className="  mb-2">
                      Device disclosure (Smart phone or Tablet) your new
                      service you just ordered must remain active for 90 days or
                      you will be subject to a equipment return fee of $50)
                    </p>
                    <p className="  mb-2">
                      devices (smart phone or tablets) for internet service
                      orders will be shipped after Internet service installation
                      has been confirmed. This may take 7 to 21 days after
                      installation.
                    </p>
                    <p className="  mb-2">
                      Visa Gift Cards are provided by the Carrier and may take
                      up to 90 to receive after installation.
                    </p>
                  </div>
                  {/* <div className="custom-chat-box mt-3">
                  <ul className="chat-messages">
                      <li className="first-chat">this is first chat message</li>
                      <li className="second-chat">this is second chat message</li>
                      <li className="first-chat">this is first chat message</li>
                      <li className="second-chat">this is second chat message</li>
                  </ul>
                  <div className="chat-box">
                      <input type="text" className="form-control"  placeholder="Please write"/>
                      <button className="btn btn-success"> <svg height="800px" width="800px" viewBox="0 0 495.003 495.003" xmlns="http://www.w3.org/2000/svg">
                      <g id="XMLID_51_">
                        <path id="XMLID_53_" d="M164.711,456.687c0,2.966,1.647,5.686,4.266,7.072c2.617,1.385,5.799,1.207,8.245-0.468l55.09-37.616 l-67.6-32.22V456.687z" />
                        <path id="XMLID_52_" d="M492.431,32.443c-1.513-1.395-3.466-2.125-5.44-2.125c-1.19,0-2.377,0.264-3.5,0.816L7.905,264.422 c-4.861,2.389-7.937,7.353-7.904,12.783c0.033,5.423,3.161,10.353,8.057,12.689l125.342,59.724l250.62-205.99L164.455,364.414 l156.145,74.4c1.918,0.919,4.012,1.376,6.084,1.376c1.768,0,3.519-0.322,5.186-0.977c3.637-1.438,6.527-4.318,7.97-7.956 L494.436,41.257C495.66,38.188,494.862,34.679,492.431,32.443z" />
                      </g>
                    </svg></button>
                  </div>
                 </div> */}
                </div>
              </div>
              <div className="col-lg-5  inserted-second-view">
                <div className="inserted-display-block">
                  <h6 className="theme-clr font-medium mb-2">
                    Order Submitted For Approval!
                  </h6>
                  <p className=" mb-2">
                    For quicker approval and installation please call the number
                    below. Once your order is approved you will receive an
                    approved order confirmation via email and SMS (data rates
                    may apply){" "}
                  </p>
                  <p className=" mb-2">
                    Call now to speak with an agent directly!
                  </p>

                  <div className="row align-items-center">
                    <div className="col-lg-5 ">
                      <h5 className="contact-text">
                        <span className="theme-clr font-normal">Call:</span>{" "}
                        <a
                          style={{ textDecoration: "none" }}
                          href="tel:+18662612148"
                          className="green-clr font-medium "
                        >
                          866-261-2148
                        </a>
                      </h5>
                      <p className="grey-text  mb-2">Mon-Fri 9am to 12am EST</p>
                    </div>
                    <div className="col-lg-7 ">
                    <div className="grey-bg-card mb-3 mt-2 installation-mobile-date">
                  <div className="grey-bg-card-content top-green-border">
                    <p className="font-bold mb-2">Installation Date</p>
                    <input
                      type="date"
                      className="form-control form-custom-date mb-3"
                      value={installationDate}
                      onChange={(e) => setInstallationDate(e.target.value)}
                    />
                    <div className="row">
                      <p className="font-bold mb-2">Preferred Install Time</p>
                      <div className="form-check col-xxl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexmobile"
                          id="flexmobile1"
                          value="Morning 8am to 12pm"
                          disabled={submitButton}
                          checked={
                            preferredInstallTime === "Morning 8am to 12pm"
                          }
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexmobile1"
                        >
                          Morning 8am to 12pm
                        </label>
                      </div>
                      <div className="form-check col-xxl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexmobile"
                          id="flexmobile2"
                          value="Afternoon 1pm to 5pm"
                          disabled={submitButton}
                          checked={
                            preferredInstallTime === "Afternoon 1pm to 5pm"
                          }
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexmobile2"
                        >
                          Afternoon 1pm to 5pm
                        </label>
                      </div>
                      <div className="form-check col-xxl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexmobile"
                          id="flexmobile3"
                          value="Evening After 5pm"
                          disabled={submitButton}
                          checked={preferredInstallTime === "Evening After 5pm"}
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexmobile3"
                        >
                          Evening After 5pm
                        </label>
                      </div>
                    </div>
                  </div>
                  {submitButton ? null : (
                    <div className="text-end mt-3">
                      <button
                        className="btn green-filled-btn "
                        onClick={() => handleInstallDate()}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
                    </div>
                  </div>
                </div>
                <div className="textual-display-block">
                  <p className="  mb-2">
                    Your service order has been submitted and is subject to
                    approval. If approved you will receive an additional
                    approved order confirmation with install date (if
                    applicable) You may also receive a phone call or text from
                    Comlink or the service provider with additional information.
                    If you have questions please call us at 866 261-2148 M-F 9am
                    to 8pm EST.
                  </p>
                  <p className="  mb-2">
                    Device disclosure (Smart phone or Tablet) your new
                    service you just ordered must remain active for 90 days or
                    you will be subject to a equipment return fee of $50
                  </p>
                  <p className="  mb-2">
                    devices (smart phone or tablets) for internet service
                    orders will be shipped after Internet service installation
                    has been confirmed. This may take 7 to 21 days after
                    installation.
                  </p>
                  <p className="  mb-2">
                    Visa Gift Cards are provided by the Carrier and may take up
                    to 90 to receive after installation.
                  </p>
                </div>
                <div className="last-step-right-content shoping-cart-detail mb-xxl-3 mb-2">
                  <div className="grey-bg-card">
                    <div className="grey-bg-card-content top-green-border">
                      <p className="font-bold  mb-2">Your Shopping Cart </p>
                      {leadDetail?.dealsId?.map((item, idx) =>
                        leadDetail?.dealSelectedItems.map(
                          (data, index) =>
                            data?._id == item?._id && (
                              <span>
                                <div
                                  key={idx}
                                  className="d-flex justify-content-between"
                                >
                                  <p className="grey-text left-side-text">
                                    {item?.name} +{" "}
                                    {leadDetail?.metaData?.preferredDevice}
                                  </p>
                                  <p className="grey-text font-bold text-end">
                                    {`$${Number(item?.planPrice)?.toFixed(
                                      2
                                    )}/mo`}
                                  </p>
                                </div>
                                {data?.acpDiscount &&
                                item?.acpDiscountPrice &&
                                item?.acpDiscountPrice !== "0" ? (
                                  <React.Fragment>
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex flex-column">
                                        <label className="mb-1">
                                          ACP Discount
                                        </label>
                                        <span className="small-text2">
                                          ACP APPROVAL REQUIRED
                                        </span>
                                      </div>
                                      <p className="grey-text text-end">
                                        {`-$${Number(
                                          item?.acpDiscountPrice
                                        ).toFixed(2)}/mo`}
                                      </p>
                                    </div>
                                    {item?.professionalInstallationFee &&
                                    item?.professionalInstallationFee !== "0" &&
                                    data?.installationOptions ==
                                      "Professional Installation" ? (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                          <label className="my-2 grey-text left-side-text">
                                            Installation Options Fee
                                          </label>
                                        </div>
                                        <p className="grey-text text-end">
                                          {`+$${Number(
                                            item?.professionalInstallationFee
                                          ).toFixed(2)}`}
                                        </p>
                                      </div>
                                    ) : null}
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                                <hr />
                              </span>
                            )
                        )
                      )}
                      <div className="d-flex justify-content-between">
                        <label>Sub Total</label>
                        <p className="grey-text font-bold text-end">
                          ${subTotal?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="last-step-right-content services-receipet-detail ">
                  <div className="grey-bg-card">
                    <div className="grey-bg-card-content top-green-border">
                      <p className="font-bold  mb-2">
                        Order Receipt for your services
                      </p>
                      <div className="d-flex justify-content-between  mb-2 align-items-center">
                        <p className="grey-text left-side-text">
                          Confirmation Number
                        </p>
                        <p className="grey-text font-bold right-end-text">
                          {leadDetail?.dealOrderConfirmationNumber}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between  mb-2 align-items-center">
                        <p className="grey-text left-side-text">
                          Customer Name
                        </p>
                        <p className="grey-text font-bold right-end-text">
                          {(leadDetail?.firstname ?? "") + " " + (leadDetail?.lastname ?? "")}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between  mb-2 align-items-center">
                        <p className="grey-text left-side-text">Order Date</p>
                        <p className="grey-text font-bold right-end-text">
                          {leadDetail?.createdAt &&
                            new Date(leadDetail.createdAt).toLocaleString()}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between  mb-2 align-items-center">
                        <p className="grey-text left-side-text">
                          Service Address
                        </p>
                        <p className="grey-text font-bold right-end-text">
                          {leadDetail?.metaData?.serviceAddress},{" "}
                          {leadDetail?.metaData?.serviceCity}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between  mb-2 align-items-center">
                        <p className="grey-text left-side-text">Phone Number</p>
                        <p className="grey-text font-bold right-end-text">
                          {leadDetail?.phone}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between  mb-2 font-18 align-items-center">
                        <p className="grey-text font-bold left-side-text">
                          Order Submitted
                        </p>
                        <p className="grey-text font-bold right-end-text">
                          Status:
                          <span className="yellow-text"> Pending approval</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="grey-bg-card mb-3 mt-2">
                  <div className="grey-bg-card-content top-green-border">
                    <p className="font-bold mb-2">Installation Date</p>
                    <input
                      type="date"
                      className="form-control form-custom-date mb-3"
                      value={installationDate}
                      onChange={(e) => setInstallationDate(e.target.value)}
                    />
                    <div className="row">
                      <p className="font-bold mb-2">Preferred Install Time</p>
                      <div className="form-check col-xl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="Morning 8am to 12pm"
                          disabled={submitButton}
                          checked={
                            preferredInstallTime === "Morning 8am to 12pm"
                          }
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Morning 8am to 12pm
                        </label>
                      </div>
                      <div className="form-check col-xl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="Afternoon 1pm to 5pm"
                          disabled={submitButton}
                          checked={
                            preferredInstallTime === "Afternoon 1pm to 5pm"
                          }
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Afternoon 1pm to 5pm
                        </label>
                      </div>
                      <div className="form-check col-xl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          value="Evening After 5pm"
                          disabled={submitButton}
                          checked={preferredInstallTime === "Evening After 5pm"}
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Evening After 5pm
                        </label>
                      </div>
                    </div>
                  </div>
                  {submitButton ? null : (
                    <div className="text-end mt-3">
                      <button
                        className="btn green-filled-btn "
                        onClick={() => handleInstallDate()}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div> */}
                 {/* <div className="grey-bg-card mb-3 mt-2">
                  <div className="grey-bg-card-content top-green-border">
                    <p className="font-bold mb-2">Installation Date</p>
                    <input
                      type="date"
                      className="form-control form-custom-date mb-3"
                      value={installationDate}
                      onChange={(e) => setInstallationDate(e.target.value)}
                    />
                    <div className="row">
                      <p className="font-bold mb-2">Preferred Install Time</p>
                      <div className="form-check col-xl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="Morning 8am to 12pm"
                          disabled={submitButton}
                          checked={
                            preferredInstallTime === "Morning 8am to 12pm"
                          }
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Morning 8am to 12pm
                        </label>
                      </div>
                      <div className="form-check col-xl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="Afternoon 1pm to 5pm"
                          disabled={submitButton}
                          checked={
                            preferredInstallTime === "Afternoon 1pm to 5pm"
                          }
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Afternoon 1pm to 5pm
                        </label>
                      </div>
                      <div className="form-check col-xl-4 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          value="Evening After 5pm"
                          disabled={submitButton}
                          checked={preferredInstallTime === "Evening After 5pm"}
                          onChange={(e) =>
                            setPreferredInstallTime(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Evening After 5pm
                        </label>
                      </div>
                    </div>
                  </div>
                  {submitButton ? null : (
                    <div className="text-end mt-3">
                      <button
                        className="btn green-filled-btn "
                        onClick={() => handleInstallDate()}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div> */}
                   <div className="grey-bg-card mt-3">
                          <div className="grey-bg-card-content">
                            <p className="grey-text ">
                              Don’t forget to have your confirmation and phone
                              number handy when you speak with the agent to
                              schedule your installation for service.
                            </p>
                          </div>
                        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastStep;
