import React, { useLayoutEffect, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const MyFinanceWidget = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.myfinance.com/embed/inlineMedia_core.js";
    script.async = true;
    script.onload = () => {
      window.initializeInline({ loadInlineCss: true });
    };
    document.head.appendChild(script);
  }, [props?.myBoolean]);

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{"Check Availability"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="myFinance-widget"
          data-ad-id="ebe461ff-6e71-4484-bf1c-03e43e225e01"
          data-campaign="chameleon-comlink-zip-detected-providers"
        ></div>
      </Modal.Body>
    </Modal>
  );
};

export default MyFinanceWidget;
