import React from "react";
import "./OptimumInternet.css";
import optlogo from "../../../assets/images/optimum-logo.png";
import callinggirl from "../../../assets/images/home-phone-girl.png";
import qualifyinternet from "../../../assets/images/qualify-for-internet.png";
import comlinkbundle from "../../../assets/images/comlink bundle.png";
import dummyarrow from "../../../assets/images/circle-arrow.png";
import { Link } from "react-router-dom";

const OptimumInternet = () => {
    return (
        <>
            <div className="optimum-internet-services-wraper ">
                <div className="optimum-navbar">
                    <div className="optimum-logo">
                        <img src={optlogo} alt="optimim-logo" />
                    </div>
                    <div className="call-to-claim">
                        <h6>CALL TO CLAIM THIS OFFER:</h6>
                        <a style={{ cursor: 'pointer', color: "black", textDecoration: 'none' }} href="tel:+18662609968"><h5>866-260-9968</h5></a>
                    </div>
                </div>

                {/* banner */}
                <div className="optimum-banner">
                    <h1>
                        Optimum Internet with services
                        <br /> up to 1 Gig <br /> and 99.9% reliability.
                    </h1>
                    <Link to="/optimum-offer">
                        <button>Apply Now</button>
                    </Link>
                </div>
                
                {/* internet packages */}
                <div className="internet-packages">
                    <div className="internet-packages-wraper">
                        <div className="packages-details">
                            <h4>300 Mbps Internet</h4>
                            <h5>
                                <sup className="dollar-sup">$</sup>40
                                <sup>
                                    /mo. <br /> 1yr.
                                </sup>
                            </h5>
                            <h6>plus taxes, fees & other charges</h6>
                            <div className="btn_wrap">
                                <Link to="/optimum-offer">
                                    <button>Apply Now</button>
                                </Link>
                            </div>
                        </div>
                        <div className="packages-details">
                            <h4>500 Mbps Internet</h4>
                            <h5>
                                <sup className="dollar-sup">$</sup>60
                                <sup>
                                    /mo. <br /> 1yr.
                                </sup>
                            </h5>
                            <h6>plus taxes, fees & other charges </h6>
                            <div className="btn_wrap">
                                <Link to="/optimum-offer">
                                    <button>Apply Now</button>
                                </Link>
                            </div>
                        </div>
                        <div className="packages-details">
                            <h4>1 Gig Internet</h4>
                            <h5>
                                <sup className="dollar-sup">$</sup>70
                                <sup>
                                    /mo. <br /> 1yr.
                                </sup>
                            </h5>
                            <h6>plus taxes, fees & other charges</h6>
                            <div className="btn_wrap">
                                <Link to="/optimum-offer">
                                    <button>Apply Now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <p className="packages-text">Not available in all areas. Advertised max speed for wired connection. Actual speeds, including over WiFi, may vary and are not gauranteed.</p>
                </div>

                <div className="no_annual_contrat">
                    <h5>
                        99.9% <br /> Reliable
                    </h5>
                    <h5>
                        Equipment <br /> included
                    </h5>
                    <h5>
                        30 Day Money <br /> Back Guarantee
                    </h5>
                    <h5>
                        Free Self <br /> Install
                    </h5>
                    <h5>
                        No Annual <br /> Contract
                    </h5>
                </div>

                {/* add home phone */}
                <div className="add-home-phone-wraper">
                    <div className="home-phone-part">
                        <div>
                            <img src={callinggirl} alt="calling-girl" className="img-fluid" />
                        </div>
                        <div className="home-phone-ul-wraper">
                            <div className="home-phone-details">
                                <div className="reliable-services">
                                    <h3>
                                        Add Home <br />
                                        Phone
                                    </h3>
                                    <h4>
                                        Reliable Home <br /> Phone Services With <br /> Enhanced
                                        Features.
                                    </h4>
                                </div>
                                <div className="packages-details">
                                    <h5>
                                        <sup className="dollar-sup">$</sup>20
                                        <sup>
                                            /mo. <br /> 1yr.
                                        </sup>
                                    </h5>
                                    <h6>plus taxes, fees & other charges</h6>
                                </div>
                            </div>
                            <ul>
                                <li>Unlimited Local And Long Distance Calling Anytime</li>
                                <li>20+ Calling Features</li>
                                <li>Battery Backup To Protect Against Power Outages</li>
                            </ul>

                            <div className="packages-details">
                                <div className="btn_wrap">
                                    <Link to="/optimum-offer">
                                        <button>Apply Now</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="add-home-phone-wraper affortable-connectivity">
                    <div className="qualify-for-internet-wrap">
                        <div className="See-if-you-qualify">
                            <h4>
                                See If You Qualify <br /> For Free Internet
                            </h4>
                            <h5>with the</h5>
                            <h6>
                                Affordable <br /> Connectivity Program
                            </h6>
                            <div className="packages-details">
                                <div className="btn_wrap">
                                    <Link to="/optimum-offer">
                                        <button>Apply Now</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img
                                src={qualifyinternet}
                                alt="qualifyinternet"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>

                {/* comlink total solution */}
                <div className="comlink-total-solutions">
                    <div className="total-solutions-wraper">
                        <div>
                            <h6>COMLINK TOTAL SOLUTIONS</h6>
                            <h5>
                                2891 Center Pointe <br /> DR Fort Myers FL <br /> 33916
                            </h5>
                        </div>
                        <div>
                            <img
                                src={comlinkbundle}
                                alt="comlink bundle"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="solution-details">
                        <p>
                        *Customer must be in good standings for 90 days, gift card processing takes about 4-6 weeks. Customer should receive card around day 120. Offer starts 10/03/23
                        </p>
                        <p>
                            Visa® Reward Card: Offer is not available to individuals who have previously participated in an Optimum/Suddenlink Visa® Reward Card promotion within the past 12 months. Visa Reward Card will be mailed to customers who maintain promo-
                            tion and remain in good standing with no past due or returned payments throughout first 90 days after account activation. Allow 4-6 weeks for delivery. Limit 1 per customer. Visa Reward Card cannot be used to pay Optimum monthly bill. Card
                            value expires in 12 mos. Visa Reward Card may be used when making purchases from merchants in the U.S. and District of Columbia everywhere Visa debit cards are accepted. No ATM access. Terms and Conditions apply to Reward Cards. See
                            Cardholder Agreement for details. Visa Reward Card is issued by MetaBank®, N.A., Member FDIC pursuant to a license from Visa U.S.A. Inc. This optional offer is not a MetaBank product or service nor does MetaBank endorse this offer. Card is
                            distributed and serviced by InComm Financial Services, Inc., which is licensed as a Money Transmitter by the New York State Department of Financial Service.
                        </p>
                        <p>© 2023 Csc Holdings , LLC All Right Reserved</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OptimumInternet;
