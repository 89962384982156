import React, { useEffect, useState, useRef } from "react";
import { Images } from "../../../../assets/assets";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import LeadsTermsModal from "./LeadsTermsModal";
import { useNavigate, useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY_FOR_PREPAID_PLAN;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};

const initialValue = {
  firstname: "",
  lastname: "",
  prefix: "",
  phone: "",
  zipcode: "",
  bestWayToReach: "",
  serviceAddress: "",
  serviceAddress2: "",
  serviceCity: "",
  serviceState: "",
  agreement: false,
};

const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};
const USStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  prefix: Yup.string().required("Prefix is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Phone number must be exactly 10 digits")
    .length(14, "Phone number must be exactly 10 digits"),
  zipcode: Yup.string()
    .required("Zipcode is required")
    .matches(/^[^_]*$/, "Zipcode must be numbers only")
    .length(5, "Zipcode must be exactly 5 digits"),
  serviceAddress: Yup.string().required("Service address is required"),
  serviceAddress2: Yup.string(),
  serviceCity: Yup.string().required("Service city is required"),
  serviceState: Yup.string().required("Service state is required"),
  agreement: Yup.boolean()
    .oneOf([true], "You must agree to the agreement")
    .required("Agreement is required"),
});

const apiUrl = `${API_URL}/leads/create`;

const LeadDetailCapture = (props) => {
  const { slug } = useParams();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [services, setServices] = useState([]);
  const [sameAsService, setSameAsService] = useState(false);
  const [sameAsServiceDisabled, setSameAsServiceDisabled] = useState(false);
  const formRef = useRef();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  // useEffect(() => {
  //   if (props.data.zipcode) {
  //     formik.setFieldValue("serviceZipcode", props.data.zipcode);
  //     formik.setFieldValue("phone", props.data.phone);
  //     formik.setFieldValue("firstname", props.data.firstname);
  //     formik.setFieldValue("lastname", props.data.lastname);
  //     formik.setFieldValue("prefix", props.data.prefix);
  //     formik.setFieldValue("serviceAddress", props.data.serviceAddress);

  //     fetch(`https://api.zippopotam.us/us/${props.data.zipcode}`)
  //       .then((response) => {
  //         // Check if the response is successful
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         // Parse the JSON data
  //         return response.json();
  //       })
  //       .then((data) => {
  //         // Handle the data
  //         formik.setFieldValue("serviceCity", data.places[0]?.["place name"]);
  //         formik.setFieldValue(
  //           "serviceState",
  //           data.places[0]?.["state abbreviation"]
  //         );
  //         formik.setFieldValue("shippingZipcode", props.data.zipcode);
  //         formik.setFieldValue("shippingCity", data.places[0]?.["place name"]);
  //         formik.setFieldValue(
  //           "shippingState",
  //           data.places[0]?.["state abbreviation"]
  //         );
  //         setDisabled(true);
  //       })
  //       .catch((error) => {
  //         // Handle errors
  //         console.error(
  //           "There was a problem with your fetch operation:",
  //           error
  //         );
  //       });
  //   }
  // }, [props.data.zipcode]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader2(true);
      setLoader(true);
      toast.dismiss();

      values.shippingZipcode = values.zipcode;

      props.setData({
        phone: values?.phone,
        email: values?.email,
        zipcode: values?.zipcode,
        firstname: values?.firstname,
        lastname: values?.lastname,
        prefix: values?.prefix,
        serviceAddress: values?.serviceAddress,
        serviceAddress2: values?.serviceAddress2,
        serviceCity: values?.serviceCity,
        serviceState: values?.serviceState,
        serviceZipcode: values?.zipcode,
      });

      const queryString = window.location.search.slice(1);
      var url = window?.location?.href;

      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({
          ...values,
          source: "Prepaid Plan Form",
          campaignSource: queryString ? queryString : "",
          partnerName: "",
          url: url ? url : "",
          status: 9,
          step: 1,
          stepStatus: "Incompleted",
          slug: slug ? slug : "",
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (!response?.ok) {
            throw new Error(
              response?.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : response?.status == 409
                ? 409
                : "Something went wrong"
            );
          }
          return response?.json(); // Parse the response body as JSON
        })
        .then((data) => {
          props?.setLeadId(data?.leadId);
          props.nextStep();
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoader(false);
          setLoader2(false);
        });
    },
  });

  const handlePrefferdevice = (value) => {
    formik.setFieldValue("preferredDevice", value);
    props.setData({
      ...props.data,
      preferredDevice: value,
    });
  };

  const handleWayToContact = (value) => {
    formik.setFieldValue("bestWayToReach", value);
    props.setData({
      ...props.data,
      bestWayToReach: value,
    });
  };

  const handleService = (value) => {
    const index = services.indexOf(value);

    if (index === -1) {
      setServices([...services, value]);
    } else {
      const updatedServices = services?.filter((item) => item !== value);
      setServices(updatedServices);
    }
  };

  const handleSameAsService = () => {
    // Update sameAsService state and use the updated value
    setSameAsService((prevSameAsService) => {
      const updatedSameAsService = !prevSameAsService;

      // Update other state or execute logic based on the updated value
      if (updatedSameAsService) {
        setSameAsServiceDisabled(true);
        formik.setFieldValue("shippingAddress", formik.values?.serviceAddress);
        formik.setFieldValue(
          "shippingAddress2",
          formik.values?.serviceAddress2
        );
        formik.setFieldValue("shippingZipcode", formik.values?.serviceZipcode);
        formik.setFieldValue("shippingCity", formik.values?.serviceCity);
        formik.setFieldValue("shippingState", formik.values?.serviceState);
      } else {
        setSameAsServiceDisabled(false);
      }

      // Return the updated value
      return updatedSameAsService;
    });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="application-step-form-wraper">
          <div className="custom-container">
            <form onSubmit={formik.handleSubmit} ref={formRef}>
              <div className="lead-step-form-card">
                <div className="lead-step-form-wraper">
                  <div className="row mb-lg-5 mb-3">
                    <div className="col-lg-12">
                      <h6 className="theme-clr pb-xxl-4 pb-md-3 pb-2">
                        Please Provide Your Customer Information
                      </h6>
                      <div className="row">
                        <div className=" col-lg-3 col-md-3  mb-lg-3 mb-2">
                          <label>
                            Prefix <span>*</span>
                          </label>
                          <select
                            {...formik.getFieldProps("prefix")}
                            className={`form-control mt-0 appear_none ${
                              formik.touched.prefix && formik.errors.prefix
                                ? "is-invalid"
                                : ""
                            }`}
                            id="prefix"
                            name="prefix"
                          >
                            <option value="" hidden>
                              Title
                            </option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Other">Other</option>
                          </select>
                          {formik.touched.prefix && formik.errors.prefix && (
                            <div className="for-red">
                              {formik.errors.prefix}
                            </div>
                          )}
                        </div>

                        <div className="col-lg-3 col-md-3   mb-lg-3 mb-2">
                          <div className="">
                            <label>
                              First Name <span>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.firstname &&
                                formik.errors.firstname
                                  ? "is-invalid"
                                  : ""
                              }`}
                              {...formik.getFieldProps("firstname")}
                              id="fname"
                              placeholder="First Name"
                              name="firstname"
                            />
                            {formik.touched.firstname &&
                              formik.errors.firstname && (
                                <div className="for-red">
                                  {formik.errors.firstname}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3    mb-lg-3 mb-2">
                          <div className=" ">
                            <label>
                              Last Name <span>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.lastname &&
                                formik.errors.lastname
                                  ? "is-invalid"
                                  : ""
                              }`}
                              {...formik.getFieldProps("lastname")}
                              id="l-name"
                              placeholder="Last Name"
                              name="lastname"
                              required=""
                            />
                            {formik.touched.lastname &&
                              formik.errors.lastname && (
                                <div className="for-red">
                                  {formik.errors.lastname}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className=" col-lg-3 col-md-3  mb-lg-3 mb-2">
                          <label>
                            Email <span>*</span>
                          </label>
                          <input
                            type="email"
                            placeholder=" Your email"
                            {...formik.getFieldProps("email")}
                            x
                            className={`form-control ${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="for-red">{formik.errors.email}</div>
                          )}
                        </div>
                        <div className=" col-lg-3 col-md-3 mb-lg-3 mb-2">
                          <label>
                            Phone Number <span>*</span>
                          </label>
                          <ReactInputMask
                            className={`form-control ${
                              formik.touched.phone && formik.errors.phone
                                ? "is-invalid"
                                : ""
                            }`}
                            type="dob"
                            mask="(999) 999-9999"
                            {...formik.getFieldProps("phone")}
                            placeholder="Phone number"
                          />
                          {formik.touched.phone && formik.errors.phone && (
                            <div className="for-red">{formik.errors.phone}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <h6 className="theme-clr pb-md-3 pb-2">
                        Service Address
                      </h6>
                      <div className="col-lg-3 col-md-6 mb-lg-4 mb-3">
                        <div className="">
                          <input
                            type="text"
                            {...formik.getFieldProps("serviceAddress")}
                            className={`form-control service-address ${
                              formik.touched.serviceAddress &&
                              formik.errors.serviceAddress
                                ? "is-invalid"
                                : ""
                            }`}
                            id="serviceAddress"
                            placeholder="Enter Address"
                            name="serviceAddress"
                          />
                          {formik.touched.serviceAddress &&
                            formik.errors.serviceAddress && (
                              <div className="for-red">
                                {formik.errors.serviceAddress}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 mb-lg-4 mb-3">
                        <div className="">
                          <input
                            type="text"
                            {...formik.getFieldProps("serviceAddress2")}
                            className={`form-control service-address ${
                              formik.touched.serviceAddress2 &&
                              formik.errors.serviceAddress2
                                ? "is-invalid"
                                : ""
                            }`}
                            id="serviceAddress2"
                            placeholder="Apt, Unit, etc"
                            name="serviceAddress2"
                          />
                          {formik.touched.serviceAddress2 &&
                            formik.errors.serviceAddress2 && (
                              <div className="for-red">
                                {formik.errors.serviceAddress2}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6 mb-lg-4 mb-3">
                        <div className="">
                          <input
                            type="text"
                            {...formik.getFieldProps("serviceCity")}
                            className={`form-control service-address ${
                              formik.touched.serviceCity &&
                              formik.errors.serviceCity
                                ? "is-invalid"
                                : ""
                            }`}
                            id="serviceCity"
                            placeholder="City"
                            name="serviceCity"
                          />
                          {formik.touched.serviceCity &&
                            formik.errors.serviceCity && (
                              <div className="for-red">
                                {formik.errors.serviceCity}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6 mb-lg-4 mb-3">
                        <div className="">
                          <select
                            id="serviceState"
                            placeholder="State"
                            {...formik.getFieldProps("serviceState")}
                            className="form-control mt-0 appear_none"
                          >
                            <option value="" selected hidden label="State" />
                            {USStates.map((state, index) => (
                              <option key={index} value={state} label={state} />
                            ))}
                          </select>
                          {formik.touched.serviceState &&
                            formik.errors.serviceState && (
                              <div className="for-red">
                                {formik.errors.serviceState}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className=" col-lg-2 col-md-6  mb-lg-4 mb-3">
                        <ReactInputMask
                          type="zipcode"
                          placeholder="Please enter zipcode"
                          mask="99999"
                          {...formik.getFieldProps("zipcode")}
                          className={`form-control ${
                            formik.touched.zipcode && formik.errors.zipcode
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.touched.zipcode && formik.errors.zipcode && (
                          <div className="for-red">{formik.errors.zipcode}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-lg-row flex-column justify-content-lg-between align-items-center mt-lg-5 mt-md-3">
                    <div className="terms-checkbox">
                      <div className="d-flex">
                        <label className="custom-control overflow-checkbox relative">
                          <input
                            type="checkbox"
                            name="agreement"
                            id="agreement"
                            className="overflow-control-input"
                            onChange={formik.handleChange}
                            checked={formik.values.agreement}
                          />
                          <span className="overflow-control-indicator"></span>
                          <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2"></span>
                        </label>
                        <p className="Submitting_form cri-pointer green-clr">
                          <LeadsTermsModal />
                        </p>
                      </div>
                      {formik.touched.agreement && formik.errors.agreement && (
                        <div className="for-red">{formik.errors.agreement}</div>
                      )}
                    </div>
                    <div className="d-flex mt-lg-0 mt-3 justify-content-end align-items-center">
                      {loader2 ? (
                        <button disabled className="btn theme-btn d-flex ">
                          <span className="ml_9"> Loading...</span>
                          <div className="spinner"></div>
                        </button>
                      ) : (
                        <button type="submit" className="btn green-filled-btn">
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadDetailCapture;
