import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import comlinkDisability from "../../../../../src/assets/images/Comlink-disablity.JPG";
import comlinkMobileimg from "../../../../../src/assets/images/comlink-mobile-line.png";
import comlinkTvimg from "../../../../../src/assets/images/comlink-tv-trial.png";
import comlinkbundleimg from "../../../../../src/assets/images/acp-logo.png";
import netspend from "../../../../../src/assets/images/netspend-img.png";

function CongratulationModal(props) {
    const [activeStep, setActiveStep] = useState(1); // Initialize activeStep state
    const [stepLinks, setStepLinks] = useState([
      "https://www.nblv4trk.com/2FMZLP/M2GJW/?sub1=&sub2=&sub3=", // Link for Step 1
      "https://comlinktv.com/", // Link for Step 2 (initialize empty, you can change as needed)
      "http://comlinkmobile.com/", // Link for Step 3
      "https://comlinkacp.com/",
      "https://comlinkacp.com/",
      "http://comlinkmobile.com/", // Link for Step 4
    ]);
  
    const handleStepClick = (step, e) => {
      e.preventDefault();
      setActiveStep(step);
    };
    const handleButtonClick = (btnType) => {
      if (btnType === "skip") {
        const nextStep = activeStep + 1;
        if (nextStep <= stepLinks.length && stepLinks[nextStep - 1] !== "") {
          setActiveStep(nextStep);
        }
      } else if (btnType === "prev") {
        const prevStep = activeStep - 1;
        if (prevStep > 0) {
          setActiveStep(prevStep);
        }
      } else {
        if (stepLinks[activeStep - 1] !== "") {
          window.open(stepLinks[activeStep - 1], "_blank");
        }
      }
    }
      const stepImages = [
        comlinkDisability,
        comlinkTvimg,
        comlinkMobileimg,
        comlinkMobileimg,
        comlinkTvimg,
      ];
  return (
          <Modal
        show={props.showModal}
        onHide={props.handleCloseModal}
        className="congratulation-modal last_step_congratulation_modal "
      >
        <Modal.Header closeButton>
          <div className="congratulation-modal-header">
            <h2>Wait, Check Out Our Exclusive Offers!</h2>
            <div className="custom-out-of-box-header">
              <h6>Order Successfully Submitted For Approval 👍</h6>
              <h5>
                Call now to speak with an agent directly :
                <a href="#">
                  <span className="theme-color-green"> 866-261-2148</span>
                </a>
                <svg
                  className="theme-color-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2a1.001 1.001 0 011.02-.24c1.12.45 2.33.69 3.57.69.55 0 1 .45 1 1v3.5c0 .55-.45 1-1 1C10.29 22 2 13.71 2 3.5 2 2.95 2.45 2.5 3 2.5H6.5c.55 0 1 .45 1 1 0 1.24.24 2.45.69 3.57.13.37.04.79-.24 1.02l-2.2 2.2z" />
                </svg>
              </h5>
              <p className="grey-text mb-xl-3 mb-2">Mon-Fri 9am to 12am EST</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div id="smartwizard" className="sw-main sw-theme-dots">
            {/* <p>Please choose from the options below to view the incredible discounts on our other fantastic products for your home.</p> */}
            <ul className="nav nav-tabs step-anchor">
              {[1, 2, 3, 4, 5].map((step) => (
                <li
                  key={step}
                  className={`nav-item ${activeStep === step ? "active" : ""}`}
                >
                  <a
                    href={`#step-${step}`}
                    className="nav-link"
                    onClick={(e) => handleStepClick(step, e)}
                  >
                    OFFER {step}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* <div className="item_cong">
            <a target="_blank" href={stepLinks[activeStep - 1]}>
              <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
            </a>
        
          </div> */}
          {/* Conditional rendering based on activeStep */}
          {activeStep === 1 && (
            <>
              <div className="modal-box-custom p-3">
                <div className="item_cong">
                  <a target="_blank" href={stepLinks[activeStep - 1]}>
                    <img
                      src={stepImages[activeStep - 1]}
                      alt={`Image for Step ${activeStep}`}
                    />
                  </a>
                </div>
                <p className="learn-more ps-1">
                  Learn more{" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2L12 22"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 15L12 22L5 15"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </div>
            </>
          )}
          {activeStep === 2 && (
            <>
              {/* <div className="modal-box-custom">
                <div className="item_cong">
                <a target="_blank" href={stepLinks[activeStep - 1]}>
                <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
              </a>
                </div>
                <p className="learn-more">Learn more  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L12 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M19 15L12 22L5 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></p> */}
              {/* <div className="text-section">
                  <p>This is content for step 2.</p>
                </div> */}
              {/* </div> */}
              <div className="modal-box-custom second-offers-box p-3">
                <div className="text-section">
                  <div className="row" id="horoscope">
                    <h3 className="text-capitalize">
                      Access to your horoscopes has never been so effortless!
                    </h3>
                    <div className="col-lg-12">
                      <h6 className="text-blue pb-lg-2 pb-md-3 pb-2">
                        Receiving your predictions via texts messages is quick,
                        easy and free*!
                      </h6>
                      <div className="text-btn-wrap ">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onclick="saveChange('horoscope')"
                          fdprocessedid="p7cbah"
                        >
                          Yes
                        </button>

                        <button
                          type="button"
                          className="btn btn-primary "
                          onclick="saveChange('horoscope')"
                          fdprocessedid="9ldu66"
                        >
                          No
                        </button>
                      </div>

                      <span>
                        By entering my number and clicking the "Yes" button, I
                        verify that this is my mobile number and consent to
                        receive Daily Horoscope &amp; Everyday Horoscopes text
                        messages on my mobile phone. Message and data rates may
                        apply. 1 message/day. Reply HELP to 96059 for help,
                        reply STOP to 96059 to opt-out. To obtain help you may:
                        Email the service provider at:
                        customercare@daily-horoscope.us &amp;
                        contact@everydayhoroscopes.com Call the service provider
                        at: +1 (888)-880-9693
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep === 3 && (
            <div className="modal-box-custom second-offers-box p-3">
              <div className="text-section">
                {/* <div className="item_cong">
                    <a target="_blank" href={stepLinks[activeStep - 1]}>
                    <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
                  </a>
                    </div> */}
                <div className="netspend-visa-wrapper" id="netspend">
                  <h3>Netspend Visa Prepaid Card </h3>
                  <div className="netspend-main-content">
                    <div className="row ">
                      <div className="col-lg-3">
                        <img src={netspend} className="img-fluid" />
                      </div>
                      <div className="col-lg-9">
                        <p>
                          With Netspend Card, you get the tool you to need to
                          help control your money.Plus get paid faster than a
                          paper check with direct deposit.
                        </p>
                        <p>
                          Subject to card activation and ID veridication.Terms
                          and fees apply. Card issued by pathward N,A Member
                          FDIC.
                        </p>
                      </div>
                    </div>
                    <p className="mt-lg-4 mb-lg-3 mt-2 mb-2 f_21">
                      Send me a Netspend Visa prepaid Card
                    </p>
                    <div className="text-btn-wrap text-center">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onclick="saveChange('netspend')"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary "
                        onclick="saveChange('netspend')"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="text-section">
                <p>This is content for step 3.</p>
              </div> */}
            </div>
          )}
          {activeStep === 4 && (
            <div className="modal-box-custom">
              <div className="item_cong">
                <a target="_blank" href={stepLinks[activeStep - 1]}>
                  <img
                    src={stepImages[activeStep - 1]}
                    alt={`Image for Step ${activeStep}`}
                  />
                </a>
                <p className="learn-more">
                  Learn more{" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2L12 22"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 15L12 22L5 15"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </div>
              {/* <div className="text-section">
                <p>This is content for step 4.</p>
              </div> */}
            </div>
          )}
          {activeStep === 5 && (
            <div className="modal-box-custom">
              <div className="item_cong">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={stepLinks[activeStep - 1]}
                >
                  <img
                    src={stepImages[activeStep - 1]}
                    alt={`Image for Step ${activeStep}`}
                  />
                </a>
                <p className="learn-more">
                  Learn more{" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2L12 22"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 15L12 22L5 15"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </div>
            </div>
          )}
          {/* {activeStep === 6 && (
        <div className="modal-box-custom">
            <div className="item_cong">
                    <a target="_blank" href={stepLinks[activeStep - 1]}>
                    <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
                  </a>
                    </div> 
                    <p className="learn-more">Learn more  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L12 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M19 15L12 22L5 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></p>
        </div>
      )} */}

          {/* <div className="dual-btn-wrap mt-xxl-5 mt-3">
            <button className="btn green-filled-btn" onClick={handleButtonClick}>Click</button>
            <button className="btn green-filled-btn theme-blue-filled" fdprocessedid="ytqjgq" onClick={() => handleButtonClick('skip')}>
            Skip
            </button>
         
            
            
          </div> */}
          <button
            className="left-arrow"
            fdprocessedid="ytqjgq"
            onClick={() => handleButtonClick("prev")}
          ></button>
          <button
            className="right-arrow"
            fdprocessedid="ytqjgq"
            onClick={() => handleButtonClick("skip")}
          ></button>
        </Modal.Body>
      </Modal>
  )
}

export default CongratulationModal
