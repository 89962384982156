import React, { useEffect, useState, useRef  } from 'react'
import "./GetOrderSumary.css"
import Loader from '../../main/Common/Loader/Loader';
import { toast } from 'react-toastify'
import * as Yup from 'yup';

const API_URL = process.env.REACT_APP_API_URL
const IMAGE_URL = process.env.REACT_APP_API_URL_IMAGE;

const headers = {
    'Content-Type': 'application/json',
};

const validationSchema = Yup.object().shape({
    leadId: Yup.string().required('Lead ID is required').nullable(),
    answer: Yup.array().required('Please select answer').min(1, 'Please select answer'),
});

function GetOrderSumary(props) {
    
    const [loader, setLoader] = useState(true)
    const [loader2, setLoader2] = useState(false);
    const [leadData, setleadData] = useState(false)
    const [leadDealData, setleadDealData] = useState(false);

    // For recording
    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const [audioBLOB, setAudioBLOB] = useState('');
    const [alreadyDone, setAlreadyDone] = useState(false);
    const [orderSubmitionType, setOrderSubmitionType] = useState(null);
    const [orderSubmitionLink, setOrderSubmitionLink] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    
    useEffect(() => 
    {
        setLoader(false)
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds smooth scrolling animation
        });
        const urlParams = new URLSearchParams(window.location.search);
        let leadID = urlParams.get('leadID');
        getOrderDetails(leadID);
    }, [])

    useEffect(() => 
    {
        if(props.step == 4)
        {
            getOrderDetails(props?.leadId);
        }
    }, [props.step])

    const approve = async () => 
    {
        confirmOrder();
    };

    const getOrderDetails = async (leadID) => 
    {
        if(leadID)
        {
            const apiUrl = `${API_URL}/leads/get-lead-details`
            setLoader(true)

            const params = new URLSearchParams({
                leadID: leadID,
            }).toString();
        
            const apiUrlWithParams = `${apiUrl}?${params}`;
            
            const requestOptions = {
                method: 'GET',
                headers,
            };

            fetch(apiUrlWithParams, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.status == 429
                        ? "Too many requests from this IP, please try again after a day"
                        : "Something went wrong");
                }
                return response.json(); // Parse the response body as JSON
            })
            .then(data => {
                if(data.orderconfirm == true)
                {
                    setAlreadyDone(true);
                }
                setleadData(data);
                setOrderSubmitionType( data?.dealsId[0]?.after_submision );
                setOrderSubmitionLink( data?.dealsId[0]?.after_submision_link );
            })
            .catch(error => {
                // Handle any errors that occur during the request
                toast.error("" + error);
            }).finally(() => {
                setLoader(false)
            })
        }
    };

    const confirmOrder = async () => 
    {
        if(!audioBLOB)
        {
            toast.error("Record your order acceptance.");
            return false;
        }

        const apiUrl = `${API_URL}/leads/confirm-order`
        setLoader(true)

        const urlParams = new URLSearchParams(window.location.search);

        let NextStep = false;
        let leadID = urlParams.get('leadID');

        if(!leadID)
        {
            NextStep = true;
            leadID = props?.leadId;
        }
            
        const formData = new FormData();
        formData.append('leadID', leadID); // Append the audio Blob
        formData.append('audio', audioBLOB, 'recording.wav'); // Append the audio Blob

        const requestOptions = {
            method: 'POST',
            body: formData,
        };

        await fetch(apiUrl, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status == 429
                    ? "Too many requests from this IP, please try again after a day"
                    : "Something went wrong");
            }
            return response.json(); // Parse the response body as JSON
        })
        .then(data => {

            if(orderSubmitionType != null && orderSubmitionType == 'gtsl')
            {
                setLoader(true)
                window.location.href = orderSubmitionLink
            }
            else if(NextStep && (orderSubmitionType == 'gtsp' || orderSubmitionType == 'gtss' || orderSubmitionType == null) )
            {

                props.nextStep();    
            }
        
            setAlreadyDone(true);
            // toast.success("Done");
        })
        .catch(error => {
            // Handle any errors that occur during the request
            toast.error("" + error);
        }).finally(() => {
            setLoader(false)
        })
    };

    const startRecording = async () => {
        setAudioBLOB(false);
        setAudioURL(false);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = event => {
            audioChunks.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioURL(audioUrl);
            audioChunks.current = [];
            setAudioBLOB(audioBlob);
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
    };

    return (
        <>
            {loader ? <Loader /> :
                <div className='Qoute__content updated-qoute-content'>
                    <div className='custom-container'>
                        <div className='qoute__form'>
                            <div className='row'>
                                <div className='col-xl-12'>
                                    {
                                    alreadyDone
                                    ?
                                    <div>
                                        <h3>Your Response Submited Succesffully..!</h3>
                                    </div> 
                                    :
                                    <div className="last-step-right-content services-receipet-detail ">
                                        { leadData ?
                                            <div className="grey-bg-card">
                                                <div className="grey-bg-card-content top-green-border">
                                                    <h5>Order Details</h5>
                                                    <p className="font-bold  mb-2">Please review your order details and verify them using the button below.</p>
                                                    <div className="d-flex justify-content-between  mb-2 align-items-center generic-quote-divider">
                                                        <p className="grey-text left-side-text">Customer Name</p>
                                                        <p className="grey-text font-bold right-end-text">{ leadData?.firstname+' '+leadData?.lastname }</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between  mb-2 align-items-center generic-quote-divider">
                                                        <p className="grey-text left-side-text">Customer Email</p>
                                                        <p className="grey-text font-bold right-end-text">{ leadData?.email }</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between  mb-2 align-items-center generic-quote-divider">
                                                        <p className="grey-text left-side-text">Customer Phone</p>
                                                        <p className="grey-text font-bold right-end-text">{ leadData?.phone }</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between  mb-2 align-items-center generic-quote-divider">
                                                        <p className="grey-text left-side-text">Selected Carrier</p>
                                                        <p className="grey-text font-bold right-end-text">
                                                            <img
                                                                src={`${IMAGE_URL}/uploads/images/${leadData?.dealsId[0]?.dealProvider?.logo}`}
                                                                alt={leadData?.dealsId[0]?.dealProvider?.name}
                                                                className="img-fluid"
                                                            />
                                                            { leadData?.dealsId[0]?.dealProvider?.name }
                                                        </p>
                                                    </div>
                                                    <div className="d-flex justify-content-between  mb-2 align-items-center generic-quote-divider">
                                                        <p className="grey-text left-side-text">Selected Service</p>
                                                        <p className="grey-text font-bold right-end-text">
                                                            { leadData?.dealsId[0]?.type+' Service Order' }
                                                        </p>
                                                    </div>
                                                    <div className="d-flex justify-content-between  mb-2 align-items-center generic-quote-divider">
                                                        <p className="grey-text left-side-text">Reward</p>
                                                        <p className="grey-text font-bold right-end-text">{ leadData?.metaData.preferredDevice }</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between  mb-2 align-items-center generic-quote-divider">
                                                        <p className="grey-text left-side-text">Monthly Price</p>
                                                        <p className="grey-text font-bold right-end-text">${ leadData?.dealsId[0]?.planPrice }/mo</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between  mb-2 align-items-center generic-quote-divider">
                                                        <p className="grey-text left-side-text">Order Date</p>
                                                        <p className="grey-text font-bold right-end-text">{ leadData?.createdAt }</p>
                                                    </div>
                                                    { leadData ?
                                                    <div className='row'>
                                                        <div className='quote-btn-wrap mt-4 col-sm-8'>
                                                            <h6>Please record the following statement to complete the order, and then click the "Agree" button (this is required):</h6>
                                                            <p>
                                                                I, { leadData?.firstname+' '+leadData?.lastname }, agree to the terms and conditions of the { leadData?.dealsId[0]?.type } Service Order through { leadData?.dealsId[0]?.dealProvider?.name }.
                                                            </p>
                                                            <hr />
                                                            <button className={isRecording ? 'btn btn-danger' : 'btn quote-agree-btn'} onClick={isRecording ? stopRecording : startRecording}>
                                                                {isRecording ? 'Stop Recording' : 'Start Recording'}
                                                            </button>
                                                            {audioURL && (
                                                                <div>
                                                                    <hr />
                                                                    <audio src={audioURL} controls />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='quote-btn-wrap text-end mt-4 col-sm-4'>
                                                            <button className=' btn quote-agree-btn' onClick={approve}>Agree</button>
                                                        </div>
                                                    </div> : ''
                                                    }

                                                    <div>
                                                        <hr />
                                                        <div dangerouslySetInnerHTML={{ __html: leadData?.dealsId[0]?.dealProvider?.termsAndConditions }} />
                                                    </div>
                                                </div>
                                            </div> : ''
                                        }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default GetOrderSumary
