import React from "react";
import "./loader.css";
import loaderimg from "../../../../../src/assets/images/comlink-bundle.svg";
const Loader = () => {
  return (
    <div id="loader-wrapper">
      <div className="pulse">
        <img src={loaderimg} />
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Loader;
