import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../../Pages/CmsBanner/CmsBanner.css";
import { Images } from "../../../../assets/assets";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import OptInModal from "./OptInModal";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY_FOR_SPOC_LEADS; // For Comlink Bundle spoc leads
const ORIGIN = "https://comlinkbundle.com/offers";

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN,
  apikey: API_KEY,
};
const USStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const intialValue = {
  firstname: "",
  lastname: "",
  prefix: "",
  serviceAddress: "",
  phone: "",
  email: "",
  zipCode: "",
  serviceAddress2: "",
  serviceCity: "",
  serviceState: "",
  // shippingAddress: "",
  // shippingAddress2: "",
  // shippingZipcode: "",
  // shippingCity: "",
  // shippingState: "",
  // sameAsService: false,
  optIn: false,
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  // prefix: Yup.string().required("Prefix is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Enter 10 digits Number")
    .length(14, "Enter 10 digits Number"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .nullable(),

  serviceAddress: Yup.string().required("Service address is required"),
  serviceAddress2: Yup.string(),
  serviceCity: Yup.string().required("Service city is required"),
  serviceState: Yup.string().required("Service state is required"),
  zipCode: Yup.string()
    .required("Zipcode is required")
    .matches(/^[^_]*$/, "Enter 5 digits Zipcode")
    .length(5, "Zipcode must be exactly 5 digits"),

  // shippingAddress: Yup.string().required("Shipping address is required"),
  // shippingAddress2: Yup.string(),
  // shippingCity: Yup.string().required("Shipping city is required"),
  // shippingState: Yup.string().required("Shipping state is required"),
  // shippingZipcode: Yup.string()
  //   .required("Zipcode is required")
  //   .matches(/^[^_]*$/, "Zipcode must be numbers only")
  //   .length(5, "Zipcode must be exactly 5 digits"),

  sameAsService: Yup.boolean(),

  optIn: Yup.boolean()
    .oneOf([true], "You must agree to the Opt In")
    .required("Opt In is required"),
});

const apiUrl = `${API_URL}/leads/create`;

function Offers() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [sameAsService, setSameAsService] = useState(false);
  const [sameAsServiceDisabled, setSameAsServiceDisabled] = useState(false);

  const location = useLocation();

  let heading = 'SIGN UP FOR INTERNET';
  let paragraph = 'Discover unbeatable deals on Internet, TV, and phone services';
  let paragraph2 = '';

  if(location.pathname.toLowerCase() === '/att')
  {
      heading = 'SIGN UP FOR INTERNET';
      paragraph = 'AT&T Fiber delivers some of the fastest internet speeds available, with up to 5,000 Mbps and 99% network reliability, even during peak hours. Download 4K movies in seconds or quickly upload photos to the cloud with AT&T’s top fiber internet option.';
      paragraph2 = 'Stay connected on the go with AT&T’s nationwide network of free Wi-Fi hotspots.';
  }
  else if(location.pathname.toLowerCase() === '/frontier')
  {
      heading = 'SIGN UP FOR INTERNET';
      paragraph = `Frontier provides high-speed internet and home phone services to keep you connected. With our Fiber plans, you'll receive a state-of-the-art wireless router for superior coverage throughout your home.`;
      paragraph2 = `Plus, Frontier Secure® offers services like identity protection and password management to safeguard you and your family from cyber threats.`;
  }
  else if(location.pathname.toLowerCase() === '/wow')
  {
      heading = 'SIGN UP FOR INTERNET';
      paragraph = `WOW! ensures you're always connected, whether you're gaming with friends or video chatting with family on FaceTime.`;
      paragraph2 = `WOW! offers no-contract cable internet service across nine states in the eastern half of the U.S., staying true to its name—"Wide Open West."`;
  }
  else if(location.pathname.toLowerCase() === '/windstream')
  {
      heading = 'SIGN UP FOR INTERNET';
      paragraph = 'Kinetic High-Speed Internet by Windstream leverages a fiber-backbone network to deliver some of Windstream’s fastest and most cost-effective plans.';
      paragraph2 = `While Windstream's pricing can vary, its unlimited data and competitive speeds make it a great choice. If Windstream offers good rates and speeds in your area, consider their internet packages.`;
  }
  else if(location.pathname.toLowerCase() === '/attwireless')
  {
      heading = 'SIGN UP FOR INTERNET';
      paragraph = 'Discover the perfect wireless plan for you. With AT&T, you can mix and match our top unlimited wireless plans to fit your needs. All plans include the ability to create a hotspot whenever you need it.';
      paragraph2 = `Choose from the AT&T Unlimited Starter® SL plan with 5GB of hotspot data, the AT&T Unlimited Extra® EL plan with 30GB, or the AT&T Unlimited Premium® PL plan with 60GB. Find your ideal wireless service today.`;
  }

  
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
    setTimeout(() => {
      setLoader(false);
    }, 1000);
    // setTimeout(() => {
    //   localStorage.removeItem("pageCheck");
    // }, 15000);
  }, []);

  const formik = useFormik({
    initialValues: intialValue,
    validationSchema: validationSchema,
    validateOnChange: async () => {},
    onSubmit: async (values) => {
      setLoader2(true);

      if (sameAsService) {
        values.shippingAddress = values.serviceAddress;
        values.shippingAddress2 = values.serviceAddress2;
        values.shippingCity = values.serviceCity;
        values.shippingState = values.serviceState;
        values.shippingZipcode = values.zipCode;
      }
      setLoader(true);
      toast.dismiss();
      const queryString = window.location.search.slice(1);
      var url = window?.location?.href;
      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({
          ...values,
          source: "SPOC Leads",
          campaignSource: queryString ? queryString : "",
          partnerName: "",
          url: url ? url : "",
          status: 9,
          step: 1,
          stepStatus: "Incompleted",
          slug: slug ? slug : "",
        }),
      };
      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (!response?.ok) {
            throw new Error(
              response?.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : response?.status == 409
                ? 409
                : "You are already connected with comlink through this email"
            );
          }
          return response?.json(); // Parse the response body as JSON
        })
        .then((data) => {
          // toast.success("Thanks for contacting. Have a great day!");
          navigate("/fiber-deals");
          formik.resetForm();
        })
        .catch((error) => {
          if (error == "Error: 409") {
            toast.error(
              "You are already connected with comlink through this email"
            );
          }
          console.error("Error:", error);
        })
        .finally(() => {
          setLoader(false);
          setLoader2(false);
        });
    },
  });

  const handleSameAsService = () => {
    // Update sameAsService state and use the updated value
    setSameAsService((prevSameAsService) => {
      const updatedSameAsService = !prevSameAsService;

      // Update other state or execute logic based on the updated value
      if (updatedSameAsService) {
        setSameAsServiceDisabled(true);
        formik.setFieldValue("shippingAddress", formik.values?.serviceAddress);
        formik.setFieldValue(
          "shippingAddress2",
          formik.values?.serviceAddress2
        );
        formik.setFieldValue("shippingZipcode", formik.values?.zipCode);
        formik.setFieldValue("shippingCity", formik.values?.serviceCity);
        formik.setFieldValue("shippingState", formik.values?.serviceState);
      } else {
        setSameAsServiceDisabled(false);
      }

      // Return the updated value
      return updatedSameAsService;
    });
  };

  return (
    <React.Fragment>
      {loader ? (
        <Loader />
      ) : (
        <div className="application-step-form-wraper">
          <div className="custom-container">
            <div className="dealstep1-content-wraper deals-custom-wrap-screen">
              <div className="application-step1-top-content d-flex flex-column justify-content-center align-items-center">
                <h4 className="text-uppercase theme-clr mb-lg-2 mb-1">
                  {heading}
                </h4>
                <h6 className="theme-clr">
                  {paragraph}
                </h6>
                <h6 className="theme-clr">
                  {paragraph2}
                </h6>
              </div>
              <div className="application-step1-bottom-content">
                <div className="step1-left-content">
                  <div className="step1-img-wraper">
                    <img src={Images.step1img} alt="img" />
                  </div>
                  <div className="terms-text">
                    <a className="text-white d-none-mobile">
                      <span className="me-2 text-white">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </span>
                      Terms & conditions apply. Subject to carrier approval &
                      service ability.
                    </a>
                  </div>
                </div>
                <div className="step1-right-content">
                  <div className="form_content blue-form-box">
                    <h6 className="font-bold mb-lg-4 mb-3">
                      Let’s Get Started!
                    </h6>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row ">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-lg-6  col-6">
                              <div className="mb-lg-4 mb-3">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    formik.touched.firstname &&
                                    formik.errors.firstname
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  {...formik.getFieldProps("firstname")}
                                  id="fname"
                                  placeholder="First Name"
                                  name="firstname"
                                />
                                {formik.touched.firstname &&
                                  formik.errors.firstname && (
                                    <div className="for-red">
                                      {formik.errors.firstname}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="col-lg-6  col-6">
                              <div className=" ">
                                {/* <label>
                            Last Name <span>*</span>
                          </label> */}
                                <input
                                  type="text"
                                  className={`form-control ${
                                    formik.touched.lastname &&
                                    formik.errors.lastname
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  {...formik.getFieldProps("lastname")}
                                  id="l-name"
                                  placeholder="Last Name"
                                  name="lastname"
                                  required=""
                                />
                                {formik.touched.lastname &&
                                  formik.errors.lastname && (
                                    <div className="for-red">
                                      {formik.errors.lastname}
                                    </div>
                                  )}
                              </div>
                            </div>
                            {/* <div className="col-lg-5">
                        <div className="mb-lg-4 mb-3">
                          <label>Middle Name</label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.middleName &&
                              formik.errors.middleName
                                ? "is-invalid"
                                : ""
                            }`}
                            {...formik.getFieldProps("middleName")}
                            id="m-name"
                            placeholder="Middle Name"
                            name="middleName"
                          />
                          {formik.touched.middleName &&
                            formik.errors.middleName && (
                              <div className="for-red">
                                {formik.errors.middleName}
                              </div>
                            )}
                        </div>
                      </div> */}
                          </div>
                        </div>

                        {/* <div className="col-md-12">
                  <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="">
                   
                      <input
                        type="text"
                        {...formik.getFieldProps("serviceAddress")}
                        className={`form-control service-address ${
                          formik.touched.serviceAddress &&
                          formik.errors.serviceAddress
                            ? "is-invalid"
                            : ""
                        }`}
                        id="serviceAddress"
                        placeholder="Enter Address"
                        name="serviceAddress"
                      />
                      {formik.touched.serviceAddress &&
                        formik.errors.serviceAddress && (
                          <div className="for-red">
                            {formik.errors.serviceAddress}
                          </div>
                        )}
                    </div>
                  </div>
                

                  </div>
                  </div> */}
                      </div>
                      <div className="row">
                        <div className="form-group col-lg-6 col-6 mb-lg-4 mb-3">
                          <input
                            type="email"
                            placeholder=" Your email"
                            {...formik.getFieldProps("email")}
                            x
                            className={`form-control ${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="for-red">{formik.errors.email}</div>
                          )}
                        </div>
                        <div className="form-group col-lg-6 col-6 mb-lg-4 mb-3">
                          <ReactInputMask
                            className={`form-control ${
                              formik.touched.phone && formik.errors.phone
                                ? "is-invalid"
                                : ""
                            }`}
                            type="dob"
                            mask="(999) 999-9999"
                            {...formik.getFieldProps("phone")}
                            placeholder="Phone number"
                          />
                          {formik.touched.phone && formik.errors.phone && (
                            <div className="for-red">{formik.errors.phone}</div>
                          )}
                        </div>

                        <h6 className="pb-xxl-4 pb-md-3 pb-2">
                          Service Address
                        </h6>
                        <div className="col-lg-8 col-12 mb-lg-4 mb-3">
                          <div className="">
                            {/* <label>
                        Address 1<span>*</span>
                      </label> */}
                            <input
                              type="text"
                              {...formik.getFieldProps("serviceAddress")}
                              className={`form-control service-address ${
                                formik.touched.serviceAddress &&
                                formik.errors.serviceAddress
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="serviceAddress"
                              placeholder="Enter Address"
                              name="serviceAddress"
                            />
                            {formik.touched.serviceAddress &&
                              formik.errors.serviceAddress && (
                                <div className="for-red">
                                  {formik.errors.serviceAddress}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-4 col-6 mb-lg-4 mb-3">
                          <div className="">
                            {/* <label>
                        Address 1<span>*</span>
                      </label> */}
                            <input
                              type="text"
                              {...formik.getFieldProps("serviceAddress2")}
                              className={`form-control service-address ${
                                formik.touched.serviceAddress2 &&
                                formik.errors.serviceAddress2
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="serviceAddress2"
                              placeholder="Apt, Unit, etc"
                              name="serviceAddress2"
                            />
                            {formik.touched.serviceAddress2 &&
                              formik.errors.serviceAddress2 && (
                                <div className="for-red">
                                  {formik.errors.serviceAddress2}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-lg-4 col-6 mb-lg-4 mb-3">
                          <div className="">
                            {/* <label>
                        Address 1<span>*</span>
                      </label> */}
                            <input
                              type="text"
                              {...formik.getFieldProps("serviceCity")}
                              className={`form-control service-address ${
                                formik.touched.serviceCity &&
                                formik.errors.serviceCity
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="serviceCity"
                              placeholder="City"
                              name="serviceCity"
                            />
                            {formik.touched.serviceCity &&
                              formik.errors.serviceCity && (
                                <div className="for-red">
                                  {formik.errors.serviceCity}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-4  col-lg-4 col-6 mb-lg-4 mb-3">
                          <div className="">
                            {/* <input
                            type="text"
                            {...formik.getFieldProps("serviceState")}
                            className={`form-control service-address ${
                              formik.touched.serviceState &&
                              formik.errors.serviceState
                                ? "is-invalid"
                                : ""
                            }`}
                            id="serviceState"
                            placeholder="Enter state"
                            name="serviceState"
                          /> */}
                            <select
                              id="serviceState"
                              placeholder="State"
                              {...formik.getFieldProps("serviceState")}
                              className="form-control mt-0 appear_none"
                            >
                              <option value="" selected hidden label="State" />
                              {USStates.map((state, index) => (
                                <option
                                  key={index}
                                  value={state}
                                  label={state}
                                />
                              ))}
                            </select>
                            {formik.touched.serviceState &&
                              formik.errors.serviceState && (
                                <div className="for-red">
                                  {formik.errors.serviceState}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="form-group col-lg-4  col-6  mb-lg-4 mb-3">
                          <ReactInputMask
                            type="zipCode"
                            placeholder="Zipcode"
                            mask="99999"
                            {...formik.getFieldProps("zipCode")}
                            className={`form-control ${
                              formik.touched.zipCode && formik.errors.zipCode
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.touched.zipCode && formik.errors.zipCode && (
                            <div className="for-red">
                              {formik.errors.zipCode}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="terms_condition d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column align-items-start">
                          <label className="d-flex custom-control overflow-checkbox relative">
                            <input
                              name="optIn"
                              className="overflow-control-input"
                              type="checkbox"
                              onChange={formik.handleChange}
                              checked={formik.values.optIn}
                            />
                            <span className="overflow-control-indicator "></span>
                            <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2"></span>
                          </label>
                          <p className="Submitting_form cri-pointer mb-1">
                            <OptInModal />
                            <span className="red_staric">*</span>
                          </p>
                          {formik.touched.optIn && formik.errors.optIn && (
                            <div className="for-red ">
                              {formik.errors.optIn}
                            </div>
                          )}
                        </div>
                        <div className="d-flex justify-content-end">
                          {loader2 ? (
                            <button disabled className="btn theme-btn d-flex ">
                              <span> Loading...</span>
                              <div className="spinner"></div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn green-filled-btn"
                            >
                              Get Started
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Offers;
