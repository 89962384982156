import React, { useEffect, useState } from "react";

// __ __ Packages/Style/Media __ __ //
import StepWizard from "react-step-wizard";
import { toast } from "react-toastify";
import "./Deal.css";
import LastStep from "./LastStep";
import LeadDetailCapture from "./LeadDetailCapture";
import PrepaidPlans from "./PrepaidPlans";

// __ __ Components/Helpers __ __ //

const PrepaidPlanForm = () => {
  const [serviceZipcode, setServiceZipcode] = useState("");
  const [leadId, setLeadId] = useState("");
  const [flag, setFlag] = useState(false);
  const [step, setStep] = useState("1");
  const [data, setData] = useState({
    phone: "",
    email: "",
    zipcode: "",
  });

  return (
    <StepWizard>
      <LeadDetailCapture
        setServiceZipcode={setServiceZipcode}
        setData={setData}
        leadId={leadId}
        data={data}
        setLeadId={setLeadId}
      />
      <PrepaidPlans
        serviceZipcode={serviceZipcode}
        leadId={leadId}
        setFlag={setFlag}
        flag={flag}
        data={data}
        setStep={setStep}
      />
      <LastStep leadId={leadId} flag={flag} step={step} />
    </StepWizard>
  );
};

export default PrepaidPlanForm;
