import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import "../CmsBanner/CmsBanner.css"
import { Container, Row, Col } from "react-bootstrap";
// import ComlinkTv from "../../../assets/images/comlinktv.png"
import MyFinanceWidget from '../../main/Home/QuestionAnswer/MyFinanceWidget';
import Loader from '../../main/Common/Loader/Loader';
import SearchForm from '../../main/Common/SearchForm/SearchForm';

function PhonePg() {
	const section1Ref = useRef(null);
	const [loader, setLoader] = useState(true)

	const scrollToSection = (ref) => {
		if (ref && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			});
		}
	};
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://static.myfinance.com/embed/inlineMedia_core.js";
		script.async = true;
		script.onload = () => {
			window.initializeInline({ loadInlineCss: true });
		};
		document.head.appendChild(script);
		// setLoader(true)
		// return () => {
		//   // Clean up script when component unmounts
		//   document.head.removeChild(script);
		// };
	}, []);
	useEffect(() => { setTimeout(() => { setLoader(false) }, 1000) }, [])

	return (
		<>
			{loader ? <Loader /> :
				<div className='banner_content'>
					<div className='cms-pages-baner phone_banner'>
						<Container fluid className="custom-container">
							<div className='cms-heading-content text-white'>
								<h4>EXPAND YOUR</h4>
								<h2>POSSIBILITIES</h2>
								<p>Phone Services With Free Premium Features Unlimited Nationwide Calling Call Waiting & Call Forwarding</p>
								{/* <div onClick={() => scrollToSection(section1Ref)} className='cms-page-banner-button desktop-btn'>
									<button className='btn theme-btn main_search_btn'>Search Now</button>
								</div> */}
							</div>
						</Container>
					</div>
				</div>
			}
			{/* <div onClick={() => scrollToSection(section1Ref)} className='cms-page-banner-button mobile-btn'>
				<button className='btn theme-btn main_search_btn'>Search Now</button>
			</div> */}
			<Container fluid className="custom-container mb-5">
				<Row className='align-items-center justify-content-center'>
					<Col lg={7}>
						<SearchForm />
					</Col>
				</Row>

			</Container>
			{/* <div className='banner_content'>
				<div className='custom-container' ref={section1Ref}>
					<div
						className="myFinance-widget"
						data-ad-id="ebe461ff-6e71-4484-bf1c-03e43e225e01"
						data-campaign="chameleon-comlink-zip-detected-providers"
					></div>
				</div>
			</div> */}
		</>
	)
}

export default PhonePg
