import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Privacy.css";

const Privacy = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  return (
    <div className="privacy-policy">
      <div className="custom-container container">

        <h1 className="privacy-heading">Privacy Policy</h1>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Comlink-Bundle Privacy Notice</h4>
          <p className="privacy-text">
            This Privacy Notice (this “Notice”) explains how Comlink-Bundle L.L.C.
            and its affiliates collect, use and share information about
            Comlink-Bundle subscribers and others who visit Comlink-Bundle’s
            websites, view Comlink-Bundle’s advertisements, or interact with
            Comlink-Bundle products or services. In this Notice, the terms
            “Comlink-Bundle”, “we”, “us”, and “our” refer to Comlink-Bundle L.L.C.
            and its affiliates, which collectively own and/or operate the
            Comlink-Bundle Television branded products and services including both
            websites and apps. The term “you” refers to you as a subscriber to or
            user of our services. Your use of our services constitutes your
            acceptance of this Notice. This Notice does not apply to EchoStar
            Corporation or its subsidiaries or any other product or service
            provided by DISH Network that is covered by a separate privacy notice.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Quick Guide to Contents</h4>
          <p className="privacy-text">
            What Information Do We Collect About You?
            <br /> How Do We Use Information About You?
            <br /> What Information Do We Share with Others?
            <br /> How Is Your Information Used for Advertising Purposes?
            <br /> What Choices Do You Have?
            <br />
            Your California Consumer Privacy Act (“CCPA”) Rights Your Nevada
            Privacy Rights How Do We Protect Your Personal Information?
            <br /> How Long Do We Maintain Your Personal Information?
            <br /> Who Can You Contact with Any Questions or Concerns?
            <br /> What about Changes to This Notice?
            <br /> What Information Do We Collect About You?
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Account Information</h4>
          <p className="privacy-text">
            We collect and keep personal information you give to us when you sign
            up for an account, including: your name, service address, billing
            address, e-mail address, telephone number, bank account and/or credit
            card number, logins and passwords to our websites and apps, security
            questions and codes, and other account information. We may also
            collect and keep other information about your account with us, such as
            billing and payment history, customer service interactions, and
            equipment and programming options.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Usage Information</h4>
          <p className="privacy-text">
            When you use our services, our websites and apps automatically collect
            usage information. Usage information may include application usage
            data and logs, including device type and ID, IP address, and location
            information. This usage information may be in various formats
            (electronic, audio, and other), and may be collected through our
            website or through devices such as AirTV, third-party streaming
            devices, or applications provided through or used to access our
            services. Please see Third-Party Services and Websites below for more
            information.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Viewership</h4>
          <p className="privacy-text">
            When you or someone in your household watches a video program, whether
            using the website or an app, we may collect a record of your viewing
            activity. This applies to live television programming as well as video
            on demand, pay per view, and DVR services..
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Third-Party Information</h4>
          <p className="privacy-text">
            We also may receive information about you and your household from
            third parties such as programmers, business partners, consumer
            reporting agencies, and other data companies to enhance or supplement
            our records, products, or service offerings. This information may
            allow us to send you promotions that interest you or serve
            advertisements to you that match your interests and concerns.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            Third-Party Services and Websites
          </h4>
          <p className="privacy-text">
            When you use a third-party streaming device, you should review the
            privacy statements of the third party to understand what information
            they may collect and keep. We are not responsible for the practices of
            such third parties. You may also choose, when available, to connect
            your account with third party accounts, including with third-party
            social networks. If you share information about your usage of or
            activities on our services with such third-party accounts, then the
            collection, use, and disclosure of such information will be subject to
            that third party’s privacy policies, which may differ from our own. We
            are not responsible for the practices of such third parties.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Cookies and Other Technologies</h4>
          <p className="privacy-text">
            We and/or other companies we work with may use cookies, web beacons,
            tags, and similar technologies on websites, platforms, and in
            electronic communications to track users online and to collect certain
            information, including statistics about website and platform usage,
            broad demographic information, IP addresses, browser type, device
            type, Internet Service Provider, referring/exit pages, platform type,
            date/time stamp, number of clicks, and other similar information. You
            can access our services even when you’ve disabled certain cookies, but
            you may not be automatically recognized upon revisiting. Please visit
            the “What Choices Do You Have?” section for further information,
            including about Do Not Track and how to disable cookies.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Children’s Information</h4>
          <p className="privacy-text">
            As required by our Terms of Use, you must be over the age of 18 to
            purchase our services, and you must be over the age of 13 and have
            obtained permission from your parent or your other legal guardian to
            create an account or otherwise access or use our services. Protecting
            children’s privacy is important to us. We do not direct the services
            to, nor do we knowingly collect any personal information from,
            children under the age of sixteen. If we learn that a child under the
            age of sixteen has provided personal information through the services,
            we will use reasonable efforts to remove such information from our
            files.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            How Do We Use Information About You?
          </h4>
          <p className="privacy-text">
            We use information we’ve collected and received from and about you and
            your account (“Information About You”) primarily for business or
            commercial activities, which may include: providing, operating, and
            maintaining our services; billing and collections; confirming that you
            are receiving the level(s) of service requested and are properly
            billed; identifying when changes are made to your account or services;
            informing you of products, programming, or services that may be of
            interest to you; making programming recommendations; understanding
            your use of our services in order to consider improving them;
            obtaining user surveys, recommendations and reviews; marketing our
            services and those of select business partners; delivering addressable
            or customized advertising, offers, and other services to you within
            the services, via email, or through other web sites and platforms (for
            more information see Section 4 below); detecting fraud and
            unauthorized reception of our services; determining whether applicable
            policies and terms of service are being violated; performing
            statistical or qualitative research and analysis; maintaining our do
            not contact lists; complying with law; and other uses as necessary to
            provide our services. Personal information and usage information may
            be aggregated and/or de-identified for other business and marketing
            uses by us. This aggregated and/or de-identified data may be used to
            improve our products and services, to measure and analyze use of our
            products and services, to provide more relevant marketing and
            advertising, to measure advertising effectiveness, to conduct market
            research, to report ratings and viewer measurement, and for other
            analytical and reporting purposes. We may combine the various
            information we collect and receive from and about you, including
            account information, personal information, usage information, as well
            as information collected offline, across other computers or devices
            that you may use, and from third-party sources.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            What Information Do We Share with Others?
          </h4>
          <p className="privacy-text">
            Comlink-Bundle treats information about you (including personal
            information) contained in our business records as confidential. We may
            share information about you with select partners in order to provide
            you with the best products and services. Our agreements with business
            partners and service providers that collect information limit the
            types and use of information shared with these companies. When May
            Comlink-Bundle Share Information about You 1. With service providers
            in order to provide products and services to you We may share
            information about you to companies that work for or provide services
            to us (but only as much as is necessary for them to perform the
            service), such as our:
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">Billing processors;</h4>
          <p className="privacy-text">
            Subcontractors;
            <br />
            Software providers;
            <br />
            Marketing companies;
            <br />
            Outside auditors and professional advisors;
            <br />
            Potential business merger, acquisition, or sale partners;
            <br />
            Regulators, government agencies; and Credit bureaus and collection
            agencies.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            As necessary to protect Comlink-Bundle and its employees
          </h4>
          <p className="privacy-text">
            We may also share information about you (including personal
            information) to protect our customers, employees, or property; in
            emergency situations; and to enforce our rights under our terms of
            service and policies, in court or elsewhere.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">For “mailing list” purposes</h4>
          <p className="privacy-text">
            We may share your name and address with others, such as charities,
            marketing organizations, or other businesses for “mailing list”
            purposes. Any such disclosure for “mailing list” purposes would be
            limited to subscriber names and addresses, and the disclosure does not
            reveal, directly or indirectly, (i) the extent of any subscriber’s
            viewing or use of a television service or other service provided by
            us; or (ii) the nature of any transaction made by the subscriber over
            any facilities used by us.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            When Comlink-Bundle Must Disclose Personal Information
          </h4>
          <p className="privacy-text">
            We may be required by law to comply with a valid legal process, such
            as a subpoena, court order, or search warrant, or where there is a
            lawful request, to disclose information about you (including personal
            information) without your consent or notice. We may also be required
            to disclose personal information to a third party or governmental
            entity in response to a court order. If the court order is sought by a
            governmental entity, you should be afforded the opportunity to appear
            and contest in a court proceeding relevant to the court order any
            claims made in support of the court order.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            Sharing of Information that Is Not Personally Identifiable
          </h4>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            1. Aggregated and de-identified information
          </h4>
          <p className="privacy-text">
            We may share information collected or received from or about you in
            de-identified and/or aggregate formats. We may share this with
            companies that collect and compile consumer data, including those that
            create reports about what programs people watch.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            2. To companies and networks that provide programming
          </h4>
          <p className="privacy-text">
            We may share information with networks and other providers of
            programming about what packages our subscribers have purchased and
            what programs our subscribers watch. We may also share some
            demographic or usage information. This information is de-identified
            and/or aggregated, and Comlink-Bundle will not tell the programmer
            which the individuals or households watched the program.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            3. To advertisers and companies that provide advertising services
          </h4>
          <p className="privacy-text">
            We may also share information with our advertisers and our advertising
            service providers about when, where, and how (streaming device, mobile
            device, or computer) an advertisement was viewed. We may also tell
            them certain pieces of demographic information about your household.
            This information is de-identified, and Comlink-Bundle will not tell
            the advertiser which individuals or households watched the
            advertisement. Advertisers, advertising technology companies, and
            service providers that perform advertising-related services for us and
            our business partners may use cookies, web beacons, tags, and other
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            4. How Is Your Information Used for Advertising Purposes?
          </h4>
          <p className="privacy-text">
            Comlink-Bundle may use information about you for marketing or
            advertising purposes in the following ways:
            <br />
          </p>
          <p className="privacy-text">
            We may also share information with our advertisers and our advertising
            service providers about when, where, and how (streaming device, mobile
            device, or computer) an advertisement was viewed. We may also tell
            them certain pieces of demographic information about your household.
            This information is de-identified, and Comlink-Bundle will not tell
            the advertiser which individuals or households watched the
            advertisement. Advertisers, advertising technology companies, and
            service providers that perform advertising-related services for us and
            our business partners may use cookies, web beacons, tags, and other
            <br />
          </p>
          <p className="privacy-text">
            To provide you with advertising for Comlink-Bundle products and
            services. We may use information we have collected or received from or
            about you through use of the service or from third parties to provide
            you with marketing information, offers and advertisements for
            Comlink-Bundle products and services or to exclude you from receiving
            certain marketing information, offers or advertisements. Our
            activities with respect to such marketing information, offers and
            advertisements may occur on Comlink-Bundle websites and platforms or
            websites and platforms operated by third parties.
            <br />
          </p>
          <p className="privacy-text">
            To provide you with more relevant online advertising. We and our
            advertising partners may also use information obtained about you
            through your use of our website(s) or from third parties, or gathered
            through cookies and similar technologies, to deliver more relevant
            online advertising to you across other computers, devices, websites,
            and online services that you may use (“interest-based advertising”).
            Advertisers, advertising technology companies, and service providers
            that perform advertising-related services for us and our business
            partners may use cookies, web beacons, tags, and other technologies to
            collect information from or about you to tailor advertisements,
            measure advertising effectiveness, and enable other enhancements. You
            may be able to opt out of certain types of interest-based advertising
            as described below in the “What Choices Do You Have?” section.
            <br />
          </p>
          <p className="privacy-text">
            To provide others with more relevant advertising. We and our
            advertising partners may also use information collected from or about
            you by us or our advertising partners and information we obtain about
            you from third parties to deliver advertising that is targeted for
            individuals with similar interests. We may combine aggregated and/or
            de-identified information to create groups based on actual or assumed
            shared interests and/or factual characteristics. We also combine
            information about the programming that our customers watch on the
            Service with their common interests to help us figure out what
            advertising may be most relevant.
            <br />
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">5. What Choices Do You Have?</h4>
          <p className="privacy-text">
            A. Cookies and Do Not Track Certain parts of our services require
            cookies. You may adjust your device or Internet browser settings to
            limit certain tracking or to decline cookies, but by doing so, you may
            not be automatically recognized upon revisiting and may not be able to
            use certain features of the Service or take full advantage of all of
            our offerings. Please refer to your device’s settings or your Internet
            browser’s “Help” section for more information on how to delete and/or
            disable your device or browser from receiving cookies or controlling
            your tracking preferences. Comlink-Bundle might not recognize “Do Not
            Track” requests or headers from some or all Internet browsers. We may
            use cookies or other technologies to deliver more relevant advertising
            and to link data collected across other computers or devices that you
            may use. B. Interest-Based Advertising/Addressable Advertising Choices
            You may ask Comlink-Bundle to stop using cookies on
            Comlink-Bundle.info that help us deliver more relevant advertising to
            you. To make this choice, please visit https://comlinkbundle.com/.
          </p>
          <p className="privacy-text">
            To understand your other choices for receiving more relevant online
            advertising, please review the information below: To learn more about
            interest-based advertising, and to opt out of collection and use of
            information about your online activities for interest-based
            advertising by the Digital Advertising Alliance(DAA) participating
            companies, please visit http://www.aboutads.info/choices/. To opt out
            of targeted advertising through mobile applications, please visit
            https://youradchoices.com/appchoices. To opt out of collection and use
            of information about your online activities for interest-based
            advertising by Network Advertising Initiative (NAI) member companies,
            please visit http://www.networkadvertising.org/choices/.Even if you
            opt out, you will still receive advertising from Comlink-Bundle and
            third parties. You may also still receive TV advertising from
            Comlink-Bundle or third parties that is customized based on
            information we’ve collected or received from or about you or on
            information about your online activities from third parties that are
            not DAA or NAI participating companies.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            6.Your California Consumer Privacy Act (“CCPA”) Rights
          </h4>
          <p className="privacy-text">
            California residents may also take advantage of the following rights:
            You may request, up to two times each year, that we disclose to you
            the categories and specific pieces of personal information that we
            have collected about you, the categories of sources from which your
            personal information is collected, the business or commercial purpose
            for collecting your personal information, the categories of personal
            information that we disclosed for a business or commercial purpose,
            any categories of personal information that we sold about you, the
            categories of third parties with whom we have shared your personal
            information, and the business or commercial purpose for selling your
            personal information, if applicable. You may request that we delete
            any personal information that we have collected from or about you.
            Note that there are some reasons we will not be able to fully address
            your request, such as if we need to complete a transaction for you, to
            detect and protect against fraudulent and illegal activity, for an
            internal business purpose, to exercise our rights, or to comply with a
            legal obligation. To take advantage of any of these rights, please
            contact us at +1(866) 986-2391 or visit our Privacy Portal. We value
            your privacy and will not discriminate in response to your exercise of
            your privacy rights. We will respond to your access and deletion
            requests within 45 days of receipt of your request, after proper
            verification, unless we need additional time, in which case we will
            let you know. We will respond to your opt out of sale requests
            promptly. For purposes of compliance with the CCPA, in addition to the
            further details as described throughout this Privacy Policy, we make
            the following disclosures: We collect the following categories of
            personal information: Identifiers/Contact Information, Commercial
            information, Internet or other electronic network activity
            information, geolocation, visual and audio information, and inferences
            drawn from the above. We sell the following categories of personal
            information: Identifiers/Contact Information, Internet or other
            electronic network activity information, and inferences drawn from the
            above. We disclose the following categories of personal information
            for a business or commercial purpose: Identifiers/Contact Information,
            Commercial information, Internet or other electronic network activity
            information, geolocation, visual and audio information, and inferences
            drawn from the above.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">7. Your Nevada Privacy Rights</h4>
          <p className="privacy-text">
            Nevada law permits Nevada residents to make certain requests about how
            their personal information is shared with third parties or affiliated
            companies. To make such a request, please visit our Privacy Portal.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            8.How Do We Protect Your Personal Information?
          </h4>
          <p className="privacy-text">
            Comlink-Bundle takes information security seriously. We use
            commercially reasonable efforts to prevent unauthorized third-party
            access to information about you. However, we cannot guarantee that
            these practices will prevent every unauthorized attempt to access,
            use, or information about you.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            9. How Long Do We Maintain Your Personal Information?
          </h4>
          <p className="privacy-text">
            Comlink-Bundle maintains personal information about you in our regular
            business records while you are a subscriber to any of our products or
            services. Comlink-Bundle also maintains personal information about you
            for a period of time after you are no longer a subscriber to satisfy
            legal or tax requirements, for fraud prevention, or for business
            purposes
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            10. Who Can You Contact with Any Questions or Concerns?
          </h4>
          <p className="privacy-text">
            If you have any questions or concerns regarding this Notice, or our
            privacy practices, please contact us at support@gocomlink.com, or you
            may write us at Comlink-Bundle L.L.C., 9601 South Meridian Blvd.,
            Englewood, CO 80112, Attn: Office of the General Counsel –
            Comlink-Bundle Privacy Matters.
          </p>
        </div>
        <div className="privacy-section">
          <h4 className="privacy-sub-heading">
            11. What about Changes to This Notice?
          </h4>
          <p className="privacy-text">
            We may modify this Notice at any time. We will notify you of any
            material changes through written, electronic, or other means as
            permitted by law.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
