import React, { useEffect, useState } from "react";
import comlinkDisability from "../../../../assets/images/Comlink-disablity.JPG";
import comlinkMobileimg from "../../../../assets/images/comlink-mobile-line.png";
import comlinkTvimg from "../../../../assets/images/comlink-tv-trial.png";
import comlinkbundleimg from "../../../../assets/images/acp-logo.png";
import netspend from "../../../../assets/images/netspend-img.png";

const ThankYouPage = () => {
  const [activeStep, setActiveStep] = useState(1); // Initialize activeStep state
  const [netSpendButton, setNetSpendButton] = useState(true);
  const [horoscopesButton, setHoroscopesButton] = useState(true);

  const [stepLinks, setStepLinks] = useState([
    "https://www.nblv4trk.com/2FMZLP/M2GJW/?sub1=&sub2=&sub3=", // Link for Step 1
    "https://www.benefitspilot.com/", // Link for Step 2 (initialize empty, you can change as needed)
    "http://comlinkmobile.com/", // Link for Step 3
    "https://comlinkacp.com/",
    "https://comlinkacp.com/",
    "http://comlinkmobile.com/", // Link for Step 4
  ]);
  const stepImages = [
    comlinkDisability,
    comlinkDisability,
    comlinkMobileimg,
    comlinkMobileimg,
    comlinkTvimg,
  ];
  const handleButtonClick = (btnType) => {
    if (btnType === "skip") {
      const nextStep = activeStep + 1;
      if (nextStep <= stepLinks.length && stepLinks[nextStep - 1] !== "") {
        setActiveStep(nextStep);
      }
    } else if (btnType === "prev") {
      const prevStep = activeStep - 1;
      if (prevStep > 0) {
        setActiveStep(prevStep);
      }
    } else {
      if (stepLinks[activeStep - 1] !== "") {
        window.open(stepLinks[activeStep - 1], "_blank");
      }
    }
  };
  const handleStepClick = (step, e) => {
    e.preventDefault();
    setActiveStep(step);
  };
  return (
    <div className="thanku-screen">
      <div className="congratulation-modal last_step_congratulation_modal mt-6 ">
        <div closeButton>
          <div className="congratulation-modal-header">
            <div className="custom-out-of-box-header">
              <h6>Order Successfully Submitted For Approval 👍</h6>
              <h5>
                Call now to speak with an agent directly :
                <span className="mobiled-block">
                <a href="#">
                  <span className="theme-color-green"> 866-261-2148</span>
                </a>
                <svg
                  className="theme-color-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2a1.001 1.001 0 011.02-.24c1.12.45 2.33.69 3.57.69.55 0 1 .45 1 1v3.5c0 .55-.45 1-1 1C10.29 22 2 13.71 2 3.5 2 2.95 2.45 2.5 3 2.5H6.5c.55 0 1 .45 1 1 0 1.24.24 2.45.69 3.57.13.37.04.79-.24 1.02l-2.2 2.2z" />
                </svg>
                </span>
              </h5>
              <p className="grey-text mb-xl-3 mb-2">Mon-Fri 9am to 12am EST</p>
            </div>
          </div>
        </div>
        <div className="thanks-offers-wraper">
          <h2>Wait, Check Out Our Exclusive Offers!</h2>
          <div id="smartwizard" className="sw-main sw-theme-dots">
            {/* <p>Please choose from the options below to view the incredible discounts on our other fantastic products for your home.</p> */}
            <ul className="nav nav-tabs step-anchor">
              {[1, 2, 3, 4, 5].map((step) => (
                <li
                  key={step}
                  className={`nav-item ${activeStep === step ? "active" : ""}`}
                >
                  <a
                    href={`#step-${step}`}
                    className="nav-link"
                    onClick={(e) => handleStepClick(step, e)}
                  >
                    OFFER {step}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* <div className="item_cong">
            <a target="_blank" href={stepLinks[activeStep - 1]}>
              <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
            </a>
        
          </div> */}
          {/* Conditional rendering based on activeStep */}
          {activeStep === 1 && (
            <div className="modal-box-custom second-offers-box p-3">
              <div className="text-section">
                {/* <div className="item_cong">
                    <a target="_blank" href={stepLinks[activeStep - 1]}>
                    <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
                  </a>
                    </div> */}
                <div className="netspend-visa-wrapper" id="netspend">
                  <h3>Netspend® All-Access ® Account by MetaBank® </h3>
                  <div className="netspend-main-content">
                    <div className="row ">
                      <div className="col-lg-3">
                        <img src={netspend} className="img-fluid" />
                      </div>
                      <div className="col-lg-9">
                        <p>
                          All-Access is a bank account that helps you reach your
                          goals. We’ll help you with the financial freedom to
                          get there.
                        </p>
                        <p>
                          I would like to apply for a Netspend All-Access
                          Account by MetaBank!
                        </p>
                      </div>
                    </div>
                    <div className="text-btn-wrap text-center">
                      {netSpendButton ? (
                        <React.Fragment>
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={() => setNetSpendButton(false)}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={() => setNetSpendButton(false)}
                          >
                            No
                          </button>
                        </React.Fragment>
                      ) : null}
                      <p>
                        Subject to account activation and ID verification. Terms
                        & costs apply. Account issued by MetaBank®, Member FDIC.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="text-section">
                <p>This is content for step 3.</p>
              </div> */}
            </div>
          )}
          {activeStep === 2 && (
            <>
              <div className="modal-box-custom p-3">
                <div className="item_cong">
                  <a target="_blank" href={stepLinks[activeStep - 1]}>
                    <img
                      src={stepImages[activeStep - 1]}
                      alt={`Image for Step ${activeStep}`}
                    />
                  </a>
                </div>
                <p className="learn-more ps-1">
                  Learn more{" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2L12 22"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 15L12 22L5 15"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </div>
            </>
          )}
          {activeStep === 3 && (
            <>
              {/* <div className="modal-box-custom">
                <div className="item_cong">
                <a target="_blank" href={stepLinks[activeStep - 1]}>
                <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
              </a>
                </div>
                <p className="learn-more">Learn more  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L12 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M19 15L12 22L5 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></p> */}
              {/* <div className="text-section">
                  <p>This is content for step 2.</p>
                </div> */}
              {/* </div> */}
              <div className="modal-box-custom second-offers-box p-3">
                <div className="text-section">
                  <div className="row" id="horoscope">
                    <h3 className="text-capitalize">
                      Access to your horoscopes has never been so effortless!
                    </h3>
                    <div className="col-lg-12">
                      <h6 className="text-blue pb-lg-2 pb-md-3 pb-2">
                        Receiving your predictions via texts messages is quick,
                        easy and free*!
                      </h6>
                      {horoscopesButton ? (
                        <div className="text-btn-wrap ">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={() => setHoroscopesButton(false)}
                          >
                            Yes
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={() => setHoroscopesButton(false)}
                          >
                            No
                          </button>
                        </div>
                      ) : null}

                      <span>
                        By entering my number and clicking the "Yes" button, I
                        verify that this is my mobile number and consent to
                        receive Daily Horoscope &amp; Everyday Horoscopes text
                        messages on my mobile phone. Message and data rates may
                        apply. 1 message/day. Reply HELP to 96059 for help,
                        reply STOP to 96059 to opt-out. To obtain help you may:
                        Email the service provider at:
                        customercare@daily-horoscope.us &amp;
                        contact@everydayhoroscopes.com Call the service provider
                        at: +1 (888)-880-9693
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep === 4 && (
            <div className="modal-box-custom">
              <div className="item_cong">
                <a target="_blank" href={stepLinks[activeStep - 1]}>
                  <img
                    src={stepImages[activeStep - 1]}
                    alt={`Image for Step ${activeStep}`}
                  />
                </a>
                <p className="learn-more">
                  Learn more{" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2L12 22"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 15L12 22L5 15"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </div>
              {/* <div className="text-section">
                <p>This is content for step 4.</p>
              </div> */}
            </div>
          )}
          {activeStep === 5 && (
            <div className="modal-box-custom">
              <div className="item_cong">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={stepLinks[activeStep - 1]}
                >
                  <img
                    src={stepImages[activeStep - 1]}
                    alt={`Image for Step ${activeStep}`}
                  />
                </a>
                <p className="learn-more">
                  Learn more{" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2L12 22"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 15L12 22L5 15"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </div>
            </div>
          )}
          {/* {activeStep === 6 && (
        <div className="modal-box-custom">
            <div className="item_cong">
                    <a target="_blank" href={stepLinks[activeStep - 1]}>
                    <img src={stepImages[activeStep - 1]} alt={`Image for Step ${activeStep}`} />
                  </a>
                    </div> 
                    <p className="learn-more">Learn more  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L12 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M19 15L12 22L5 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></p>
        </div>
      )} */}

          {/* <div className="dual-btn-wrap mt-xxl-5 mt-3">
            <button className="btn green-filled-btn" onClick={handleButtonClick}>Click</button>
            <button className="btn green-filled-btn theme-blue-filled" fdprocessedid="ytqjgq" onClick={() => handleButtonClick('skip')}>
            Skip
            </button>
         
            
            
          </div> */}
          {activeStep !== 1 ? (
            <button
              className="left-arrow"
              fdprocessedid="ytqjgq"
              onClick={() => handleButtonClick("prev")}
            ></button>
          ) : null}
          {activeStep !== 5 ? (
            <button
              className="right-arrow"
              fdprocessedid="ytqjgq"
              onClick={() => handleButtonClick("skip")}
            ></button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
