import React, { useEffect, useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;
const IMAGE_URL = process.env.REACT_APP_API_URL_IMAGE;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};

const apiUrl = `${API_URL}/prepaid-plan`;
const apiUrlForUpdating = `${API_URL}/leads/update`;

const PrepaidPlans = (props) => {
  const [prepaidPlans, setPrepaidPlans] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    fetch(`${apiUrl}`)
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Parse the JSON data
        return response.json();
      })
      .then((res) => {
        // Handle the res
        setPrepaidPlans(res?.plans);
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
        setPrepaidPlans([]);
      });
  }, []);

  const handleSelection = (id) => {
    const requestOptionsForUpdate = {
      method: "PUT",
      headers,
      body: JSON.stringify({
        prepaidPlan: id,
        id: props?.leadId,
        prepaidConfirmation: "prepaidConfirmation",
        status: 11,
        step: 2,
        stepStatus: "Completed",
      }),
    };
    fetch(apiUrlForUpdating, requestOptionsForUpdate)
      .then((response) => {
        if (!response?.ok) {
          throw new Error(
            response?.status == 429
              ? "Too many requests from this IP, please try again after a day"
              : response?.status == 409
              ? 409
              : "Something went wrong"
          );
        }
        return response?.json(); // Parse the response body as JSON
      })
      .then((data) => {
        props.setFlag(!props.flag);
        props.nextStep();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {});
  };

  return (
    <div className="custom-container">
      <div className="row prepaid-plan-wrap">
        <h3>Prepaid Plans</h3>
        {prepaidPlans?.map((item, index) => (
          <div
            key={index}
            className="col-xl-3 col-lg-4  col-md-6 col-12 mb-lg-5 mb-3"
          >
            <div
              className={`comlink-plans-box ${
                item?.color == "red" ? " red" : " green"
              }`}
            >
              <div className="comlink-plan-name">{item?.name}</div>
              <div className="comlink-plans-header">
                <div className="subscribe-plane">{item?.data}</div>
                {item?.freeSmartPhone ? (
                  <React.Fragment>
                    <div className="padding_top10 padding_bottom10">+</div>
                    <h4 className="blink">
                      <span>Free Smart Phone</span>
                    </h4>
                  </React.Fragment>
                ) : null}
              </div>
              <div className="comlink-plans-body">
                <div className="pricing_place d-flex flex-column align-items-center justify-content-center">
                  <h4 className="d-flex align-items-end">
                    {item?.isDiscount && item?.salePrice ? (
                      <React.Fragment>
                        <del>${item?.price}</del> <b>${item?.salePrice}</b>
                      </React.Fragment>
                    ) : (
                      <b>${item?.price}</b>
                    )}
                    <p className="f_21">/ mo</p>
                  </h4>
                  <p>
                    {item?.isDiscount && item?.salePrice
                      ? "First month only"
                      : ""}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center get-plan-btn-wraper">
                  <button
                    onClick={() => handleSelection(item?._id)}
                    className="theme-blue-btn"
                  >
                    Get This Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* <div className="my-lg-3 my-2">
          <button className="btn green-filled-btn">Prev</button>
        </div> */}
      </div>
    </div>
  );
};

export default PrepaidPlans;
