import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import comlinkDisability from "../../../../../src/assets/images/Comlink-disablity.JPG";
import comlinkMobileimg from "../../../../../src/assets/images/comlink-mobile-line.png";
import comlinkTvimg from "../../../../../src/assets/images/comlink-tv-trial.png";
import comlinkbundleimg from "../../../../../src/assets/images/acp-logo.png";
import netspend from "../../../../../src/assets/images/netspend-img.png";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CongratulationModal from "../Congratulation-modal/Congratulation-modal";
import StripeModal from "../Stripe/Stripe";
import StripeCharge from "../Stripe/StripeCharge";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};
const apiUrl = `${API_URL}/leads/get-lead-info`;
const apiUrlForPayment = `${API_URL}/leads/get-lead-payment`;

const options = {
  style: {
    base: {
      iconColor: "#409131",
      height: "50px",
      fontWeight: "400",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#212529",
      },
    },
    invalid: {
      iconColor: "#ff0101",
      color: "#ff0101",
    },
  },
};

const LastStep = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [leadDetail, setLeadDetail] = useState({});
  const [leadData, setLeadData] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [cardError, setCardError] = useState("");
  const [flag, setFlag] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [orderSubmitionType, setOrderSubmitionType] = useState(null);
  const [orderSubmitionLink, setOrderSubmitionLink] = useState(null);

  const [activeStep, setActiveStep] = useState(1); // Initialize activeStep state
  const [showModal, setShowModal] = useState(false); // Initialize modal visibility state
  const [stripeModalShow, setStripeModalShow] = useState(false); // Initialize modal visibility state
  const [stripePrice, setStripePrice] = useState();
  //start to show thank you model
  const [stepLinks, setStepLinks] = useState([
    "https://www.nblv4trk.com/2FMZLP/M2GJW/?sub1=&sub2=&sub3=", // Link for Step 1
    "https://comlinktv.com/", // Link for Step 2 (initialize empty, you can change as needed)
    "http://comlinkmobile.com/", // Link for Step 3
    "https://comlinkacp.com/",
    "https://comlinkacp.com/",
    "http://comlinkmobile.com/", // Link for Step 4
  ]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
  }, []);

  useEffect(() => {
    let totalPrice = 0;

    leadDetail?.dealsId?.forEach((item) => {
      leadDetail?.dealSelectedItems.forEach((data) => {
        if (data?._id === item?._id) {
          let planPrice =(data?.discountPrice && data.discountPrice !== '0') ? parseFloat(data.discountPrice) : parseFloat(item.planPrice || "0");
          let acpDiscountPrice = parseFloat(item.acpDiscountPrice || "0");
          let professionalInstallationFee = parseFloat(
            item.professionalInstallationFee || "0"
          );

          totalPrice +=
            data?.acpDiscount &&
            item?.acpDiscountPrice &&
            item?.acpDiscountPrice !== "0"
              ? data?.installationOptions === "Professional Installation" &&
                item?.professionalInstallationFee &&
                item?.professionalInstallationFee !== "0"
                ? planPrice + professionalInstallationFee - acpDiscountPrice
                : data?.installationOptions !== "Professional Installation"
                ? planPrice - acpDiscountPrice
                : planPrice - acpDiscountPrice
              : data?.installationOptions === "Professional Installation" &&
                item?.professionalInstallationFee &&
                item?.professionalInstallationFee !== "0"
              ? planPrice + professionalInstallationFee
              : planPrice;
        }
      });
    });

    setStripePrice(totalPrice);
    setSubTotal(totalPrice);
  }, [leadDetail]);

  useEffect(() => {
    if (props.leadId) {
      fetch(`${apiUrl}?id=${props?.leadId}`)
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          // Parse the JSON data
          return response.json();
        })
        .then((data) => {
          // Handle the data
          setOrderSubmitionType( data?.lead?.dealsId[0]?.after_submision );
          setOrderSubmitionLink( data?.lead?.dealsId[0]?.after_submision_link );
          setLeadDetail(data.lead);
          

          if(data?.lead?.dealsId[0]?.after_submision == 'gtsl')
          {
              window.location.href = data?.lead?.dealsId[0]?.after_submision_link
          }

        })
        .catch((error) => {
          // Handle errors
          setLeadDetail({});
        });
    }

    if(props.leadData)
    {
      setLeadData(props.leadData);
    }
  }, [props.flag]);

  useEffect(() => {
    if(props.leadData)
    {
      setLeadData(props.leadData);
    }
  }, [props.step]);

  const handleStepClick = (step, e) => {
    e.preventDefault();
    setActiveStep(step);
  };
  const handleButtonClick = (btnType) => {
    if (btnType === "skip") {
      const nextStep = activeStep + 1;
      if (nextStep <= stepLinks.length && stepLinks[nextStep - 1] !== "") {
        setActiveStep(nextStep);
      }
    } else if (btnType === "prev") {
      const prevStep = activeStep - 1;
      if (prevStep > 0) {
        setActiveStep(prevStep);
      }
    } else {
      if (stepLinks[activeStep - 1] !== "") {
        window.open(stepLinks[activeStep - 1], "_blank");
      }
    }
  };

  useEffect(() => {    
  }, [props?.currentStep]);

  const stepImages = [
    comlinkDisability,
    comlinkTvimg,
    comlinkMobileimg,
    comlinkMobileimg,
    comlinkTvimg,
  ];

  useEffect(() => {
    const queryString = window.location.search.slice(1);
    var url = window?.location?.href;
    if (
      props?.step == "4" &&
      queryString == "camp_id=2617&ref_id=6331&source=Facebook"
    ) {
      setStripePrice(4.99);
      const timer = setTimeout(() => {
        setStripeModalShow(true);
      }, 2000);
    } else if (props?.step == "4") {
      // const timer = setTimeout(() => {
        setStripeModalShow(true);
      // }, 2000);
    }
  }, [props?.step]);

  return (
    <div className="application-step-form-wraper">
      <div className="custom-container">
        <div className="leads-step4-wraper">
          <div className="lead-step-form-card padding-70">
            {
              orderSubmitionType == 'gtsp' ?
              <StripeCharge
                leadId={props.leadId}
                setStripeModalShow={setStripeModalShow}
                stripeModalShow={stripeModalShow}
                price={stripePrice}
                leadDetail={leadDetail}
                leadData={leadData}
              /> : null
            }
            <div className="row">
              <div className="col-lg-7 col-md-6 mb-lg-0 mb-2">
                <div className="order-submitted-inner">
                  <h6 className="theme-clr font-medium mb-xl-3 mb-2">
                    Order Submitted Successfully!
                  </h6>
                  <p className="mb-xxl-3 mb-2">
                    For quicker installation or shipment please call the number
                    below.{" "}
                  </p>
                  <p className="font-medium mb-xl-3 mb-2">
                    Call now to speak with an agent directly!
                  </p>
                  <h5 className="contact-text">
                    <span className="theme-clr font-normal">Call:</span>{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      href="tel:+18662612148"
                      className="green-clr font-medium "
                    >
                      866-261-2148
                    </a>
                  </h5>
                  <p className="grey-text mb-xl-3 mb-2">
                    Mon-Fri 11AM to 8PM EST
                  </p>
                </div>
                {/* <div className="col-12 mt-lg-3 mt-2 payment-detail--row">
                  <div className="d-flex mb-md-3 mb-2">
                    <h4 className="blue-text">Payment Detail</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="stripe_field_wrap">
                      <CardElement
                        options={options}
                        onChange={(e) => handleCardChange(e)}
                      />
                    </div>
                    {cardError ? <p className="for-red">{cardError}</p> : null}
                  </div>
                  <div className="d-flex justify-content-lg-end align-lg-items-end align-items-center justify-content-center mt-lg-4 mt-3">
                    {buttonLoader ? (
                      <div className="theme-blue-btn">
                        <span role="status">Loading...</span>
                        <span
                          className="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                      </div>
                    ) : (
                      <button
                        onClick={() => sumbitPayment()}
                        className="theme-blue-btn"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div> */}
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="last-step-right-content shoping-cart-detail mb-xxl-3 mb-2">
                  <div className="grey-bg-card">
                    <div className="grey-bg-card-content top-green-border">
                      <p className="font-bold mb-xl-3 mb-2">
                        Your Shopping Cart{" "}
                      </p>
                      {leadDetail?.dealsId?.map((item, idx) =>
                        leadDetail?.dealSelectedItems.map(
                          (data, index) =>
                          
                            data?._id == item?._id && (
                              <span>
                                <div
                                  key={idx}
                                  className="d-flex justify-content-between"
                                >
                                  <p className="grey-text left-side-text">
                                    {item?.name} 
                                  </p>
                                  <p className="grey-text font-bold text-end">
                                    {`$${Number(item?.planPrice)?.toFixed(
                                      2
                                    )}/mo`}
                                  </p>
                                </div>
                                {data?.acpDiscount &&
                                item?.acpDiscountPrice &&
                                item?.acpDiscountPrice !== "0" ? (
                                  <React.Fragment>
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex flex-column">
                                        <label className="mb-1">
                                          ACP Discount
                                        </label>
                                        <span className="small-text2">
                                          ACP APPROVAL REQUIRED
                                        </span>
                                      </div>
                                      <p className="grey-text text-end">
                                        {`-$${Number(
                                          item?.acpDiscountPrice
                                        ).toFixed(2)}/mo`}
                                      </p>
                                    </div>
                                    {item?.professionalInstallationFee &&
                                    item?.professionalInstallationFee !== "0" &&
                                    data?.installationOptions ==
                                      "Professional Installation" ? (
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                          <label className="my-2 grey-text left-side-text">
                                            Installation Options Fee
                                          </label>
                                        </div>
                                        <p className="grey-text text-end">
                                          {`+$${Number(
                                            item?.professionalInstallationFee
                                          ).toFixed(2)}`}
                                        </p>
                                      </div>
                                    ) : null}
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                                <hr />
                              </span>
                            )
                        )
                      )}
                      <div className="d-flex justify-content-between align-items-end">
                        <label className="m-0">Shipping Fee (One Time)</label>
                        <p className="grey-text font-bold text-end">$5.00</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-end">
                        <label className="m-0">Service Fee (One Time)</label>
                        <p className="grey-text font-bold text-end">$2.99</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h6 className="fs-17">Total</h6>
                        <h6 className="grey-text font-bold text-end fs-17">
                          ${(subTotal + 5 + 2.99)?.toFixed(2)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="last-step-right-content services-receipet-detail">
                  <div className="grey-bg-card">
                    <div className="grey-bg-card-content top-green-border">
                      <p className="font-bold mb-xl-3 mb-2">
                        Order Receipt for your services
                      </p>
                      <div className="d-flex justify-content-between mb-xxl-3 mb-2 align-items-center">
                        <p className="grey-text left-side-text">
                          Confirmation Number
                        </p>
                        <p className="grey-text font-bold right-end-text">
                          {leadDetail?.dealOrderConfirmationNumber}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-xxl-3 mb-2 align-items-center">
                        <p className="grey-text left-side-text">
                          Customer Name
                        </p>
                        <p className="grey-text font-bold right-end-text">
                          {(leadDetail?.firstname ?? "") + " " + (leadDetail?.lastname ?? "")}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-xxl-3 mb-2 align-items-center">
                        <p className="grey-text left-side-text">Order Date</p>
                        <p className="grey-text font-bold right-end-text">
                          {leadDetail?.createdAt &&
                            new Date(leadDetail.createdAt).toLocaleString()}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-xxl-3 mb-2 align-items-center">
                        <p className="grey-text left-side-text">
                          Service Address
                        </p>
                        <p className="grey-text font-bold right-end-text">
                          {leadDetail?.metaData?.serviceAddress},{" "}
                          {leadDetail?.metaData?.serviceCity}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-xxl-3 mb-2 align-items-center">
                        <p className="grey-text left-side-text">Phone Number</p>
                        <p className="grey-text font-bold right-end-text">
                          {leadDetail?.phone}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-xxl-3 mb-2 font-18 align-items-center">
                        <p className="grey-text font-bold left-side-text">
                          Order Submitted
                        </p>
                        <p className="grey-text font-bold right-end-text">
                          Status:
                          <span className="green-text"> Completed</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CongratulationModal
            showModal={showModal}
            handleShowModal={handleShowModal}
            handleCloseModal={handleCloseModal}
          />
          {/* <div className="d-flex justify-content-start mt-lg-4 mt-3">
            <button
              className="btn theme-btn"
              onClick={() => {
                props.goToStep(3);
              }}
            >
              Back
            </button> */}
          {/* <button className="btn theme-btn  ms-sm-3" onClick={() => handleNext()}>Next</button> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default LastStep;
