import React, { useEffect, useLayoutEffect, useState } from "react";
import { Images } from "../../../../assets/assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import SelectPlan from "./SelectPlan";
import TermsConditionStep3 from "./TermsConditionStep3";
import Loader from "../Loader/Loader";
import { Tab, Tabs } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;
const IMAGE_URL = process.env.REACT_APP_API_URL_IMAGE;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};
const apiUrl = `${API_URL}/deal/get-deals`;
const apiUrlForUpdating = `${API_URL}/leads/update`;

const Deals = (props) => {
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [deals, setDeals] = useState({});
  const [dealIds, setDealIds] = useState([]);
  const [service, setService] = useState("Internet");
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (props?.serviceZipcode) {
      const currentPath = window.location.pathname;

      setLoader(true);
      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({ type: props.data.type }),
      };

      fetch(
        `${apiUrl}?zipcode=${props?.serviceZipcode}&providerType=${
          currentPath == "/cabledeals"
            ? "Optimum"
            : currentPath == "/fiberoffers"
            ? "Frontier"
            : ""
        }`,
        requestOptions
      )
        .then((response) => {
          if (!response?.ok) {
            throw new Error(
              response?.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : response?.status == 409
                ? 409
                : "Something went wrong"
            );
          }
          return response?.json(); // Parse the response body as JSON
        })
        .then((data) => {
          setDeals(data?.deals);
        })
        .catch((error) => {
          console.error("Error:", error);
          setDeals({});
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [props?.data?.type]);

  const addDeals = () => {
    toast.dismiss();
    if (props?.data?.type[props?.data?.type?.length - 1] !== service) {
      setService(props?.data?.type[step]);
      setStep(step + 1);
      return;
    }
    if (dealIds?.length > 0) {
      setLoader2(true);
      setLoader(true);

      const matchedDeals = [];
      deals?.Internet?.forEach((deal) => {
        if (dealIds.includes(deal._id)) {
          const { _id, acpDiscount, installationOptions } = deal;
          matchedDeals.push({ _id, acpDiscount, installationOptions });
        }
      });
      deals?.TV?.forEach((deal) => {
        if (dealIds.includes(deal._id)) {
          const { _id, acpDiscount, installationOptions } = deal;
          matchedDeals.push({ _id, acpDiscount, installationOptions });
        }
      });
      deals?.Phone?.forEach((deal) => {
        if (dealIds.includes(deal._id)) {
          const { _id, acpDiscount, installationOptions } = deal;
          matchedDeals.push({ _id, acpDiscount, installationOptions });
        }
      });
      const requestOptionsForUpdate = {
        method: "PUT",
        headers,
        body: JSON.stringify({
          dealIds,
          id: props?.leadId,
          dealSelectedItems: matchedDeals,
          dealConfirmation: "dealConfirmation",
          status: 11,
          step: 3,
          stepStatus: "Completed",
        }),
      };
      fetch(apiUrlForUpdating, requestOptionsForUpdate)
        .then((response) => {
          if (!response?.ok) {
            throw new Error(
              response?.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : response?.status == 409
                ? 409
                : "Something went wrong"
            );
          }
          return response?.json(); // Parse the response body as JSON
        })
        .then((data) => {
          props.setFlag(!props.flag);
          props.setStep("4");
          props.nextStep();
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoader(false);
          setLoader2(false);
        });
    } else {
      toast.error("Please select any deal");
    }
  };
  // const handleTerms = (index) => {
  //   const updatedDeals = [...deals];
  //   updatedDeals[index].termAndCondition = true;
  //   setDeals(updatedDeals);
  // };

  const handleInstallmentOption = (index, value, category) => {
    // if (value == "Professional Installation") {
    // }
    const updatedDeals = { ...deals };
    deals[category][index].installationOptions = value;
    setDeals(updatedDeals);
  };

  const handleACPDiscount = (index, category) => {
    setDeals((prevDeals) => {
      const updatedDeals = { ...prevDeals };
      updatedDeals[category][index] = {
        ...updatedDeals[category][index],
        acpDiscount: !updatedDeals[category][index].acpDiscount,
      };
      return updatedDeals;
    });
  };

  const handleDealSelects = (id, item, category, idx) => {
    toast.dismiss();
  
    // Deselect all previous deals
    const resetDeals = {};
    for (let cat in deals) {
      resetDeals[cat] = deals[cat].map(deal => ({
        ...deal,
        installationOptions: " "
      }));
    }
    
    // Set the new selected deal
    const updatedDeals = [...resetDeals[category]];
    updatedDeals[idx] = {
      ...updatedDeals[idx],
      installationOptions: "",
    };
  
    setDealIds([id]);
    setDeals({ ...resetDeals, [category]: updatedDeals });
  };

  const handleService = (value, index) => {
    setService(value);
    setStep(index + 1);
  };

  return (
    <div className="application-step-form-wraper deals-step-screen">
      {loader ? (
        <Loader />
      ) : (
        <div className="custom-container">
          <div className="fency-check-radio-button fency-rounded-radio-button">
            <div className="d-flex justify-content-center align-items-center tabs-radio-btns">
              <div className="grid-col-auto">
                {props?.data?.type
                  ? props?.data?.type?.map((item, index) => (
                      <label key={index} className="radio-card m-2">
                        <input
                          type="radio"
                          name="service"
                          checked={service === item}
                          onChange={() => handleService(item, index)}
                        />
                        <div className="card-content-wrapper card-services-content-wrapper">
                          <div className="card-services-content">
                            <div className="small-device-wraper">
                              <img
                                src={
                                  item == "Internet"
                                    ? Images.smallinternet
                                    : item == "TV"
                                    ? Images.smalltv
                                    : Images.smallphone
                                }
                                alt="img"
                                className="img-fluid non-active-img"
                              />
                              <img
                                src={
                                  item == "Internet"
                                    ? Images.smallwhiteinternet
                                    : item == "TV"
                                    ? Images.smallwhitetv
                                    : Images.smallwhitephone
                                }
                                alt="img"
                                className="img-fluid white-active-img"
                              />
                            </div>
                          </div>
                        </div>
                        <small className="">{item}</small>
                      </label>
                    ))
                  : null}
              </div>
            </div>
          </div>
          <Tabs id="controlled-tabs" activeKey={service}>
            <Tab eventKey="Internet">
              <div className="leads-step3-wraper ">
                <div className="lead-step-form-card">
                  {/* desktop-view */}
                  {window.innerWidth > 767 ? (
                    <div className="row step3-leads-card desktop-viewdeals">
                      <div className="step3-leads-card_inner">
                        {deals?.Internet?.length > 0 ? (
                          deals?.Internet?.map((item, index) => (
                            <div key={index} className="col-12  mb-3">
                              <div className="card">
                                <div className="card-header">
                                  <h6>{"Plan " + (index + 1)}</h6>
                                  <p>
                                    99% available in{" "}
                                    {props?.serviceZipcode
                                      ? props?.serviceZipcode
                                      : "your area"}
                                  </p>
                                </div>
                                <div
                                  className={`card-body ${
                                    dealIds.includes(item?._id)
                                      ? "plan-selected"
                                      : ""
                                  }`}
                                >
                                  <div className="row align-items-xl-center">
                                    <div className="col-md-9">
                                      <div className="row">
                                        <div className="col-lg-3">
                                          <div className="deals-logo-wraper">
                                            <img
                                              src={`${IMAGE_URL}/uploads/images/${item?.dealProvider?.[0]?.logo}`}
                                              alt="img"
                                              className="img-fluid"
                                            />
                                          </div>
                                          <ul className="radio-list simple-radio-butn">
                                            <li>
                                              {/* <input
                                            type="radio"
                                            id={`termAndCondition${index}`}
                                            name={`termAndCondition${index}`}
                                            value={
                                              item?.termAndCondition ?? false
                                            }
                                            onChange={() => handleTerms(index)}
                                          /> */}
                                              <label
                                                htmlFor={`termAndCondition${index}`}
                                              >
                                                <TermsConditionStep3
                                                  data={item}
                                                />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                          <div className="serivce-box">
                                            <p className="font-bold dark-grey">
                                              Connection:
                                            </p>
                                            <p>
                                              {item?.connection
                                                ? item?.connection
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                          <div className="serivce-box">
                                            <p className="font-bold dark-grey">
                                              Download speeds up to:
                                            </p>
                                            <p>
                                              <span className="green-big-text">
                                                {item?.speed
                                                  ? item?.speed
                                                  : "-"}
                                              </span>
                                              Mbps
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                          <div className="serivce-box">
                                            <ul className="start-list">
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                            </ul>
                                            {item?.reviewCount ? (
                                              <p>
                                                User Rating (
                                                {item?.reviewCount
                                                  ? item?.reviewCount
                                                  : "13"}
                                                )
                                              </p>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="service-data black-gradient">
                                        <div className="d-flex pkg-amount-box">
                                          <div className="d-flex">
                                            <h4>
                                              <sup>$</sup>
                                            </h4>
                                            <h1>
                                              {item?.acpDiscount &&
                                              item?.acpDiscountPrice !== "0"
                                                ? item?.planPrice &&
                                                  item?.acpDiscountPrice
                                                  ? (
                                                      parseFloat(
                                                        item?.planPrice
                                                      ) -
                                                      parseFloat(
                                                        item?.acpDiscountPrice
                                                      )
                                                    )
                                                      .toString()
                                                      .split(".")[0]
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[0]
                                                : item?.planPrice
                                                ? item?.planPrice?.split(".")[0]
                                                : "0"}
                                            </h1>
                                          </div>
                                          <div className="d-flex flex-column ms-lg-3 ms-2">
                                            <h4>
                                              .
                                              {item?.acpDiscount &&
                                              item?.acpDiscountPrice !== "0"
                                                ? item?.planPrice &&
                                                  item?.acpDiscountPrice
                                                  ? (
                                                      item?.planPrice -
                                                      item?.acpDiscountPrice
                                                    )
                                                      ?.toFixed(2)
                                                      ?.split(".")[1]
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[1] == undefined
                                                  ? "00"
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[1]
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1] == undefined
                                                ? "00"
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1]}
                                              /mo
                                            </h4>
                                            <p>
                                              {item?.contractLength == "No" ||
                                              item?.contractLength == "NO" ||
                                              item?.contractLength == "no"
                                                ? "No contract required"
                                                : item?.contractLength}
                                            </p>
                                          </div>
                                        </div>
                                        {item?.acpDiscountPrice &&
                                        item?.acpDiscountPrice !== "0" ? (
                                          <div className="service-card-footer custom-toggle">
                                            <div className="toggle-heading">
                                              <p>Apply ACP Discount</p>
                                            </div>
                                            <input
                                              type="checkbox"
                                              className="toggle"
                                              name={`toggle${index}`}
                                              id={`toggle${index}`}
                                              value={item?.acpDiscount}
                                              onClick={() =>
                                                handleACPDiscount(
                                                  index,
                                                  "Internet"
                                                )
                                              }
                                            />
                                            <label for={`toggle${index}`}>
                                              <span className="on">Yes</span>
                                              <span className="off">No</span>
                                            </label>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex courtesy-msg-box-wraper justify-content-between align-items-center">
                                    <div className="d-flex courtesy-msg-box device_with_plan">
                                      <div className="fency-check-radio-button">
                                        <div className="grid-wrapper grid-col-auto">
                                          <label
                                            for={`selectedDeal${index}first`}
                                            className="radio-card"
                                          >
                                            <input
                                              className="overflow-control-input"
                                              id={`selectedDeal${index}first`}
                                              name={`selectedDeal${index}first`}
                                              type="radio"
                                              checked={dealIds.includes(
                                                item?._id
                                              )}
                                              onChange={() =>
                                                handleDealSelects(
                                                  item?._id,
                                                  item,
                                                  "Internet",
                                                  index
                                                )
                                              }
                                            />
                                            <div className="card-content-wrapper">
                                              {/* <span className="check-icon"></span> */}
                                              {props?.data?.preferredDevice ==
                                              "Mobile" ? (
                                                <div className="card-content">
                                                  <div className="small-device-wraper">
                                                    <img
                                                      src={Images.smallMobile}
                                                      alt="img"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <small className="green-clr">
                                                    Smart Phone
                                                  </small>
                                                </div>
                                              ) : (
                                                <div className="card-content">
                                                  <div className="small-device-wraper">
                                                    <img
                                                      src={Images.smallTablet}
                                                      alt="img"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <small className="green-clr">
                                                    Tablet
                                                  </small>
                                                </div>
                                              )}
                                            </div>
                                          </label>
                                          <div className="d-flex align-items-center">
                                            <small className="d-flex">
                                              Courtesy of{" "}
                                              <img
                                                src={Images.comlinklogo}
                                                alt="img"
                                                className="img-fluid ms-1 me-1 h-20"
                                              />
                                              with installed internet
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-xxl-3 mt-1">
                                      <ul className="radio-list simple-radio-butn">
                                        {item.installationOptions !== " " ? (
                                          <>
                                            <li>
                                              <input
                                                type="radio"
                                                id={`first${index}`}
                                                name={`installationOptions${index}`}
                                                checked={
                                                  item.installationOptions ===
                                                  "Self Installation"
                                                }
                                                onChange={() =>
                                                  handleInstallmentOption(
                                                    index,
                                                    "Self Installation",
                                                    "Internet"
                                                  )
                                                }
                                              />
                                              <label htmlFor={`first${index}`}>
                                                Self Installation
                                              </label>
                                            </li>
                                            <li>
                                              <input
                                                type="radio"
                                                id={`second${index}`}
                                                name={`installationOptions${index}`}
                                                checked={
                                                  item.installationOptions ===
                                                  "Professional Installation"
                                                }
                                                onChange={() =>
                                                  handleInstallmentOption(
                                                    index,
                                                    "Professional Installation",
                                                    "Internet"
                                                  )
                                                }
                                              />
                                              <label htmlFor={`second${index}`}>
                                                Professional Installation
                                              </label>
                                            </li>
                                          </>
                                        ) : null}
                                        <li>
                                          <div className="selct-plan-check-box">
                                            <label
                                              for={`selectedDeal${index}second`}
                                              className="custom-control overflow-checkbox relative"
                                            >
                                              <input
                                                className="overflow-control-input"
                                                id={`selectedDeal${index}second`}
                                                name={`selectedDeal${index}second`}
                                                type="checkbox"
                                                checked={dealIds.includes(
                                                  item?._id
                                                )}
                                                onChange={() =>
                                                  handleDealSelects(
                                                    item?._id,
                                                    item,
                                                    "Internet",
                                                    index
                                                  )
                                                }
                                              />
                                              <span className="overflow-control-indicator"></span>
                                              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                                                <p className="Submitting_form green-clr font-medium">
                                                  Select Plan
                                                </p>
                                              </span>
                                            </label>
                                          </div>
                                        </li>
                                        {/* <li>
                                <button className="btn green-rounded-btn submit-order-btn">
                                  Submit Order
                                </button>
                              </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-deal-found">
                            <div className="no-deal-image">
                              <img
                                src={Images.noDeal}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <h6 className="text-center">
                              No Deal available in your area
                            </h6>
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-start  mt-3">
                          <button
                            className="btn theme-btn"
                            onClick={() => {
                              props.previousStep();
                            }}
                          >
                            Back
                          </button>
                          {/* <button className="btn theme-btn ms-sm-3">Next</button> */}
                        </div>
                        {/* {deals?.length > 0 ? ( */}
                        <div className="d-flex mt-lg-0 mt-3 justify-content-end align-items-center">
                          {loader2 ? (
                            <button disabled className="btn theme-btn d-flex ">
                              <span className="ml_9"> Loading...</span>
                              <div className="spinner"></div>
                            </button>
                          ) : (
                            <button
                              onClick={() => addDeals()}
                              className="btn green-filled-btn"
                            >
                              {props?.data?.type?.[
                                props?.data?.type?.length - 1
                              ] == service
                                ? "Submit"
                                : "Next"}
                            </button>
                          )}
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                  ) : (
                    //Start faizan
                    <div className="row step3-leads-card mobile-viewdeals">
                      <div className="step3-leads-card_inner">
                        {deals?.Internet?.length > 0 ? (
                          deals?.Internet?.map((item, index) => (
                            <div key={index} className="col-12 mb-lg-4 mb-4">
                              <div className="card">
                                <div className="card-header">
                                  <h6>{"Plan " + (index + 1)}</h6>
                                  {/* <p>
                                    99% available in{" "}
                                    {props?.serviceZipcode
                                      ? props?.serviceZipcode
                                      : "your area"}
                                  </p> */}
                                </div>
                                <div
                                  className={`card-body ${
                                    dealIds.includes(item?._id)
                                      ? "plan-selected"
                                      : ""
                                  }`}
                                >
                                  <div className="row align-items-xl-center packges-name-row">
                                    <div className="col-6">
                                      <div className="d-flex flex-sm-column flex-row justify-content-center">
                                        <div className="deals-logo-wraper">
                                          <img
                                            src={`${IMAGE_URL}/uploads/images/${item?.dealProvider?.[0]?.logo}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="serivce-box">
                                        <ul className="start-list">
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                        </ul>
                                        {item?.reviewCount ? (
                                          <p className="text-center mb-lg-3 mb-1">
                                            User Rating (
                                            {item?.reviewCount
                                              ? item?.reviewCount
                                              : "13"}
                                            )
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="service-data black-gradient">
                                        <div className="d-flex service-top-price">
                                          <div className="d-flex">
                                            <h4>
                                              <sup>$</sup>
                                            </h4>
                                            <h1>
                                              <h1>
                                                {item?.acpDiscount &&
                                                item?.acpDiscountPrice !== "0"
                                                  ? item?.planPrice &&
                                                    item?.acpDiscountPrice
                                                    ? (
                                                        item?.planPrice -
                                                        item?.acpDiscountPrice
                                                      )
                                                        ?.toString()
                                                        ?.split(".")[0]
                                                    : item?.planPrice?.split(
                                                        "."
                                                      )[0]
                                                  : item?.planPrice
                                                  ? item?.planPrice?.split(
                                                      "."
                                                    )[0]
                                                  : "0"}
                                              </h1>
                                            </h1>
                                          </div>
                                          <div className="d-flex flex-column ms-lg-3 ms-2">
                                            <h4>
                                              .
                                              {item?.acpDiscount &&
                                              item?.acpDiscountPrice !== "0"
                                                ? item?.planPrice &&
                                                  item?.acpDiscountPrice
                                                  ? (
                                                      item?.planPrice -
                                                      item?.acpDiscountPrice
                                                    )
                                                      ?.toFixed(2)
                                                      ?.split(".")[1]
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[1] == undefined
                                                  ? "00"
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[1]
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1] == undefined
                                                ? "00"
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1]}
                                              /mo
                                            </h4>
                                            <p>
                                              {item?.contractLength == "No" ||
                                              item?.contractLength == "NO" ||
                                              item?.contractLength == "no"
                                                ? "No contract required"
                                                : item?.contractLength}
                                            </p>
                                          </div>
                                        </div>
                                        {item?.acpDiscountPrice &&
                                        item?.acpDiscountPrice !== "0" ? (
                                          <div className="service-card-footer custom-toggle">
                                            <div className="toggle-heading">
                                              <p>Apply ACP Discount</p>
                                            </div>
                                            <input
                                              type="checkbox"
                                              className="toggle"
                                              name={`toggle${index}second`}
                                              id={`toggle${index}second`}
                                              value={item?.acpDiscount}
                                              onClick={() =>
                                                handleACPDiscount(
                                                  index,
                                                  "Internet"
                                                )
                                              }
                                            />
                                            <label for={`toggle${index}second`}>
                                              <span className="on">Yes</span>
                                              <span className="off">No</span>
                                            </label>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <ul className="radio-list simple-radio-butn mt-lg-3 mt-2">
                                      <li className="me-0 align-items-center">
                                        <label
                                          htmlFor={`termAndCondition${index}`}
                                        >
                                          <TermsConditionStep3 data={item} />
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="row packg-detail-row mt-lg-4 mt-1">
                                    <div className="col-12">
                                      <hr className="m-0" />
                                    </div>
                                    <div className="col-6">
                                      <div className="serivce-box">
                                        <p className="font-bold dark-grey">
                                          Connection:
                                        </p>
                                        <p>
                                          {item?.connection
                                            ? item?.connection
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="serivce-box">
                                        <p className="font-bold dark-grey">
                                          Download speed:
                                        </p>
                                        <p>
                                          <span className="green-big-text">
                                            {item?.speed ? item?.speed : "-"}
                                          </span>
                                          Mbps
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex courtesy-msg-box-wraper justify-content-between flex-md-row flex-column align-items-sm-center">
                                    <div className="d-flex courtesy-msg-box device_with_plan">
                                      <div className="fency-check-radio-button">
                                        <div className="grid-wrapper grid-col-auto">
                                          <label
                                            for="radio-card-3"
                                            className="radio-card"
                                          >
                                            <input
                                              type="radio"
                                              name="preferredDevice"
                                              id="radio-card-3"
                                            />
                                            <div className="card-content-wrapper">
                                              {/* <span className="check-icon"></span> */}
                                              {props?.data?.preferredDevice ==
                                              "Mobile" ? (
                                                <div className="card-content">
                                                  <div className="small-device-wraper">
                                                    <img
                                                      src={Images.smallMobile}
                                                      alt="img"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <small className="green-clr">
                                                    Free Smart Phone
                                                  </small>
                                                </div>
                                              ) : (
                                                <div className="card-content">
                                                  <div className="small-device-wraper">
                                                    <img
                                                      src={Images.smallTablet}
                                                      alt="img"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <small className="green-clr">
                                                    Free Tablet
                                                  </small>
                                                </div>
                                              )}
                                            </div>
                                          </label>
                                        </div>
                                        <div className="d-flex align-items-center courtesy-msg-box-wraper">
                                          <small className="courtesy-msg-box">
                                            Courtesy of
                                            <img
                                              src={Images.comlinklogo}
                                              alt="img"
                                              className="img-fluid ms-1 me-1 h-20"
                                            />
                                            with installed internet
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-lg-3 mt-1">
                                      <ul className="radio-list simple-radio-butn step3-select-plan">
                                        <li className="w-100 justify-content-center mb-lg-3 mb-2 mt-lg-3 mt-1 flex-width">
                                          <div className="selct-plan-check-box mb-3 mt-3 ">
                                            <label
                                              for={`selectedDeal${index}third77`}
                                              className="custom-control overflow-checkbox relative"
                                            >
                                              <input
                                                className="overflow-control-input"
                                                id={`selectedDeal${index}third77`}
                                                name={`selectedDeal${index}third77`}
                                                type="checkbox"
                                                checked={dealIds.includes(
                                                  item?._id
                                                )}
                                                onChange={() =>
                                                  handleDealSelects(
                                                    item?._id,
                                                    item,
                                                    "Internet",
                                                    index
                                                  )
                                                }
                                              />
                                              <span className="overflow-control-indicator "></span>
                                              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                                                <p className="Submitting_form green-clr font-medium">
                                                  Select Plan
                                                </p>
                                              </span>
                                            </label>
                                          </div>
                                        </li>
                                        {item.installationOptions !== " " ? (
                                          <>
                                            {" "}
                                            <li className="">
                                              <input
                                                type="radio"
                                                id={`third${index}`}
                                                name={`installationOptions${index}`}
                                                checked={
                                                  item.installationOptions ===
                                                  "Self Installation"
                                                }
                                                onChange={() =>
                                                  handleInstallmentOption(
                                                    index,
                                                    "Self Installation",
                                                    "Internet"
                                                  )
                                                }
                                              />
                                              <label for={`third${index}`}>
                                                {" "}
                                                Self Installation
                                              </label>
                                            </li>
                                            <li className="">
                                              <input
                                                type="radio"
                                                id={`fourth${index}`}
                                                name={`installationOptions${index}`}
                                                checked={
                                                  item.installationOptions ===
                                                  "Professional Installation"
                                                }
                                                onChange={() =>
                                                  handleInstallmentOption(
                                                    index,
                                                    "Professional Installation",
                                                    "Internet"
                                                  )
                                                }
                                              />
                                              <label for={`fourth${index}`}>
                                                Professional Installation
                                              </label>
                                            </li>
                                          </>
                                        ) : null}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-deal-found mobile-no-record">
                            <div className="no-deal-image">
                              <img
                                src={Images.noDeal}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <h6 className="text-center">
                              {" "}
                              No Deal available in your area
                            </h6>
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content-start mt-lg-4 mt-3">
                          <button
                            className="link---btn"
                            onClick={() => {
                              props.previousStep();
                            }}
                          >
                            <span>
                              <FontAwesomeIcon icon={faChevronLeft} />
                            </span>
                            Back
                          </button>
                          {/* <button className="btn theme-btn ms-sm-3">Next</button> */}
                        </div>
                        {/* {deals?.length > 0 ? ( */}
                        <div className="d-flex mt-lg-0 mt-3 justify-content-end align-items-center">
                          {loader2 ? (
                            <button disabled className="btn theme-btn d-flex ">
                              <span className="ml_9"> Loading...</span>
                              <div className="spinner"></div>
                            </button>
                          ) : (
                            <button
                              onClick={() => addDeals()}
                              className="btn green-filled-btn"
                            >
                              {props?.data?.type?.[
                                props?.data?.type?.length - 1
                              ] == service
                                ? "Submit"
                                : "Next"}
                            </button>
                          )}
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                    //End
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="TV">
              <div className="leads-step3-wraper">
                <div className="lead-step-form-card">
                  {/* desktop-view */}
                  {window.innerWidth > 767 ? (
                    <div className="row step3-leads-card desktop-viewdeals">
                      <div className="step3-leads-card_inner">
                        {deals?.TV?.length > 0 ? (
                          deals?.TV?.map((item, index) => (
                            <div key={index} className="col-12 mb-lg-4 mb-3">
                              <div className="card">
                                <div className="card-header">
                                  <h6>{"Plan " + (index + 1)}</h6>
                                  <p>
                                    99% available in{" "}
                                    {props?.serviceZipcode
                                      ? props?.serviceZipcode
                                      : "your area"}
                                  </p>
                                </div>
                                <div
                                  className={`card-body ${
                                    dealIds.includes(item?._id)
                                      ? "plan-selected"
                                      : ""
                                  }`}
                                >
                                  <div className="row align-items-xl-center">
                                    <div className="col-md-9">
                                      <div className="row">
                                        <div className="col-lg-3">
                                          {" "}
                                          <div className="deals-logo-wraper">
                                            <img
                                              src={`${IMAGE_URL}/uploads/images/${item?.dealProvider?.[0]?.logo}`}
                                              alt="img"
                                              className="img-fluid"
                                            />
                                          </div>
                                          <ul className="radio-list simple-radio-butn">
                                            <li className="ms-0">
                                              {/* <input
                                            type="radio"
                                            id={`termAndCondition${index}`}
                                            name={`termAndCondition${index}`}
                                            value={
                                              item?.termAndCondition ?? false
                                            }
                                            onChange={() => handleTerms(index)}
                                          /> */}
                                              <label
                                                htmlFor={`termAndCondition${index}`}
                                              >
                                                <TermsConditionStep3
                                                  data={item}
                                                />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                          <div className="serivce-box">
                                            <p className="font-bold dark-grey">
                                              Connection:
                                            </p>
                                            <p>
                                              {item?.connection
                                                ? item?.connection
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                          <div className="serivce-box">
                                            <p className="font-bold dark-grey">
                                              Download speeds up to:
                                            </p>
                                            <p>
                                              <span className="green-big-text">
                                                {item?.speed
                                                  ? item?.speed
                                                  : "-"}
                                              </span>
                                              Mbps
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                          <div className="serivce-box">
                                            <ul className="start-list">
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </li>
                                            </ul>
                                            {item?.reviewCount ? (
                                              <p>
                                                User Rating (
                                                {item?.reviewCount
                                                  ? item?.reviewCount
                                                  : "13"}
                                                )
                                              </p>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="service-data black-gradient">
                                        <div className="d-flex">
                                          <div className="d-flex">
                                            <h4>
                                              {" "}
                                              <sup>$</sup>
                                            </h4>
                                            <h1>
                                              {item?.acpDiscount &&
                                              item?.acpDiscountPrice !== "0"
                                                ? item?.planPrice &&
                                                  item?.acpDiscountPrice
                                                  ? (
                                                      item?.planPrice -
                                                      item?.acpDiscountPrice
                                                    )
                                                      ?.toString()
                                                      ?.split(".")[0]
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[0]
                                                : item?.planPrice
                                                ? item?.planPrice?.split(".")[0]
                                                : "0"}
                                            </h1>
                                          </div>
                                          <div className="d-flex flex-column ms-lg-3 ms-2">
                                            <h4>
                                              .
                                              {item?.acpDiscount &&
                                              item?.acpDiscountPrice !== "0"
                                                ? item?.planPrice &&
                                                  item?.acpDiscountPrice
                                                  ? (
                                                      item?.planPrice -
                                                      item?.acpDiscountPrice
                                                    )
                                                      ?.toFixed(2)
                                                      ?.split(".")[1]
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[1] == undefined
                                                  ? "00"
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[1]
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1] == undefined
                                                ? "00"
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1]}
                                              /mo
                                            </h4>
                                            <p>
                                              {item?.contractLength == "No" ||
                                              item?.contractLength == "NO" ||
                                              item?.contractLength == "no"
                                                ? "No contract required"
                                                : item?.contractLength}
                                            </p>
                                          </div>
                                        </div>
                                        {item?.acpDiscountPrice &&
                                        item?.acpDiscountPrice !== "0" ? (
                                          <div className="service-card-footer custom-toggle">
                                            <div className="toggle-heading">
                                              <p>Apply ACP Discount</p>
                                            </div>
                                            <input
                                              type="checkbox"
                                              className="toggle"
                                              name={`toggle${index}third`}
                                              id={`toggle${index}`}
                                              value={item?.acpDiscount}
                                              onClick={() =>
                                                handleACPDiscount(index, "TV")
                                              }
                                            />
                                            <label for={`toggle${index}third`}>
                                              <span className="on">Yes</span>
                                              <span className="off">No</span>
                                            </label>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex courtesy-msg-box-wraper  justify-content-between align-items-center">
                                    <div className="d-flex courtesy-msg-box device_with_plan">
                                      <div className="fency-check-radio-button">
                                        <div className="grid-wrapper grid-col-auto">
                                          <label
                                            for={`selectedDeal${index}fourth`}
                                            className="radio-card"
                                          >
                                            <input
                                              className="overflow-control-input"
                                              id={`selectedDeal${index}fourth`}
                                              name={`selectedDeal${index}fourth`}
                                              type="radio"
                                              checked={dealIds.includes(
                                                item?._id
                                              )}
                                              onChange={() =>
                                                handleDealSelects(
                                                  item?._id,
                                                  item,
                                                  "TV",
                                                  index
                                                )
                                              }
                                            />
                                            <div className="card-content-wrapper">
                                              {/* <span className="check-icon"></span> */}
                                              {props?.data?.preferredDevice ==
                                              "Mobile" ? (
                                                <div className="card-content">
                                                  <div className="small-device-wraper">
                                                    <img
                                                      src={Images.smallMobile}
                                                      alt="img"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <small className="green-clr">
                                                    Free Smart Phone
                                                  </small>
                                                </div>
                                              ) : (
                                                <div className="card-content">
                                                  <div className="small-device-wraper">
                                                    <img
                                                      src={Images.smallTablet}
                                                      alt="img"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <small className="green-clr">
                                                    Free Tablet
                                                  </small>
                                                </div>
                                              )}
                                            </div>
                                          </label>
                                          <div className="d-flex align-items-center">
                                            <small className="d-flex">
                                              Courtesy of{" "}
                                              <img
                                                src={Images.comlinklogo}
                                                alt="img"
                                                className="img-fluid ms-1 me-1 h-20"
                                              />
                                              with installed internet
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-xxl-3 mt-1">
                                      <ul className="radio-list simple-radio-butn">
                                        {item.installationOptions !== " " ? (
                                          <>
                                            <li>
                                              <input
                                                type="radio"
                                                id={`fifth${index}`}
                                                name={`installationOptions${index}`}
                                                checked={
                                                  item.installationOptions ===
                                                  "Self Installation"
                                                }
                                                onChange={() =>
                                                  handleInstallmentOption(
                                                    index,
                                                    "Self Installation",
                                                    "TV"
                                                  )
                                                }
                                              />
                                              <label htmlFor={`fifth${index}`}>
                                                Self Installation
                                              </label>
                                            </li>
                                            <li>
                                              <input
                                                type="radio"
                                                id={`sixth${index}`}
                                                name={`installationOptions${index}`}
                                                checked={
                                                  item.installationOptions ===
                                                  "Professional Installation"
                                                }
                                                onChange={() =>
                                                  handleInstallmentOption(
                                                    index,
                                                    "Professional Installation",
                                                    "TV"
                                                  )
                                                }
                                              />
                                              <label htmlFor={`sixth${index}`}>
                                                Professional Installation
                                              </label>
                                            </li>
                                          </>
                                        ) : null}
                                        <li>
                                          <div className="selct-plan-check-box">
                                            <label
                                              for={`selectedDeal${index}sixth2`}
                                              className="custom-control overflow-checkbox relative"
                                            >
                                              <input
                                                className="overflow-control-input"
                                                id={`selectedDeal${index}sixth2`}
                                                name={`selectedDeal${index}sixth2`}
                                                type="checkbox"
                                                checked={dealIds.includes(
                                                  item?._id
                                                )}
                                                onChange={() =>
                                                  handleDealSelects(
                                                    item?._id,
                                                    item,
                                                    "TV",
                                                    index
                                                  )
                                                }
                                              />
                                              <span className="overflow-control-indicator"></span>
                                              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                                                <p className="Submitting_form green-clr font-medium">
                                                  Select Plan
                                                </p>
                                              </span>
                                            </label>
                                          </div>
                                        </li>
                                        {/* <li>
                                          <button className="btn green-rounded-btn submit-order-btn">
                                            Submit Order
                                          </button>
                                        </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-deal-found">
                            <div className="no-deal-image">
                              <img
                                src={Images.noDeal}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <h6 className="text-center">
                              No Deal available in your area
                            </h6>
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-start mt-lg-4 mt-3">
                          <button
                            className="btn theme-btn"
                            onClick={() => {
                              props.previousStep();
                            }}
                          >
                            Back
                          </button>
                          {/* <button className="btn theme-btn ms-sm-3">Next</button> */}
                        </div>
                        {/* {deals?.length > 0 ? ( */}
                        <div className="d-flex mt-lg-0 mt-3 justify-content-end align-items-center">
                          {loader2 ? (
                            <button disabled className="btn theme-btn d-flex ">
                              <span className="ml_9"> Loading...</span>
                              <div className="spinner"></div>
                            </button>
                          ) : (
                            <button
                              onClick={() => addDeals()}
                              className="btn green-filled-btn"
                            >
                              {props?.data?.type?.[
                                props?.data?.type?.length - 1
                              ] == service
                                ? "Submit"
                                : "Next"}
                            </button>
                          )}
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                  ) : (
                    <div className="row step3-leads-card mobile-viewdeals">
                      <div className="step3-leads-card_inner">
                        {deals?.TV?.length > 0 ? (
                          deals?.TV?.map((item, index) => (
                            <div key={index} className="col-12 mb-lg-4 mb-4">
                              <div className="card">
                                <div className="card-header">
                                  <h6>{"Plan " + (index + 1)}</h6>
                                  {/* <p>
                                    99% available in{" "}
                                    {props?.serviceZipcode
                                      ? props?.serviceZipcode
                                      : "your area"}
                                  </p> */}
                                </div>
                                <div
                                  className={`card-body ${
                                    dealIds.includes(item?._id)
                                      ? "plan-selected"
                                      : ""
                                  }`}
                                >
                                  <div className="row align-items-xl-center packges-name-row">
                                    <div className="col-6">
                                      <div className="d-flex flex-sm-column flex-row justify-content-center">
                                        <div className="deals-logo-wraper">
                                          <img
                                            src={`${IMAGE_URL}/uploads/images/${item?.dealProvider?.[0]?.logo}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="serivce-box">
                                        <ul className="start-list">
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                          <li>
                                            <FontAwesomeIcon icon={faStar} />
                                          </li>
                                        </ul>
                                        {item?.reviewCount ? (
                                          <p className="text-center mb-lg-3 mb-1">
                                            User Rating (
                                            {item?.reviewCount
                                              ? item?.reviewCount
                                              : "13"}
                                            )
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="service-data black-gradient">
                                        <div className="d-flex service-top-price">
                                          <div className="d-flex">
                                            <h4>
                                              <sup>$</sup>
                                            </h4>
                                            <h1>
                                              <h1>
                                                {item?.acpDiscount &&
                                                item?.acpDiscountPrice !== "0"
                                                  ? item?.planPrice &&
                                                    item?.acpDiscountPrice
                                                    ? (
                                                        item?.planPrice -
                                                        item?.acpDiscountPrice
                                                      )
                                                        ?.toString()
                                                        ?.split(".")[0]
                                                    : item?.planPrice?.split(
                                                        "."
                                                      )[0]
                                                  : item?.planPrice
                                                  ? item?.planPrice?.split(
                                                      "."
                                                    )[0]
                                                  : "0"}
                                              </h1>
                                            </h1>
                                          </div>
                                          <div className="d-flex flex-column ms-lg-3 ms-2">
                                            <h4>
                                              .
                                              {item?.acpDiscount &&
                                              item?.acpDiscountPrice !== "0"
                                                ? item?.planPrice &&
                                                  item?.acpDiscountPrice
                                                  ? (
                                                      item?.planPrice -
                                                      item?.acpDiscountPrice
                                                    )
                                                      ?.toFixed(2)
                                                      ?.split(".")[1]
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[1] == undefined
                                                  ? "00"
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[1]
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1] == undefined
                                                ? "00"
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1]}
                                              /mo
                                            </h4>
                                            <p>
                                              {item?.contractLength == "No" ||
                                              item?.contractLength == "NO" ||
                                              item?.contractLength == "no"
                                                ? "No contract required"
                                                : item?.contractLength}
                                            </p>
                                          </div>
                                        </div>
                                        {item?.acpDiscountPrice &&
                                        item?.acpDiscountPrice !== "0" ? (
                                          <div className="service-card-footer custom-toggle">
                                            <div className="toggle-heading">
                                              <p>Apply ACP Discount</p>
                                            </div>
                                            <input
                                              type="checkbox"
                                              className="toggle"
                                              name={`toggle${index}second`}
                                              id={`toggle${index}second`}
                                              value={item?.acpDiscount}
                                              onClick={() =>
                                                handleACPDiscount(index, "TV")
                                              }
                                            />
                                            <label for={`toggle${index}second`}>
                                              <span className="on">Yes</span>
                                              <span className="off">No</span>
                                            </label>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <ul className="radio-list simple-radio-butn mt-lg-3 mt-2">
                                      <li className="me-0 align-items-center">
                                        <label
                                          htmlFor={`termAndCondition${index}`}
                                        >
                                          <TermsConditionStep3 data={item} />
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="row packg-detail-row mt-lg-4 mt-1">
                                    <div className="col-12">
                                      <hr className="m-0" />
                                    </div>
                                    <div className="col-6">
                                      <div className="serivce-box">
                                        <p className="font-bold dark-grey">
                                          Connection:
                                        </p>
                                        <p>
                                          {item?.connection
                                            ? item?.connection
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="serivce-box">
                                        <p className="font-bold dark-grey">
                                          Download speed:
                                        </p>
                                        <p>
                                          <span className="green-big-text">
                                            {item?.speed ? item?.speed : "-"}
                                          </span>
                                          Mbps
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex courtesy-msg-box-wraper justify-content-between flex-md-row flex-column align-items-sm-center">
                                    <div className="d-flex courtesy-msg-box device_with_plan">
                                      <div className="fency-check-radio-button">
                                        <div className="grid-wrapper grid-col-auto">
                                          <label
                                            for="radio-card-3"
                                            className="radio-card"
                                          >
                                            <input
                                              type="radio"
                                              name="preferredDevice"
                                              id="radio-card-3"
                                            />
                                            <div className="card-content-wrapper">
                                              {/* <span className="check-icon"></span> */}
                                              {props?.data?.preferredDevice ==
                                              "Mobile" ? (
                                                <div className="card-content">
                                                  <div className="small-device-wraper">
                                                    <img
                                                      src={Images.smallMobile}
                                                      alt="img"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <small className="green-clr">
                                                    Free Smart Phone
                                                  </small>
                                                </div>
                                              ) : (
                                                <div className="card-content">
                                                  <div className="small-device-wraper">
                                                    <img
                                                      src={Images.smallTablet}
                                                      alt="img"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                  <small className="green-clr">
                                                    Free Tablet
                                                  </small>
                                                </div>
                                              )}
                                            </div>
                                          </label>
                                        </div>
                                        <div className="d-flex align-items-center courtesy-msg-box-wraper">
                                          <small className="courtesy-msg-box">
                                            Courtesy of
                                            <img
                                              src={Images.comlinklogo}
                                              alt="img"
                                              className="img-fluid ms-1 me-1 h-20"
                                            />
                                            with installed internet
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-lg-3 mt-1">
                                      <ul className="radio-list simple-radio-butn step3-select-plan">
                                        <li className="w-100 justify-content-center mb-lg-3 mb-2 mt-lg-3 mt-1 flex-width">
                                          <div className="selct-plan-check-box mb-3 mt-3 ">
                                            <label
                                              for={`selectedDeal${index}third7788`}
                                              className="custom-control overflow-checkbox relative"
                                            >
                                              <input
                                                className="overflow-control-input"
                                                id={`selectedDeal${index}third7788`}
                                                name={`selectedDeal${index}third7788`}
                                                type="checkbox"
                                                checked={dealIds.includes(
                                                  item?._id
                                                )}
                                                onChange={() =>
                                                  handleDealSelects(
                                                    item?._id,
                                                    item,
                                                    "TV",
                                                    index
                                                  )
                                                }
                                              />
                                              <span className="overflow-control-indicator "></span>
                                              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                                                <p className="Submitting_form green-clr font-medium">
                                                  Select Plan
                                                </p>
                                              </span>
                                            </label>
                                          </div>
                                        </li>
                                        {item.installationOptions !== " " ? (
                                          <>
                                            {" "}
                                            <li className="">
                                              <input
                                                type="radio"
                                                id={`third${index}`}
                                                name={`installationOptions${index}`}
                                                checked={
                                                  item.installationOptions ===
                                                  "Self Installation"
                                                }
                                                onChange={() =>
                                                  handleInstallmentOption(
                                                    index,
                                                    "Self Installation",
                                                    "TV"
                                                  )
                                                }
                                              />
                                              <label for={`third${index}`}>
                                                {" "}
                                                Self Installation
                                              </label>
                                            </li>
                                            <li className="">
                                              <input
                                                type="radio"
                                                id={`fourth${index}`}
                                                name={`installationOptions${index}`}
                                                checked={
                                                  item.installationOptions ===
                                                  "Professional Installation"
                                                }
                                                onChange={() =>
                                                  handleInstallmentOption(
                                                    index,
                                                    "Professional Installation",
                                                    "TV"
                                                  )
                                                }
                                              />
                                              <label for={`fourth${index}`}>
                                                Professional Installation
                                              </label>
                                            </li>
                                          </>
                                        ) : null}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-deal-found mobile-no-record">
                            <div className="no-deal-image">
                              <img
                                src={Images.noDeal}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <h6 className="text-center">
                              {" "}
                              No Deal available in your area
                            </h6>
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content-start mt-lg-4 mt-3">
                          <button
                            className="link---btn"
                            onClick={() => {
                              props.previousStep();
                            }}
                          >
                            <span>
                              <FontAwesomeIcon icon={faChevronLeft} />
                            </span>
                            Back
                          </button>
                          {/* <button className="btn theme-btn ms-sm-3">Next</button> */}
                        </div>
                        {/* {deals?.length > 0 ? ( */}
                        <div className="d-flex mt-lg-0 mt-3 justify-content-end align-items-center">
                          {loader2 ? (
                            <button disabled className="btn theme-btn d-flex ">
                              <span className="ml_9"> Loading...</span>
                              <div className="spinner"></div>
                            </button>
                          ) : (
                            <button
                              onClick={() => addDeals()}
                              className="btn green-filled-btn"
                            >
                              {props?.data?.type?.[
                                props?.data?.type?.length - 1
                              ] == service
                                ? "Submit"
                                : "Next"}
                            </button>
                          )}
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="Phone">
              <div className="lead-step-form-card">
                {/* desktop-view */}
                {window.innerWidth > 767 ? (
                  <div className="row step3-leads-card desktop-viewdeals">
                    <div className="step3-leads-card_inner">
                      {deals?.Phone?.length > 0 ? (
                        deals?.Phone?.map((item, index) => (
                          <div key={index} className="col-12 mb-lg-4 mb-3">
                            <div className="card">
                              <div className="card-header">
                                <h6>{"Plan " + (index + 1)}</h6>
                                <p>
                                  99% available in{" "}
                                  {props?.serviceZipcode
                                    ? props?.serviceZipcode
                                    : "your area"}
                                </p>
                              </div>
                              <div
                                className={`card-body ${
                                  dealIds.includes(item?._id)
                                    ? "plan-selected"
                                    : ""
                                }`}
                              >
                                <div className="row align-items-xl-center">
                                  <div className="col-md-9">
                                    <div className="row">
                                      <div className="col-xl-3">
                                        <div className="deals-logo-wraper">
                                          <img
                                            src={`${IMAGE_URL}/uploads/images/${item?.dealProvider?.[0]?.logo}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <ul className="radio-list simple-radio-butn">
                                          <li className="ms-0">
                                            {/* <input
                                            type="radio"
                                            id={`termAndCondition${index}`}
                                            name={`termAndCondition${index}`}
                                            value={
                                              item?.termAndCondition ?? false
                                            }
                                            onChange={() => handleTerms(index)}
                                          /> */}
                                            <label
                                              htmlFor={`termAndCondition${index}`}
                                            >
                                              <TermsConditionStep3
                                                data={item}
                                              />
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="serivce-box">
                                          <p className="font-bold dark-grey">
                                            Connection:
                                          </p>
                                          <p>
                                            {item?.connection
                                              ? item?.connection
                                              : "-"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="serivce-box">
                                          <p className="font-bold dark-grey">
                                            Download speeds up to:
                                          </p>
                                          <p>
                                            <span className="green-big-text">
                                              {item?.speed ? item?.speed : "-"}
                                            </span>
                                            Mbps
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="serivce-box">
                                          <ul className="start-list">
                                            <li>
                                              <FontAwesomeIcon icon={faStar} />
                                            </li>
                                            <li>
                                              <FontAwesomeIcon icon={faStar} />
                                            </li>
                                            <li>
                                              <FontAwesomeIcon icon={faStar} />
                                            </li>
                                            <li>
                                              <FontAwesomeIcon icon={faStar} />
                                            </li>
                                            <li>
                                              <FontAwesomeIcon icon={faStar} />
                                            </li>
                                          </ul>
                                          {item?.reviewCount ? (
                                            <p>
                                              User Rating (
                                              {item?.reviewCount
                                                ? item?.reviewCount
                                                : "13"}
                                              )
                                            </p>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="service-data black-gradient">
                                      <div className="d-flex pkg-amount-box">
                                        <div className="d-flex">
                                          <h4>
                                            <sup>$</sup>
                                          </h4>
                                          <h1>
                                            {item?.acpDiscount &&
                                            item?.acpDiscountPrice !== "0"
                                              ? item?.planPrice &&
                                                item?.acpDiscountPrice
                                                ? (
                                                    item?.planPrice -
                                                    item?.acpDiscountPrice
                                                  )
                                                    ?.toString()
                                                    ?.split(".")[0]
                                                : item?.planPrice?.split(".")[0]
                                              : item?.planPrice
                                              ? item?.planPrice?.split(".")[0]
                                              : "0"}
                                          </h1>
                                        </div>
                                        <div className="d-flex flex-column ms-lg-3 ms-2">
                                          <h4>
                                            .
                                            {item?.acpDiscount &&
                                            item?.acpDiscountPrice !== "0"
                                              ? item?.planPrice &&
                                                item?.acpDiscountPrice
                                                ? (
                                                    item?.planPrice -
                                                    item?.acpDiscountPrice
                                                  )
                                                    ?.toFixed(2)
                                                    ?.split(".")[1]
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1] == undefined
                                                ? "00"
                                                : item?.planPrice?.split(".")[1]
                                              : item?.planPrice?.split(
                                                  "."
                                                )[1] == undefined
                                              ? "00"
                                              : item?.planPrice?.split(".")[1]}
                                            /mo
                                          </h4>
                                          <p>
                                            {item?.contractLength == "No" ||
                                            item?.contractLength == "NO" ||
                                            item?.contractLength == "no"
                                              ? "No contract required"
                                              : item?.contractLength}
                                          </p>
                                        </div>
                                      </div>
                                      {item?.acpDiscountPrice &&
                                      item?.acpDiscountPrice !== "0" ? (
                                        <div className="service-card-footer custom-toggle">
                                          <div className="toggle-heading">
                                            <p>Apply ACP Discount</p>
                                          </div>
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            name={`toggle${index}fifth`}
                                            id={`toggle${index}`}
                                            value={item?.acpDiscount}
                                            onClick={() =>
                                              handleACPDiscount(index, "Phone")
                                            }
                                          />
                                          <label for={`toggle${index}fifth`}>
                                            <span className="on">Yes</span>
                                            <span className="off">No</span>
                                          </label>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex courtesy-msg-box-wraper  justify-content-between align-items-center">
                                  <div className="d-flex courtesy-msg-box device_with_plan">
                                    <div className="fency-check-radio-button">
                                      <div className="grid-wrapper grid-col-auto">
                                        <label
                                          for={`selectedDeal${index}ninth31`}
                                          className="radio-card"
                                        >
                                          <input
                                            className="overflow-control-input"
                                            id={`selectedDeal${index}ninth31`}
                                            name={`selectedDeal${index}ninth31`}
                                            type="radio"
                                            checked={dealIds.includes(
                                              item?._id
                                            )}
                                            onChange={() =>
                                              handleDealSelects(
                                                item?._id,
                                                item,
                                                "Phone",
                                                index
                                              )
                                            }
                                          />
                                          <div className="card-content-wrapper">
                                            {/* <span className="check-icon"></span> */}
                                            {props?.data?.preferredDevice ==
                                            "Mobile" ? (
                                              <div className="card-content">
                                                <div className="small-device-wraper">
                                                  <img
                                                    src={Images.smallMobile}
                                                    alt="img"
                                                    className="img-fluid"
                                                  />
                                                </div>
                                                <small className="green-clr">
                                                  Free Smart Phone
                                                </small>
                                              </div>
                                            ) : (
                                              <div className="card-content">
                                                <div className="small-device-wraper">
                                                  <img
                                                    src={Images.smallTablet}
                                                    alt="img"
                                                    className="img-fluid"
                                                  />
                                                </div>
                                                <small className="green-clr">
                                                  Free Tablet
                                                </small>
                                              </div>
                                            )}
                                          </div>
                                        </label>
                                        <div className="d-flex align-items-center">
                                          <small className="d-flex">
                                            Courtesy of{" "}
                                            <img
                                              src={Images.comlinklogo}
                                              alt="img"
                                              className="img-fluid ms-1 me-1 h-20"
                                            />
                                            with installed internet
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-end mt-xxl-3 mt-1">
                                    <ul className="radio-list simple-radio-butn">
                                      {item.installationOptions !== " " ? (
                                        <>
                                          <li>
                                            <input
                                              type="radio"
                                              id={`nineth${index}`}
                                              name={`installationOptions${index}`}
                                              checked={
                                                item.installationOptions ===
                                                "Self Installation"
                                              }
                                              onChange={() =>
                                                handleInstallmentOption(
                                                  index,
                                                  "Self Installation",
                                                  "Phone"
                                                )
                                              }
                                            />
                                            <label htmlFor={`nineth${index}`}>
                                              Self Installation
                                            </label>
                                          </li>
                                          <li>
                                            <input
                                              type="radio"
                                              id={`ten${index}`}
                                              name={`installationOptions${index}`}
                                              checked={
                                                item.installationOptions ===
                                                "Professional Installation"
                                              }
                                              onChange={() =>
                                                handleInstallmentOption(
                                                  index,
                                                  "Professional Installation",
                                                  "Phone"
                                                )
                                              }
                                            />
                                            <label htmlFor={`ten${index}`}>
                                              Professional Installation
                                            </label>
                                          </li>
                                        </>
                                      ) : null}
                                      <li>
                                        <div className="selct-plan-check-box">
                                          <label
                                            for={`selectedDeal${index}tenth6`}
                                            className="custom-control overflow-checkbox relative"
                                          >
                                            <input
                                              className="overflow-control-input"
                                              id={`selectedDeal${index}tenth6`}
                                              name={`selectedDeal${index}tenth6`}
                                              type="checkbox"
                                              checked={dealIds.includes(
                                                item?._id
                                              )}
                                              onChange={() =>
                                                handleDealSelects(
                                                  item?._id,
                                                  item,
                                                  "Phone",
                                                  index
                                                )
                                              }
                                            />
                                            <span className="overflow-control-indicator"></span>
                                            <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                                              <p className="Submitting_form green-clr font-medium">
                                                Select Plan
                                              </p>
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      {/* <li>
                                <button className="btn green-rounded-btn submit-order-btn">
                                  Submit Order
                                </button>
                              </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-deal-found">
                          <div className="no-deal-image">
                            <img
                              src={Images.noDeal}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                          <h6 className="text-center">
                            No Deal available in your area
                          </h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-start mt-lg-4 mt-3">
                        <button
                          className="btn theme-btn"
                          onClick={() => {
                            props.previousStep();
                          }}
                        >
                          Back
                        </button>
                        {/* <button className="btn theme-btn ms-sm-3">Next</button> */}
                      </div>
                      {/* {deals?.length > 0 ? ( */}
                      <div className="d-flex mt-lg-0 mt-3 justify-content-end align-items-center">
                        {loader2 ? (
                          <button disabled className="btn theme-btn d-flex ">
                            <span className="ml_9"> Loading...</span>
                            <div className="spinner"></div>
                          </button>
                        ) : (
                          <button
                            onClick={() => addDeals()}
                            className="btn green-filled-btn"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                      {/* ) : null} */}
                    </div>
                  </div>
                ) : (
                  <div className="row step3-leads-card mobile-viewdeals">
                    <div className="step3-leads-card_inner">
                      {deals?.Phone?.length > 0 ? (
                        deals?.Phone?.map((item, index) => (
                          <div key={index} className="col-12 mb-lg-4 mb-4">
                            <div className="card">
                              <div className="card-header">
                                <h6>{"Plan " + (index + 1)}</h6>
                                {/* <p>
                                    99% available in{" "}
                                    {props?.serviceZipcode
                                      ? props?.serviceZipcode
                                      : "your area"}
                                  </p> */}
                              </div>
                              <div
                                className={`card-body ${
                                  dealIds.includes(item?._id)
                                    ? "plan-selected"
                                    : ""
                                }`}
                              >
                                <div className="row align-items-xl-center packges-name-row">
                                  <div className="col-6">
                                    <div className="d-flex flex-sm-column flex-row justify-content-center">
                                      <div className="deals-logo-wraper">
                                        <img
                                          src={`${IMAGE_URL}/uploads/images/${item?.dealProvider?.[0]?.logo}`}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="serivce-box">
                                      <ul className="start-list">
                                        <li>
                                          <FontAwesomeIcon icon={faStar} />
                                        </li>
                                        <li>
                                          <FontAwesomeIcon icon={faStar} />
                                        </li>
                                        <li>
                                          <FontAwesomeIcon icon={faStar} />
                                        </li>
                                        <li>
                                          <FontAwesomeIcon icon={faStar} />
                                        </li>
                                        <li>
                                          <FontAwesomeIcon icon={faStar} />
                                        </li>
                                      </ul>
                                      {item?.reviewCount ? (
                                        <p className="text-center mb-lg-3 mb-1">
                                          User Rating (
                                          {item?.reviewCount
                                            ? item?.reviewCount
                                            : "13"}
                                          )
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="service-data black-gradient">
                                      <div className="d-flex service-top-price">
                                        <div className="d-flex">
                                          <h4>
                                            <sup>$</sup>
                                          </h4>
                                          <h1>
                                            <h1>
                                              {item?.acpDiscount &&
                                              item?.acpDiscountPrice !== "0"
                                                ? item?.planPrice &&
                                                  item?.acpDiscountPrice
                                                  ? (
                                                      item?.planPrice -
                                                      item?.acpDiscountPrice
                                                    )
                                                      ?.toString()
                                                      ?.split(".")[0]
                                                  : item?.planPrice?.split(
                                                      "."
                                                    )[0]
                                                : item?.planPrice
                                                ? item?.planPrice?.split(".")[0]
                                                : "0"}
                                            </h1>
                                          </h1>
                                        </div>
                                        <div className="d-flex flex-column ms-lg-3 ms-2">
                                          <h4>
                                            .
                                            {item?.acpDiscount &&
                                            item?.acpDiscountPrice !== "0"
                                              ? item?.planPrice &&
                                                item?.acpDiscountPrice
                                                ? (
                                                    item?.planPrice -
                                                    item?.acpDiscountPrice
                                                  )
                                                    ?.toFixed(2)
                                                    ?.split(".")[1]
                                                : item?.planPrice?.split(
                                                    "."
                                                  )[1] == undefined
                                                ? "00"
                                                : item?.planPrice?.split(".")[1]
                                              : item?.planPrice?.split(
                                                  "."
                                                )[1] == undefined
                                              ? "00"
                                              : item?.planPrice?.split(".")[1]}
                                            /mo
                                          </h4>
                                          <p>
                                            {item?.contractLength == "No" ||
                                            item?.contractLength == "NO" ||
                                            item?.contractLength == "no"
                                              ? "No contract required"
                                              : item?.contractLength}
                                          </p>
                                        </div>
                                      </div>
                                      {item?.acpDiscountPrice &&
                                      item?.acpDiscountPrice !== "0" ? (
                                        <div className="service-card-footer custom-toggle">
                                          <div className="toggle-heading">
                                            <p>Apply ACP Discount</p>
                                          </div>
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            name={`toggle${index}second`}
                                            id={`toggle${index}second`}
                                            value={item?.acpDiscount}
                                            onClick={() =>
                                              handleACPDiscount(index, "Phone")
                                            }
                                          />
                                          <label for={`toggle${index}second`}>
                                            <span className="on">Yes</span>
                                            <span className="off">No</span>
                                          </label>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <ul className="radio-list simple-radio-butn mt-lg-3 mt-2">
                                    <li className="me-0 align-items-center">
                                      <label
                                        htmlFor={`termAndCondition${index}`}
                                      >
                                        <TermsConditionStep3 data={item} />
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                                <div className="row packg-detail-row mt-lg-4 mt-1">
                                  <div className="col-12">
                                    <hr className="m-0" />
                                  </div>
                                  <div className="col-6">
                                    <div className="serivce-box">
                                      <p className="font-bold dark-grey">
                                        Connection:
                                      </p>
                                      <p>
                                        {item?.connection
                                          ? item?.connection
                                          : "-"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="serivce-box">
                                      <p className="font-bold dark-grey">
                                        Download speed:
                                      </p>
                                      <p>
                                        <span className="green-big-text">
                                          {item?.speed ? item?.speed : "-"}
                                        </span>
                                        Mbps
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex courtesy-msg-box-wraper justify-content-between flex-md-row flex-column align-items-sm-center">
                                  <div className="d-flex courtesy-msg-box device_with_plan">
                                    <div className="fency-check-radio-button">
                                      <div className="grid-wrapper grid-col-auto">
                                        <label
                                          for="radio-card-3"
                                          className="radio-card"
                                        >
                                          <input
                                            type="radio"
                                            name="preferredDevice"
                                            id="radio-card-3"
                                          />
                                          <div className="card-content-wrapper">
                                            {/* <span className="check-icon"></span> */}
                                            {props?.data?.preferredDevice ==
                                            "Mobile" ? (
                                              <div className="card-content">
                                                <div className="small-device-wraper">
                                                  <img
                                                    src={Images.smallMobile}
                                                    alt="img"
                                                    className="img-fluid"
                                                  />
                                                </div>
                                                <small className="green-clr">
                                                  Free Smart Phone
                                                </small>
                                              </div>
                                            ) : (
                                              <div className="card-content">
                                                <div className="small-device-wraper">
                                                  <img
                                                    src={Images.smallTablet}
                                                    alt="img"
                                                    className="img-fluid"
                                                  />
                                                </div>
                                                <small className="green-clr">
                                                  Free Tablet
                                                </small>
                                              </div>
                                            )}
                                          </div>
                                        </label>
                                      </div>
                                      <div className="d-flex align-items-center courtesy-msg-box-wraper">
                                        <small className="courtesy-msg-box">
                                          Courtesy of
                                          <img
                                            src={Images.comlinklogo}
                                            alt="img"
                                            className="img-fluid ms-1 me-1 h-20"
                                          />
                                          with installed internet
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-center mt-lg-3 mt-1">
                                    <ul className="radio-list simple-radio-butn step3-select-plan">
                                      <li className="w-100 justify-content-center mb-lg-3 mb-2 mt-lg-3 mt-1 flex-width">
                                        <div className="selct-plan-check-box mb-3 mt-3 ">
                                          <label
                                            for={`selectedDeal${index}third7799`}
                                            className="custom-control overflow-checkbox relative"
                                          >
                                            <input
                                              className="overflow-control-input"
                                              id={`selectedDeal${index}third7799`}
                                              name={`selectedDeal${index}third7799`}
                                              type="checkbox"
                                              checked={dealIds.includes(
                                                item?._id
                                              )}
                                              onChange={() =>
                                                handleDealSelects(
                                                  item?._id,
                                                  item,
                                                  "Phone",
                                                  index
                                                )
                                              }
                                            />
                                            <span className="overflow-control-indicator "></span>
                                            <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                                              <p className="Submitting_form green-clr font-medium">
                                                Select Plan
                                              </p>
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      {item.installationOptions !== " " ? (
                                        <>
                                          {" "}
                                          <li className="">
                                            <input
                                              type="radio"
                                              id={`third${index}`}
                                              name={`installationOptions${index}`}
                                              checked={
                                                item.installationOptions ===
                                                "Self Installation"
                                              }
                                              onChange={() =>
                                                handleInstallmentOption(
                                                  index,
                                                  "Self Installation",
                                                  "Phone"
                                                )
                                              }
                                            />
                                            <label for={`third${index}`}>
                                              {" "}
                                              Self Installation
                                            </label>
                                          </li>
                                          <li className="">
                                            <input
                                              type="radio"
                                              id={`fourth${index}`}
                                              name={`installationOptions${index}`}
                                              checked={
                                                item.installationOptions ===
                                                "Professional Installation"
                                              }
                                              onChange={() =>
                                                handleInstallmentOption(
                                                  index,
                                                  "Professional Installation",
                                                  "Phone"
                                                )
                                              }
                                            />
                                            <label for={`fourth${index}`}>
                                              Professional Installation
                                            </label>
                                          </li>
                                        </>
                                      ) : null}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-deal-found mobile-no-record">
                          <div className="no-deal-image">
                            <img
                              src={Images.noDeal}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                          <h6 className="text-center">
                            {" "}
                            No Deal available in your area
                          </h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="d-flex justify-content-start mt-lg-4 mt-3">
                        <button
                          className="link---btn"
                          onClick={() => {
                            props.previousStep();
                          }}
                        >
                          <span>
                            <FontAwesomeIcon icon={faChevronLeft} />
                          </span>
                          Back
                        </button>
                        {/* <button className="btn theme-btn ms-sm-3">Next</button> */}
                      </div>
                      {/* {deals?.length > 0 ? ( */}
                      <div className="d-flex mt-lg-0 mt-3 justify-content-end align-items-center">
                        {loader2 ? (
                          <button disabled className="btn theme-btn d-flex ">
                            <span className="ml_9"> Loading...</span>
                            <div className="spinner"></div>
                          </button>
                        ) : (
                          <button
                            onClick={() => addDeals()}
                            className="btn green-filled-btn"
                          >
                            {props?.data?.type?.[
                              props?.data?.type?.length - 1
                            ] == service
                              ? "Submit"
                              : "Next"}
                          </button>
                        )}
                      </div>
                      {/* ) : null} */}
                    </div>
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default Deals;
