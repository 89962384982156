import React, { useEffect, useState, useRef } from "react";
import { Images } from "../../../../assets/assets";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import LeadsTermsModal from "./LeadsTermsModal";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};

const initialValue = {
  preferredDevice: "",
  firstname: "",
  middleName: "",
  lastname: "",
  suffix: "",
  prefix: "",
  ssn: "",
  phone: "",
  day: "",
  month: "",
  year: "",
  bestWayToReach: "",
  // homeAddress: "",
  // serviceAddress: "",
  // serviceAddress2: "",
  // serviceZipcode: "",
  // serviceCity: "",
  // serviceState: "",
  // shippingAddress: "",
  // shippingAddress2: "",
  // shippingZipcode: "",
  // shippingCity: "",
  // shippingState: "",
  // sameAsService: false,
  agreement: false,
};

const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const validationSchema = Yup.object().shape({
  // preferredDevice: Yup.string().required("Preferred device is required"),
  // firstname: Yup.string().required("First name is required"),
  // middleName: Yup.string(),
  // lastname: Yup.string().required("Last name is required"),
  suffix: Yup.string(),
  // prefix: Yup.string().required("Prefix is required"),
  ssn: Yup.string()
  .required("SSN is required")
  .matches(/^[^_]*$/, "SSN must be exactly 10 digits")
  .length(11, "SSN must be exactly 10 digits"),
  // phone: Yup.string()
  //   .required("Phone number is required")
  //   .matches(/^[^_]*$/, "Phone number must be exactly 10 digits")
  //   .length(14, "Phone number must be exactly 10 digits"),
  day: Yup.number()
    .required("Please select a day")
    .min(1, "Day must be between 1 and 31")
    .test(
      "is-valid-day",
      "Invalid day for selected month and year",
      function (value) {
        const month = this.parent.month;
        const year = this.parent.year;

        if (!value || !month || !year) {
          return true; // Let Yup.string().nullable() handle null values
        }

        if (month === 2) {
          // February
          if (isLeapYear(year)) {
            return Yup.number().min(1).max(29).isValidSync(value);
          } else {
            return Yup.number().min(1).max(28).isValidSync(value);
          }
        } else if ([4, 6, 9, 11].includes(month)) {
          // Months with 30 days
          return Yup.number().min(1).max(30).isValidSync(value);
        } else {
          // Months with 31 days
          return Yup.number().min(1).max(31).isValidSync(value);
        }
      }
    ),
  month: Yup.number()
    .required("Please select a month")
    .min(1, "Month must be between 1 and 12")
    .max(12, "Month must be between 1 and 12"),
  year: Yup.number()
    .required("Please select a year")
    .min(1925, "Year must be between 1925 and current year")
    .max(
      new Date().getFullYear(),
      "Year must be between 1925 and current year"
    ),
  bestWayToReach: Yup.string().required("Please select a way"),
  // homeAddress: Yup.string().required("Home address is required"),
  // serviceAddress: Yup.string().required("Service address is required"),
  // serviceAddress2: Yup.string(),
  // serviceZipcode: Yup.string()
  //   .required("Zipcode is required")
  //   .matches(/^[^_]*$/, "Zipcode must be numbers only")
  //   .length(5, "Zipcode must be exactly 5 digits"),
  // serviceCity: Yup.string().required("Service city is required"),
  // serviceState: Yup.string().required("Service state is required"),
  // shippingAddress: Yup.string().required("Shipping address is required"),
  // shippingAddress2: Yup.string(),
  // shippingZipcode: Yup.string()
  //   .required("Zipcode is required")
  //   .matches(/^[^_]*$/, "Zipcode must be numbers only")
  //   .length(5, "Zipcode must be exactly 5 digits"),
  // shippingCity: Yup.string().required("Shipping city is required"),
  // shippingState: Yup.string().required("Shipping state is required"),
  // sameAsService: Yup.boolean(),
  agreement: Yup.boolean()
    .oneOf([true], "You must agree to the agreement")
    .required("Agreement is required"),
});

const apiUrl = `${API_URL}/leads/update`;

const months = [
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sep" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" },
];

const LeadDetailCapture = (props) => {
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [services, setServices] = useState(['Internet']);
  const [disabled, setDisabled] = useState(false);
  const [sameAsService, setSameAsService] = useState(false);
  const [sameAsServiceDisabled, setSameAsServiceDisabled] = useState(false);
  const [serviceError, setServiceError] = useState("");
  const formRef = useRef();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.data.zipcode) {
      formik.setFieldValue("serviceZipcode", props.data.zipcode);
      formik.setFieldValue("phone", props.data.phone);
      formik.setFieldValue("firstname", props.data.firstname);
      formik.setFieldValue("lastname", props.data.lastname);
      formik.setFieldValue("prefix", props.data.prefix);
      formik.setFieldValue("serviceAddress", props.data.serviceAddress);

      fetch(`https://api.zippopotam.us/us/${props.data.zipcode}`)
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          // Parse the JSON data
          return response.json();
        })
        .then((data) => {
          // Handle the data
          formik.setFieldValue("serviceCity", data.places[0]?.["place name"]);
          formik.setFieldValue(
            "serviceState",
            data.places[0]?.["state abbreviation"]
          );
          formik.setFieldValue("shippingZipcode", props.data.zipcode);
          formik.setFieldValue("shippingCity", data.places[0]?.["place name"]);
          formik.setFieldValue(
            "shippingState",
            data.places[0]?.["state abbreviation"]
          );
          setDisabled(true);
        })
        .catch((error) => {
          // Handle errors
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
        });
    }
  }, [props.data.zipcode]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (services?.length == 0) {
        setServiceError("Please select a service which you like to order.");
        return;
      } else {
        setServiceError("");
      }
      props.setData({
        ...props.data,
        type: services,
        preferredDevice: values.preferredDevice,
      });
      setLoader2(true);
      setLoader(true);
      toast.dismiss();
      props.setServiceZipcode(values?.serviceZipcode);
      values.dob = `${values?.month}/${values?.day}/${values?.year}`;
      const requestOptions = {
        method: "PUT",
        headers,
        body: JSON.stringify({
          ...values,
          services,
          id: props?.leadId,
          status: 10,
          step: 2,
          stepStatus: "Incompleted",
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (!response?.ok) {
            throw new Error(
              response?.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : response?.status == 409
                ? 409
                : "Something went wrong"
            );
          }
          return response?.json(); // Parse the response body as JSON
        })
        .then((data) => {
          props.nextStep();
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoader(false);
          setLoader2(false);
        });
    },
  });

  useEffect(() => {
    // Check if the form has been submitted
    if (formik.submitCount > 0) {
      const firstErrorField = Object.keys(formik.errors)[0];
      if (firstErrorField) {
        // formRef.current.querySelector(`[name="${firstErrorField}"]`).focus();
      }
    }
  }, [formik.errors, formik.submitCount]);
  useEffect(() => {
    if (serviceError) {
      if (services?.length == 0) {
        setServiceError("Please select a service which you like to order.");
      } else {
        setServiceError("");
      }
    }
  }, [services]);
  const handlePrefferdevice = (value) => {
    formik.setFieldValue("preferredDevice", value);
    props.setData({
      ...props.data,
      preferredDevice: value,
    });
  };

  const handleWayToContact = (value) => {
    formik.setFieldValue("bestWayToReach", value);
    props.setData({
      ...props.data,
      bestWayToReach: value,
    });
  };

  const handleService = (value) => {
    const index = services.indexOf(value);

    if (index === -1) {
      setServices([...services, value]);
    } else {
      const updatedServices = services?.filter((item) => item !== value);
      setServices(updatedServices);
    }
  };

  const handleSameAsService = () => {
    // Update sameAsService state and use the updated value
    setSameAsService((prevSameAsService) => {
      const updatedSameAsService = !prevSameAsService;

      // Update other state or execute logic based on the updated value
      if (updatedSameAsService) {
        setSameAsServiceDisabled(true);
        formik.setFieldValue("shippingAddress", formik.values?.serviceAddress);
        formik.setFieldValue(
          "shippingAddress2",
          formik.values?.serviceAddress2
        );
        formik.setFieldValue("shippingZipcode", formik.values?.serviceZipcode);
        formik.setFieldValue("shippingCity", formik.values?.serviceCity);
        formik.setFieldValue("shippingState", formik.values?.serviceState);
      } else {
        setSameAsServiceDisabled(false);
      }

      // Return the updated value
      return updatedSameAsService;
    });
  };

  // const moveToTop = () => {
  //   window.scrollTo({
  //     top: 100,
  //     behavior: "smooth", // Adds smooth scrolling animation
  //   });
  // };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="application-step-form-wraper">
          <div className="custom-container">
            <form onSubmit={formik.handleSubmit} ref={formRef}>
              <div className="lead-step-form-card">
                <div className="lead-step-form-wraper">
                  {
                    props?.rewardOptions?.length ? 
                    <div className="row align-items-center mb-lg-3 mb-2">
                      <div className="col-md-6">
                        <h6 className="theme-clr">
                          Select Your  Reward
                        </h6>
                        <small className="orange-clr">
                          Choose Your Required Item
                        </small>
                      </div>
                      <div className="col-md-6">
                        <div className="fency-check-radio-button">
                          <div className="grid-wrapper grid-col-auto custom-mobile-grid">
                            {props?.rewardOptions.map((reward) => (
                              <div key={reward._id}>
                                <label for={reward._id} className="radio-card">
                                  <input
                                    type="radio"
                                    name="preferredDevice"
                                    id={reward._id}
                                    defaultChecked={
                                      props?.data?.preferredDevice == reward.name
                                        ? true
                                        : false
                                    }
                                    onClick={() => handlePrefferdevice(reward.name)}
                                  />
                                  <div className="card-content-wrapper">
                                    <span className="check-icon"></span>
                                    <div className="card-content">
                                      <div className="small-device-wraper">
                                        <img
                                          src={reward.icon}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </div>
                                      <small className="orange-clr mt-2">
                                        {reward.name}
                                      </small>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            ))}
                          </div>
                          
                          {formik.touched.preferredDevice &&
                            formik.errors.preferredDevice && (
                              <div className="for-red">
                                {formik.errors.preferredDevice}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    : 
                    null
                  }
                  
                  <div className="row align-items-center mb-lg-3 mb-2">
                    <div className="col-md-6">
                      <h6 className="theme-clr">
                        What Services Would You Like To Order?
                      </h6>
                      <small className="orange-clr">
                        Select Your Required Service
                      </small>
                    </div>
                    <div className="col-md-6">
                      <div className="fency-check-radio-button fency-rounded-radio-button">
                        <div className="grid-wrapper grid-col-auto">
                          <label for="radio-card-4" className="radio-card">
                            <input
                              type="radio"
                              name="radio-card2"
                              id="radio-card-4"
                              // onClick={() => handleService("Internet")}
                              checked={services.includes("Internet")}
                            />
                            <div className="card-content-wrapper card-services-content-wrapper">
                              <div className="card-services-content">
                                <div className="small-device-wraper">
                                  <img
                                    src={Images.smallinternet}
                                    alt="img"
                                    className="img-fluid non-active-img"
                                  />
                                  <img
                                    src={Images.smallwhiteinternet}
                                    alt="img"
                                    className="img-fluid white-active-img"
                                  />
                                </div>
                              </div>
                            </div>
                            <small className="">Internet</small>
                          </label>
                          <label for="radio-card-35" className="radio-card">
                            <input
                              type="radio"
                              name="radio-card"
                              id="radio-card-35"
                              onClick={() => handleService("TV")}
                              checked={services.includes("TV")}
                            />
                            <div className="card-content-wrapper card-services-content-wrapper ">
                              <div className="card-services-content">
                                <div className="small-device-wraper">
                                  <img
                                    src={Images.smalltv}
                                    alt="img"
                                    className="img-fluid non-active-img"
                                  />
                                  <img
                                    src={Images.smallwhitetv}
                                    alt="img"
                                    className="img-fluid white-active-img"
                                  />
                                </div>
                              </div>
                            </div>
                            <small className="">TV</small>
                          </label>
                          <label for="radio-card-5" className="radio-card">
                            <input
                              type="radio"
                              name="radio-card3"
                              id="radio-card-5"
                              onClick={() => handleService("Phone")}
                              checked={services.includes("Phone")}
                            />
                            <div className="card-content-wrapper card-services-content-wrapper">
                              <div className="card-services-content ">
                                <div className="small-device-wraper">
                                  <img
                                    src={Images.smallphone}
                                    alt="img"
                                    className="img-fluid non-active-img"
                                  />
                                  <img
                                    src={Images.smallwhitephone}
                                    alt="img"
                                    className="img-fluid white-active-img"
                                  />
                                </div>
                              </div>
                            </div>
                            <small className="">Phone</small>
                          </label>
                        </div>
                        {serviceError && (
                          <div className="for-red">{serviceError}</div>
                        )}
                      </div>
                    </div>
                  </div>
      
                  <div className="row mb-lg-5 mb-3">
                    <div className="col-lg-6">
                      <h6 className="theme-clr pb-xxl-4 pb-md-3 pb-2">
                        Please Provide Your Customer Information
                      </h6>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-lg-3 mb-2">
                            <label>
                              SSN No. <span>*</span>
                            </label>
                            <ReactInputMask
                              mask="999-99-9999"
                              type="text"
                              className={`form-control ${
                                formik.touched.ssn && formik.errors.ssn
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="ssn"
                              placeholder="Enter Number"
                              name="ssn"
                              {...formik.getFieldProps("ssn")}
                            />
                            {formik.touched.ssn && formik.errors.ssn && (
                              <div className="for-red">{formik.errors.ssn}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-lg-3 mb-2">
                            <label>Contact Number *</label>
                            <ReactInputMask
                              type="text"
                              mask="(999) 999-9999"
                              id="m-name"
                              placeholder="Enter Number"
                              name="phone"
                              {...formik.getFieldProps("phone")}
                              className={`form-control ${
                                formik.touched.phone && formik.errors.phone
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                              <div className="for-red">
                                {formik.errors.phone}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="theme-clr pb-xxl-4 pb-md-3 pb-2">
                        What Is Your Date Of Birth?
                      </h6>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-lg-3 mb-2">
                            <label>Month</label>
                            <select
                              {...formik.getFieldProps("month")}
                              className={`form-control ${
                                formik.touched.month && formik.errors.month
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="month"
                              name="month"
                            >
                              {/* Add options for months */}
                              <option value="">Select Month</option>
                              {months?.map((month) => (
                                <option key={month.value} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </select>
                            {formik.touched.month && formik.errors.month && (
                              <div className="for-red">
                                {formik.errors.month}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-lg-3 mb-2">
                            <label>Day</label>
                            <select
                              {...formik.getFieldProps("day")}
                              className={`form-control ${
                                formik.touched.day && formik.errors.day
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="day"
                              name="day"
                            >
                              {/* Add options for days */}
                              <option value="">Select Day</option>
                              {Array.from({ length: 31 }, (_, i) => (
                                <option key={i + 1} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                            {formik.touched.day && formik.errors.day && (
                              <div className="for-red">{formik.errors.day}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-lg-3 mb-2">
                            <label>Year</label>
                            <select
                              {...formik.getFieldProps("year")}
                              className={`form-control ${
                                formik.touched.year && formik.errors.year
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="year"
                              name="year"
                            >
                              {/* Add options for years */}
                              <option value="">Select Year</option>
                              {Array.from({ length: 100 }, (_, i) => (
                                <option key={2006 - i} value={2006 - i}>
                                  {2006 - i}
                                </option>
                              ))}
                            </select>
                            {formik.touched.year && formik.errors.year && (
                              <div className="for-red">
                                {formik.errors.year}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    
                  </div>
                  <div className="row mb-lg-5 mb-3 align-items-center">
                   
                    <div className="col-lg-6">
                      <h6 className="theme-clr pb-xxl-4 pb-md-3 pb-2">
                        What Is The Best Way To Reach You?
                      </h6>
                      <div className="d-flex flex-sm-row flex-column justify-content-sm-between justify-content-start align-items-sm-center align-items-start">
                        <label className="custom-control overflow-checkbox relative">
                          <input
                            className="overflow-control-input"
                            type="radio"
                            name="bestWayToReach"
                            defaultChecked={
                              props?.data?.bestWayToReach == "Email"
                                ? true
                                : false
                            }
                            onClick={() => handleWayToContact("Email")}
                          />
                          <span className="overflow-control-indicator "></span>
                          <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                            <p className="Submitting_form">Email</p>
                          </span>
                        </label>
                        <label className="custom-control overflow-checkbox relative">
                          <input
                            className="overflow-control-input"
                            type="radio"
                            // {...formik.getFieldProps("bestWayToReach")}
                            name="bestWayToReach"
                            defaultChecked={
                              props?.data?.bestWayToReach == "Phone"
                                ? true
                                : false
                            }
                            onClick={() => handleWayToContact("Phone")}
                          />
                          <span className="overflow-control-indicator "></span>
                          <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                            <p className="Submitting_form">Phone</p>
                          </span>
                        </label>
                        <label className="custom-control overflow-checkbox relative">
                          <input
                            className="overflow-control-input"
                            type="radio"
                            // {...formik.getFieldProps("bestWayToReach")}
                            defaultChecked={
                              props?.data?.bestWayToReach == "Text Message"
                                ? true
                                : false
                            }
                            onClick={() => handleWayToContact("Text Message")}
                            name="bestWayToReach"
                          />
                          <span className="overflow-control-indicator "></span>
                          <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                            <p className="Submitting_form">Text Message</p>
                          </span>
                        </label>
                        <label className="custom-control overflow-checkbox relative">
                          <input
                            className="overflow-control-input"
                            type="radio"
                            // {...formik.getFieldProps("bestWayToReach")}
                            defaultChecked={
                              props?.data?.bestWayToReach == "Mail"
                                ? true
                                : false
                            }
                            onClick={() => handleWayToContact("Mail")}
                            name="bestWayToReach"
                          />
                          <span className="overflow-control-indicator "></span>
                          <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2">
                            <p className="Submitting_form">Mail</p>
                          </span>
                        </label>
                      </div>
                      {formik.touched.bestWayToReach &&
                        formik.errors.bestWayToReach && (
                          <div className="for-red">
                            {formik.errors.bestWayToReach}
                          </div>
                        )}
                    </div>
                  </div>
                 
                  <div className="d-flex flex-lg-row flex-column justify-content-lg-between align-items-center mt-lg-5 mt-md-3">
                    <div className="terms-checkbox">
                      <div className="d-flex">
                        <label className="custom-control overflow-checkbox relative">
                          <input
                            type="checkbox"
                            name="agreement"
                            id="agreement"
                            className="overflow-control-input"
                            onChange={formik.handleChange}
                            checked={formik.values.agreement}
                          />
                          <span className="overflow-control-indicator"></span>
                          <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f pr-2"></span>
                        </label>
                        <p className="Submitting_form cri-pointer green-clr">
                          <LeadsTermsModal />
                        </p>
                      </div>
                      {formik.touched.agreement && formik.errors.agreement && (
                        <div className="for-red">{formik.errors.agreement}</div>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex mt-lg-0 mt-3 justify-content-end align-items-center">
                      {loader2 ? (
                        <button disabled className="btn theme-btn d-flex ">
                          <span className="ml_9"> Loading...</span>
                          <div className="spinner"></div>
                        </button>
                      ) : (
                        <button
                          // onClick={() => moveToTop()}
                          type="submit"
                          className="btn orange-filled-btn"
                        >
                          Agree & Submit
                        </button>
                      )}
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadDetailCapture;
