import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Stripe from "./Stripe";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};
const apiUrlForPayment = `${API_URL}/leads/get-lead-payment`;

const options = {
  style: {
    base: {
      iconColor: "#409131",
      height: "50px",
      fontWeight: "400",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#212529",
      },
    },
    invalid: {
      iconColor: "#ff0101",
      color: "#ff0101",
    },
  },
};

const StripeCharge = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardError, setCardError] = useState("");
  const [flag, setFlag] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const handleCardChange = (event) => {
    try {
      if (event.complete && !event.error) {
        setFlag(true);
        setCardError("");
      } else {
        setFlag(false);
        setCardError("Please enter your card information.");
      }
    } catch (error) {
    }
  };

  const sumbitPayment = async () => {
    setButtonLoader(true);
    toast.dismiss();
    if (!props?.leadId || props?.leadId == "") {
      toast.error("Required data is missing");
    }

    if (!stripe || !elements) {
      setButtonLoader(false);
      return;
    }
    if (!flag || !props.price) {
      return;
    }

    const firstToken = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (firstToken?.error) {
      toast.error(firstToken?.error?.message || "Something went wrong!");
      console.error(firstToken.error, "error");
      setButtonLoader(false);
      return;
    }

    let payload = {
      paymentMethod: "Card",
      price: props.price + 5 + 2.99,
      source: firstToken?.token?.id,
      leadId: props?.leadId ?? "", // This leadId is for testing
    };

    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({
        ...payload,
      }),
    };
    fetch(`${apiUrlForPayment}`, requestOptions)
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Parse the JSON data
        return response.json();
      })
      .then((data) => {
        toast.success(data?.message || "Payment done successfully");
        props.setStripeModalShow(false);
        // Handle the data
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  return (
    <Modal
      show={props.stripeModalShow}
      //   onHide={props.setStripeModalShow(false)}
      size="xl"
      className="common-modal-wrape payment-detail-modal"
    >
      <Modal.Body className="modal-common-body">
        <div className="col-12  payment-detail--row">
          <div className="d-flex  paymeny-deatail-header">
            <h4 className="blue-text">Add a Payment Method</h4>
          </div>
          <div className="last-step-right-content shoping-cart-detail mb-xxl-3 mb-2 p-lg-3 p-2">
            <div className="grey-bg-card max_w_full">
              <div className="grey-bg-card-content top-green-border">
                <h5 className="font-bold mb-xl-3 mb-2">Order Summary</h5>
                {props?.leadDetail?.dealsId?.map((item, idx) =>
                  props?.leadDetail?.dealSelectedItems.map(
                    (data, index) =>
                      data?._id == item?._id && (
      
                        <span key={index}>
                          <div
                            key={idx}
                            className="d-flex justify-content-between">
                            <p className="grey-text left-side-text font-bold">
                              {item?.name}
                            </p>
                            <p className="grey-text font-bold text-end">
                             {(data?.discountPrice && data.discountPrice !== '0') ? `$${Number(data.discountPrice).toFixed(2)}/mo` : `$${Number(item?.planPrice).toFixed(2)}/mo`}
                            </p>
                          </div>
                        </span>
                      )
                  )
                )}
                <div className="d-flex justify-content-between align-items-end">
                  <p className="m-0">Shipping Fee (One Time)</p>
                  <p className="grey-text  text-end">$5.00</p>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                  <p className="m-0">Payment Processing Fee (One Time)</p>
                  <p className="grey-text  text-end">$2.99</p>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                  <h6 className="m-0 fs-17 font-bold ">Sub Total</h6>
                  <h6 className="grey-text font-bold text-end fs-17">
                    ${(props?.price + 5 + 2.99)?.toFixed(2)}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="m-lg-4 m-2">
            <div className="col-lg-12">
              <div className="stripe_field_wrap">
                <CardElement
                  options={options}
                  onChange={(e) => handleCardChange(e)}
                />
              </div>
              {cardError ? <p className="for-red">{cardError}</p> : null}
            </div>
            <div className="d-flex justify-content-lg-end align-lg-items-end align-items-center justify-content-center mt-lg-4 mt-3">
              {buttonLoader ? (
                <div className="btn green-filled-btn">
                  <span role="status">Loading...</span>
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                <button
                  onClick={() => sumbitPayment()}
                  className="btn green-filled-btn"
                >
                  Make Payment
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StripeCharge;
