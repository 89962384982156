import React, { useEffect, useState } from "react";

// __ __ Packages/Style/Media __ __ //
import StepWizard from "react-step-wizard";
import { toast } from "react-toastify";
import "./Deal.css";
import LeadCapture from "./LeadCapture";
import LastStep from "./LastStep";
import LeadDetailCapture from "./LeadDetailCapture";
import Deals from "./Deals";
import OrderSumary from "../../../Pages/GetOrderSumary/GetOrderSumary";

// __ __ Components/Helpers __ __ //

const LeadCaptureForms = () => {
  const [serviceZipcode, setServiceZipcode] = useState("");
  const [leadId, setLeadId] = useState("");
  const [flag, setFlag] = useState(false);
  const [step, setStep] = useState("1");
  const [data, setData] = useState({
    phone: "",
    email: "",
    zipcode: "",
    type: [],
    preferredDevice: "",
  });

  return (
    <StepWizard>
      <LeadCapture setLeadId={setLeadId} setData={setData} />
      <LeadDetailCapture
        setServiceZipcode={setServiceZipcode}
        setData={setData}
        leadId={leadId}
        data={data}
      />
      <Deals
        serviceZipcode={serviceZipcode}
        leadId={leadId}
        setFlag={setFlag}
        flag={flag}
        data={data}
        setStep={setStep}
      />
      <OrderSumary step={step} leadId={leadId} />
      <LastStep leadId={leadId} flag={flag} step={step}/>
    </StepWizard>
  );
};

export default LeadCaptureForms;
