import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function OptInModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a onClick={handleShow}>Opt In</a>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="common-modal-wraper custom-optin-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="theme-clr">
            <h5>Opt-In</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-common-body">
          <p>
            By checking this box and clicking "Agree & Next" I represent that I am the line subscriber or primary user of the phone number (including my wireless number if provided) and provide my express consent authorizing “Quantumax” to contact me by telephone (including text messages), delivered via automated technology to the phone number above regarding phone carrier and internet service products and/or offerings even if I am on a Federal, State or Do-Not-Call registry. I understand that my consent is not required to continue with my application or is a condition to search for phone carrier and internet products and/offerings. I understand and agree that this site uses third-party visit recording technology, including, but not limited to, Trusted Form and Jornaya. I understand I can revoke consent at any time. To skip and continue without sharing your information with our phone and internet service providers/partners, <Link to="/privacy-policy">click here</Link>
          </p>
          <p>
            For SMS messages campaigns Msg. & Data Rates May Apply. Reply HELP for help. Reply STOP to cancel.
          </p>
          <p>
            By clicking “Next” I represent I am a U.S. Resident over the age of 18, understand and agree to the Privacy Policy, Terms & Conditions and California Privacy Notice and agree to receive email promotions from Quantumax and our marketing partners. 
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OptInModal;
